import React, { useEffect, useState, useCallback, useRef } from "react";
import { IoSearch } from "react-icons/io5";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import DraftsPagination from "../components/DraftsPagination.jsx";
import { formatDate, formatDay, formatTime } from "../services/logics";
import { FiRotateCw } from "react-icons/fi";
import DateRangeInput from "../components/DateRangeInput.jsx";
import { extractNameAndEmail } from "../services/emailUtils.js";
import { useSelector } from "react-redux";
import { CiFilter } from "react-icons/ci";
import DraftShimmer from "../components/DraftShimmer.jsx";
import axiosInstance from "../services/axiosInstance.js";
export const groupDraftsByDate = (drafts = []) => {
  const grouped = {};

  drafts.forEach((draft) => {
    const draftDate = new Date(draft.created_at);
    const today = new Date();

    const utcDraftDate = new Date(Date.UTC(draftDate.getUTCFullYear(), draftDate.getUTCMonth(), draftDate.getUTCDate()));
    const utcToday = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));

    const timeDifference = utcToday - utcDraftDate;
    const dayDifference = timeDifference / (1000 * 60 * 60 * 24);

    let key;

    if (dayDifference === 0) {
      key = `Today, ${draftDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })}`;
    } else if (dayDifference === 1) {
      key = `Yesterday, ${draftDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })}`;
    } else {
      key = draftDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });
    }

    if (!grouped[key]) {
      grouped[key] = [];
    }
    grouped[key].push(draft);
  });

  return grouped;
};
const DraftPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [drafts, setDrafts] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const API_URL = process.env.REACT_APP_API_URL;
  const [isAscending, setIsAscending] = useState(true);
  const token = localStorage.getItem("token");
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalDrafts, setTotalDrafts] = useState(0);
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [activeTab, setActiveTab] = useState('all');
  const [showCalendar, setShowCalendar] = useState(false);
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const calendarRef = useRef(null);

  const fetchDrafts = async (searchTerm, page = 1, startDate = null, endDate = null, unread = null) => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/v1/store/drafts`, {
        params: {
          page,
          page_size: 10,
          search: searchTerm,
          from_date: startDate,
          to_date: endDate,
          unread: unread === "all" ? null : (unread !== "sent" ? true : null),
          sent: unread === "sent" ? true : null,
        },
       
      });
      const data = response.data;
      // If the current page has no drafts and it's not the first page, navigate to the previous page
      if (data.drafts.length === 0 && page > 1) {
        setPage(page - 1);
        fetchDrafts(searchTerm, page - 1, startDate, endDate, unread); 
        return;
      }
      setDrafts(data.drafts);
      setTotalDrafts(data.total);
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
    setIsFetching(false);
  };

  // Load state from location.state if available
  useEffect(() => {
    if (location.state) {
      const { page, searchTerm, selectedDateRange, activeTab } = location.state;
      setPage(page || 1);
      setSearchTerm(searchTerm || "");
      setSelectedDateRange(selectedDateRange || [null, null]);
      setActiveTab(activeTab || "all");

      // Fetch drafts based on the loaded state
      fetchDrafts(searchTerm || "", page || 1, selectedDateRange?.[0] || null, selectedDateRange?.[1] || null, activeTab || "all");
    } else {
      // Fetch drafts with default values if no state is present in location
      fetchDrafts(searchTerm, page, selectedDateRange[0], selectedDateRange[1], activeTab);
    }
  }, [location.state]);

  const debouncedSearchDrafts = useCallback(
    debounce((term, startDate, endDate, tab) => {
      setPage(1);
      fetchDrafts(term, 1, startDate, endDate, tab);
    }, 500),
    []
  );

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    const [startDate, endDate] = selectedDateRange;
    debouncedSearchDrafts(value, startDate, endDate, activeTab);
  };

  const handlePageChange = (newPage) => {
    if (newPage !== page) {
      setPage(newPage);
      setIsFetching(true);
      fetchDrafts(searchTerm, newPage, selectedDateRange[0], selectedDateRange[1], activeTab);
    }
  };

  const handleCalendarToggle = () => {
    setShowCalendar((prevShow) => !prevShow);
  };

  const handleDateChange = (dateRange) => {
    setSelectedDateRange(dateRange);
    setShowCalendar(false);
    fetchDrafts(searchTerm, 1, dateRange[0], dateRange[1], activeTab); // Fetch drafts immediately when date range changes
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setPage(1);
    setSelectedDateRange([null, null]);
    setIsFetching(true);
    fetchDrafts(searchTerm, 1, null, null, tab);
  };

  const handleResetFilters = () => {
    setSelectedDateRange([null, null]);
    setSearchTerm("");
    setPage(1)
    const [startDate, endDate] = [null, null];
    fetchDrafts("", 1, startDate, endDate, activeTab);
  };

  const handleNavigateToDetail = (draftId) => {
    // Store the current state before navigating
    navigate(`/draft-page/detail/${draftId}`, {
      state: {
        page,
        searchTerm,
        selectedDateRange,
        activeTab,
      },
    });
  };

  const getInitials = (name) => {
    return name ? name.charAt(0).toUpperCase() : "";
  };



  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calendarRef]);

  const groupedDrafts = groupDraftsByDate(drafts);

  return (
    <div data-testid="draftPageContainer" className={`py-2 font-dmsans overflow-y-auto flex flex-col ${isSidebarOpen ? "ml-[230px] px-8" : "ml-[80px] px-6"} transition-all ease-in-out duration-500`}>
      <div className="flex items-center justify-between mr-4 mb-2">
        <div className="flex items-center space-x-10">
          <div className="flex space-x-14 ml-10">
            <span
              className={`cursor-pointer font-semibold relative ${activeTab === "all" ? "text-[#191D23]" : "text-[#868CA2]"}`}
              onClick={() => handleTabChange("all")}
            >
              All
              {activeTab === "all" && (
                <div className="absolute left-1/2 transform -translate-x-1/2 h-1 mt-2 bg-[#39F6A3]" style={{ width: "50px" }} />
              )}
            </span>
            <span
              className={`cursor-pointer font-semibold relative ${activeTab === "unread" ? "text-[#191D23]" : "text-[#868CA2]"}`}
              onClick={() => handleTabChange("unread")}
            >
              Unread
              {activeTab === "unread" && (
                <div className="absolute left-1/2 transform -translate-x-1/2 h-1 bg-[#39F6A3] mt-2" style={{ width: "50px" }} />
              )}
            </span>
            <span
              className={`cursor-pointer font-semibold relative ${activeTab === "sent" ? "text-[#191D23]" : "text-[#868CA2]"}`}
              onClick={() => handleTabChange("sent")}
            >
              Sent
              {activeTab === "sent" && (
                <div className="absolute left-1/2 transform -translate-x-1/2 h-1 bg-[#39F6A3] mt-2" style={{ width: "50px" }} />
              )}
            </span>
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0 space-x-0 md:space-x-4 relative">
          <div className="relative w-[220px] h-[36px] border border-[#868CA2] bg-white text-[#000000] rounded-sm">
            <IoSearch className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-[#64748B]" />
            <input
              type="text"
              placeholder="Search"
              className="focus:outline-none w-[84%] h-full ml-8 text-[#64748B] text-[15px]"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          <div className="relative" ref={calendarRef}>
            <div
              className={`flex items-center h-[36px] w-full md:w-[150px] justify-center cursor-pointer py-2 border border-gray-300 rounded-md hover:bg-gray-100 ${showCalendar ? "bg-[#39F6A333] text-[#374957]" : ""}`}
              onClick={handleCalendarToggle}
            >
              <CiFilter className="mr-2 text-[#64748B] w-[15px] h-[15px]" />
              <span className="text-sm font-medium text-[#64748B]" data-testid="Calendar">Filter by date</span>
            </div>
            {showCalendar && (
              <DateRangeInput startDate={selectedDateRange[0]} endDate={selectedDateRange[1]} onChange={handleDateChange} />
            )}
          </div>
          <FiRotateCw className="w-[22px] h-[22px] text-[#868CA2] cursor-pointer" onClick={handleResetFilters} title="reset-filters" />
        </div>
      </div>

      {/* Drafts list section */}
      <div className="overflow-auto custom-scrollbar h-auto justify-between w-full ">
        {isFetching ? (
          <DraftShimmer />
        ) : (
          <>
            {Object.keys(groupedDrafts).length === 0 ? (
              <p className="text-center mt-28 text-red-400">No drafts found</p>
            ) : (
              Object.entries(groupedDrafts).map(([date, drafts]) => (
                <div key={date} className="mt-3">
                  <div className="text-sm px-4 py-2 font-normal font-dmsans text-[#64748B] border-b border-[#E7EAEE]">
                    {date}
                  </div>
                  {drafts.map((draft) => (
                    <div
                      key={draft.id}
                      className="flex items-center gap-2 p-3  hover:bg-[#39F6A30D] border-b border-gray-200 w-full cursor-default"
                      onClick={() => handleNavigateToDetail(draft.id)}
                      data-testid="draftMail"
                    >
                      <div className={`w-2 h-2 ${!draft.is_read ? 'bg-[#39F6A3]' : ""} rounded-full`}></div>
                      <div className="flex-shrink-0 w-8 h-8 bg-[black] rounded-full flex items-center justify-center text-[#61F8B5] text-lg font-semibold ml-1">
                        {getInitials(extractNameAndEmail(draft.sender_email)?.name)}
                      </div>
                      <div className="flex-1 flex flex-col md:flex-row items-start md:items-center">
                        <span
                          className={`font-dmsans text-sm truncate w-[150px] font-normal ${draft.is_read ? "text-gray-400" : "text-gray-800"}`}
                        >
                          {extractNameAndEmail(draft.sender_email)?.name || "Unknown Sender"}
                        </span>
                        <div
                          className="text-sm text-gray-500 ml-10 truncate flex-1 w-[100px]"
                        >
                          <span className="font-medium text-[#2B2E3A] text-sm">{draft.email_subject}</span>
                          <span className="ml-2 text-[#868CA2] text-sm font-medium">{draft.email_body}</span>
                        </div>
                      </div>
                      <div className="text-xs ml-12 text-gray-500 text-right whitespace-nowrap flex flex-col md:flex-row md:space-x-1">
                        <span>{formatDay(draft.created_at)},</span>
                        <span>{formatDate(draft.created_at)} | {formatTime(draft.created_at)}</span>
                      </div>
                    </div>
                  ))}
                </div>
              ))
            )}
          </>
        )}
      </div>
      {Object.keys(groupedDrafts).length > 0 && <DraftsPagination
        currentPage={page}
        onPageChange={handlePageChange}
        totalDrafts={totalDrafts}
      />}
    </div>
  );
};

export default DraftPage;
