import React, { useState } from 'react';
import CalendarIcon from "../assets/DraftsPageAssets/CalendarIcon.jsx";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';

const formatDate = (date) => {
    return date ? date.toISOString().split('T')[0] : null;
};

const DateRangeInput = ({ startDate, endDate, onChange }) => {
    const [showStartCalendar, setShowStartCalendar] = useState(false);
    const [showEndCalendar, setShowEndCalendar] = useState(false);

    const handleStartDateChange = (date) => {
        if (date) {
            const formattedDate = formatDate(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())));
            onChange([formattedDate, endDate]);
            setShowStartCalendar(false);
        }
    };

    const handleEndDateChange = (date) => {
        if (date) {
            date.setHours(23, 59, 59, 999); // Set the time to the end of the day
            const formattedDate = formatDate(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())));
            onChange([startDate, formattedDate]);
            setShowEndCalendar(false);
        }
    };

    const toggleStartCalendar = () => {
        setShowStartCalendar((prev) => !prev);
        if (showEndCalendar) {
            setShowEndCalendar(false);
        }
    };

    const toggleEndCalendar = () => {
        setShowEndCalendar((prev) => !prev);
        if (showStartCalendar) {
            setShowStartCalendar(false);
        }
    };

    const calendarStyle = {
        width: '250px',
        height: '250px',
        zIndex: 20,
    };

    return (
        <div className="absolute top-10 right-0 z-10 bg-white border border-gray-300 rounded shadow-lg">
            <div className="flex justify-between w-[390px] h-[40px] px-1">
                <div className="relative w-1/2 p-1 ">
                    <input
                        type="text"
                        value={startDate ? new Date(startDate).toLocaleDateString() : ""}
                        placeholder="From"
                        className="border border-[#868ca2] p-2 w-[180px] h-[30px] rounded focus:outline-none focus:ring-2 focus:ring-[#39F6A3]"
                        onClick={toggleStartCalendar}
                        onChange={() => {}}
                        data-testid="start-calendar"
                    />
                    <CalendarIcon className="absolute right-4 top-1/2 transform -translate-y-1/2 w-4 h-4" fill="#374957" />
                    {showStartCalendar && (
                        <div className="absolute top-12 left-0 z-20" style={calendarStyle}>
                            <Calendar
                                onChange={handleStartDateChange}
                                value={startDate ? new Date(startDate) : new Date()}
                                selectRange={false}
                                maxDate={new Date()}
                            />
                        </div>
                    )}
                </div>
                <div className="relative w-1/2 p-1">
                    <input
                        type="text"
                        value={endDate ? new Date(endDate).toLocaleDateString() : ""}
                        placeholder="To"
                        className="border border-[#868CA2] p-2 w-[180px] h-[30px] rounded focus:outline-none focus:ring-2 focus:ring-[#39F6A3]"
                        onClick={toggleEndCalendar}
                        onChange={() => {}}
                        data-testid="end-calendar"
                    />
                    <CalendarIcon className="absolute right-4 top-1/2 transform -translate-y-1/2 w-4 h-4" fill="#374957" />
                    {showEndCalendar && (
                        <div className="absolute top-12 left-0 z-20" style={calendarStyle} >
                            <Calendar
                                onChange={handleEndDateChange}
                                value={endDate ? new Date(endDate) : new Date()}
                                selectRange={false}
                                maxDate={new Date()}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DateRangeInput;
