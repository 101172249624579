import React, { useState, useRef } from 'react';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa6';
import VisaCardIcon from '../assets/DraftsPageAssets/VisaCardIcon.svg';
import { IoIosArrowRoundBack } from "react-icons/io";
import { formatCustomDateTime } from "../services/logics";
import { toast } from "react-toastify";
import WhiteLoadingAnimation from "../assets/WhiteLoadingAnimation.svg";
import { useSelector } from 'react-redux';
import axiosInstance from "../services/axiosInstance.js";
import SucessModal from "../components/SucessModal.jsx";

const CustomNumberInput = ({ value, onChange, min, max, showPercentageSymbol, isChanged }) => {
  const [blocked, setBlocked] = useState(false);
  const handleIncrement = () => {
    if (value < max) {
      onChange(value + 1);
      setBlocked(false);
    } else {
      setBlocked(true);
    }
  };

  const handleDecrement = () => {
    if (value > min) {
      onChange(value - 1);
      setBlocked(false);
    }
  };

  return (
    <div className="relative flex items-center w-[50px] mx-auto">
      <input
        type="number"
        value={value}
        onChange={(e) => {
          const newValue = parseInt(e.target.value);
          if (newValue <= max && newValue >= min) {
            onChange(newValue);
            setBlocked(false);
          } else if (newValue > max) {
            setBlocked(true);
          }
        }}
        className={`no-arrows w-full text-left pl-1 border border-[#1212121A] rounded-md focus:outline-none h-[23px] pr-${showPercentageSymbol ? '6' : '2'} ${isChanged ? 'bg-[#F1F9F7]' : ''}`}
        min={min}
        max={max}
      />
      {showPercentageSymbol && (
        <span className="absolute right-4 text-[#000000] text-[11px]">%</span>
      )}
      <div className="absolute right-1 top-1/2 transform -translate-y-1/2 flex flex-col justify-center h-full">
        <button
          type="button"
          onClick={handleIncrement}
          className="flex"
          disabled={value >= max}
        >
          <FaAngleUp className={`text-[#000000] w-2 h-2.4 ${value >= max ? 'text-gray-400' : ''}`} />
        </button>
        <button
          type="button"
          onClick={handleDecrement}
          className="flex"
          disabled={value <= min}
        >
          <FaAngleDown className={`text-[#000000] w-2 h-2.4 ${value <= min ? 'text-gray-400' : ''}`} />
        </button>
      </div>
    </div>
  );
};

const RefundConfirmation = ({ showRefundModal, setShowRefundModal, orderData, transactionData, refundDetails, draftData, updateDraft, draftEmail,setRefundSucess}) => {
  const [showRefundSucessModal, setShowRefundSucessModal] = useState(false);
  const products = orderData[0]?.line_items || [];
  const [isCreatingRefund, setIsCreatingRefund] = useState(false);
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  // Store the initial quantity and refund percentages in useRef
  const initialQuantities = useRef(products.map(product => product.current_quantity));
  const initialRefundPercents = useRef(products.map(product => refundDetails?.percentage || 0));

  const [selectedNumbers, setSelectedNumbers] = useState(initialQuantities.current);
  const [refundPercents, setRefundPercents] = useState(initialRefundPercents.current);
  const [refundMessages, setRefundMessages] = useState(Array(products.length).fill(""));

  const handleQuantityInputChange = (index, value) => {
    const newSelectedNumbers = [...selectedNumbers];
    newSelectedNumbers[index] = Math.min(Math.max(0, value), products[index].current_quantity);
    setSelectedNumbers(newSelectedNumbers);
  };


  const handleRefundPercentChange = (index, value) => {
    const originalPercent = refundDetails.percentage;
    const cappedValue = Math.min(Math.max(0, value), 100);
    const newRefundPercents = [...refundPercents];
    const newRefundMessages = [...refundMessages];

    newRefundPercents[index] = cappedValue;

    if (cappedValue !== originalPercent) {
      const difference = cappedValue - originalPercent;
      newRefundMessages[index] = difference > 0
        ? `*${difference}% Higher`
        : `*${Math.abs(difference)}% Lower`;
    } else {
      newRefundMessages[index] = "";
    }

    setRefundPercents(newRefundPercents);
    setRefundMessages(newRefundMessages);
  };

  const createRefund = async () => {
    setIsCreatingRefund(true);
    const getLocationIdForProduct = (productId, orderData) => {
      if (!orderData[0]?.fulfillments?.length) {
        return null;
      }
  
      for (const fulfillment of orderData[0].fulfillments) {
        const foundItem = fulfillment.line_items.find(
          (item) => item.product_id === productId
        );
  
        if (foundItem) {
          return fulfillment.location_id || null;
        }
      }
  
      return null;
    };
  
    const refundLineItems = products.map((product, index) => {
      const isUnfulfilled = product.fulfillment_status === null;
      const restockType = isUnfulfilled ? "cancel" : "return";
      const locationId = getLocationIdForProduct(product.product_id, orderData); // Ensure the product ID is passed correctly
  
      return {
        line_item_id: product.id,
        quantity: selectedNumbers[index],
        restock_type: restockType,
        ...(restockType === "return" && locationId ? { location_id: locationId } : {}),
        ...(restockType === "cancel" && { location_id: null } ),
      };
    });
    const transactions = products.map((product, index) => ({
      parent_id: transactionData[0].id,
      amount: (product.price * selectedNumbers[index] * refundPercents[index] / 100).toFixed(2),
      kind: "refund",
      gateway: transactionData[0].gateway
    }));

    const payload = {
      thread_id:draftData?.thread_id,
      draft_id:draftData?.id,
      order_id: orderData[0]?.id,
      currency: orderData[0]?.currency,
      notify: true,
      note: refundDetails.reason,
      shipping: {
        full_refund: true
      },
      refund_line_items: refundLineItems,
      transactions: transactions
    };

    try {
      const response = await axiosInstance.post(
        `${API_URL}/v1/store/shopify/create_refund`,
        payload
       
      );
      const data = response.data;
      if (response.status === 200) {
        setShowRefundSucessModal(true);
        toast.success('Refund created successfully');
        setRefundSucess(true)
        updateDraft(draftEmail, true);
      }
    } catch (error) {
      toast.error(error.response?.data?.detail || 'An error occurred while creating the refund.');
    }
    setIsCreatingRefund(false);
  };

  const handleCloseRefundModal = () => {
    setShowRefundSucessModal(false);
    setShowRefundModal(!showRefundModal);
  };

  const totalAmount = products.reduce((acc, product) => acc + (product.price * product.quantity), 0).toFixed(2);
  const totalRefundCost = products.reduce((acc, product, index) => acc + (product.price * selectedNumbers[index]), 0).toFixed(2);
  const totalRefundAmount = products.reduce((acc, product, index) => acc + (product.price * selectedNumbers[index] * refundPercents[index] / 100), 0).toFixed(2);

  const textStyle = "text-xs font-['DM Sans']";
  const boldTextStyle = `${textStyle} font-bold text-[#2b2e3a] break-words`;
  const labelStyle = `${textStyle} text-[#868ca2] font-medium w-[100px]`;
  const headerStyle = `${textStyle} font-bold text-[#121212] text-[10px] capitalize leading-[14.40px] py-2 border border-[#E1E1E6] text-center bg-[#E7E8EC]`;
  const tableDataStyle = `py-3 text-[#000000] text-[12px] font-normal border-r border-l border-[#E1E1E6] align-top`;

  const isQuantityChanged = (index) => selectedNumbers[index] !== initialQuantities.current[index];
  const isRefundPercentChanged = (index) => refundPercents[index] !== initialRefundPercents.current[index];

  return (
    <div className={`relative bg-white flex flex-col w-full`}>
      <div className="flex items-center justify-between ml-2 mt-4 space-x-6 h-[26px]">
        <div className="flex items-center text-[#2B2E3A] text-[16px] font-medium font-dmsans">
          <button className="flex items-center mr-4" onClick={handleCloseRefundModal}>
            <IoIosArrowRoundBack className="w-7 h-7 text-[#2B2E3A]" />
          </button>
          Refund Confirmation
        </div>
      </div>

      <div className="flex justify-end w-full pr-4 mt-8">
        <div className="flex items-center">
          <span className="text-[#868CA2] text-sm font-medium">Status</span>
          <div className="flex items-center ml-2">
            <div className="h-2.5 w-2.5 bg-[#28FB03] rounded-full mr-2 animate-pulse"></div>
            <span className="text-[#2B2E3A] text-sm font-medium">{orderData[0]?.financial_status}</span>
          </div>
        </div>
      </div>

      <div className="relative">
        <div className="absolute ml-3 w-[286px] top-[16px] text-[#2b2e3a] text-[12px] font-bold font-['DM Sans'] leading-[30px]">
          Payment Details
        </div>
      </div>

      <div className="absolute mt-[210px] w-full px-2">
        <div className="overflow-y-auto custom-scrollbar h-[530px] pr-0.5">
          <table className="table-auto w-full border-collapse min-w-[600px]">
            <thead>
              <tr className="bg-[#E7E8EC]">
                <th className={headerStyle} style={{ width: '6%' }}>S. No.</th>
                <th className={headerStyle} style={{ width: '25%' }}>Product Name</th>
                <th className={headerStyle} style={{ width: '8%' }}>Original QTY.</th>
                <th className={headerStyle} style={{ width: '8%' }}>Unit Rate</th>
                <th className={headerStyle} style={{ width: '8%' }}>Total Rate</th>
                <th className={headerStyle} style={{ width: '8%' }}>Previous Refund QTY</th>
                <th className={headerStyle} style={{ width: '8%' }}>Refund QTY.</th>
             
                <th className={headerStyle} style={{ width: '8%' }}>Total Refund Cost</th>
                <th className={headerStyle} style={{ width: '8%' }}>Refund %</th>
                <th className={headerStyle} style={{ width: '12%' }}>Total Refund Amount</th>
              </tr>
            </thead>
            <tbody className="h-[440px] space-y-1">
              {products.map((product, index) => (
                <tr key={product.id} className={` border-b border-[#E1E1E6] ${index === 0 ? 'pt-4' : ''}`}>
                  <td className={`${tableDataStyle} text-center  ${selectedNumbers[index] === 0 ? 'text-[#868CA2]' : 'text-black'}`}>{index + 1}</td>
                  <td className={`${tableDataStyle} pl-4 text-[11px] ${selectedNumbers[index] === 0 ? 'text-[#868CA2]' : 'text-black'}`}>{product.name}</td>
                  <td className={`${tableDataStyle} text-center ${selectedNumbers[index] === 0 ? 'text-[#868CA2]' : 'text-black'}`}>
                    {product.quantity}
                  </td>
                  <td className={`${tableDataStyle} text-center ${selectedNumbers[index] === 0 ? 'text-[#868CA2]' : 'text-black'}`}>{product.price}</td>
                  <td className={`${tableDataStyle} text-center ${selectedNumbers[index] === 0 ? 'text-[#868CA2]' : 'text-black'}`} style={{ overflowWrap: 'break-word' }}>{(product.price * product.quantity).toFixed(2)}</td>
                  <td className={`${tableDataStyle} text-center ${selectedNumbers[index] === 0 ? 'text-[#868CA2]' : 'text-black'}`}>{product.quantity - product.current_quantity}</td>
                  <td className={`${tableDataStyle} text-center ${selectedNumbers[index] === 0 ? 'text-[#868CA2]' : 'text-black'} relative`}>
                    <CustomNumberInput
                      value={selectedNumbers[index]}
                      onChange={(value) => handleQuantityInputChange(index, value)}
                      min={0}
                      max={product.current_quantity}
                      isChanged={isQuantityChanged(index)}  // Change background if modified
                    />
                  </td>

                  <td className={`${tableDataStyle} text-center ${selectedNumbers[index] === 0 ? 'text-[#868CA2]' : 'text-black'}`} style={{ overflowWrap: 'break-word' }}>{(product.price * selectedNumbers[index]).toFixed(2)}</td>
                  <td className={`${tableDataStyle} text-center relative ${selectedNumbers[index] === 0 ? 'text-[#868CA2]' : 'text-black'}`}>
                    <CustomNumberInput
                      value={refundPercents[index]}
                      onChange={(value) => handleRefundPercentChange(index, value)}
                      min={0}
                      max={100}
                      showPercentageSymbol
                      isChanged={isRefundPercentChanged(index)}
                    />
                    {refundMessages[index] && (<span className="absolute text-[#FF0101] text-[8px] left-1/2 top-[40px] transform -translate-x-1/2"> {refundMessages[index]}</span> )}
                  </td>
                  <td className={`${tableDataStyle} text-center ${selectedNumbers[index] === 0 ? 'text-[#868CA2]' : 'text-black'}`} style={{ overflowWrap: 'break-word' }}>{(product.price * selectedNumbers[index] * refundPercents[index] / 100).toFixed(2)}</td>
                </tr>
              ))}
              <tr className="h-[50px] font-bold text-[#000000] text-xs ">
                <td colSpan={4} className="text-center pr-24 py-2 border-l border-b border-t border-[#EFE4E4]">TOTAL</td>
                <td colSpan={1} className="text-center text-[13px] py-2 border-b border-t border-[#EFE4E4]" style={{ width: '90px', overflowWrap: 'break-word' }}>
                  {totalAmount}
                </td>
                <td colSpan={2} className='border-b border-t border-[#EFE4E4]'></td>
                <td colSpan={1} className="text-center text-[13px] py-2 border-b border-t border-[#EFE4E4]" style={{ width: '90px', overflowWrap: 'break-word' }}>
                  {totalRefundCost}
                </td>
                <td colSpan={1} className='border-b border-t border-[#EFE4E4]'></td>
                <td colSpan={1} className="text-center px-2 text-[13px] py-2 border-b border-r border-t border-[#EFE4E4]" style={{ width: '90px', overflowWrap: 'break-word' }}>
                  {totalRefundAmount}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* Buttons Container */}
        <div className="absolute mt-2 right-6 flex justify-end">
          <button
            className="text-[#2B2E3ACC] bg-[#FFFFFF] border boder-[#2B2E3A80] rounded-lg shadow-sm font-dmsans px-6 py-2 mr-4  text-sm h-[36px]"
            onClick={handleCloseRefundModal}
          >
            Cancel
          </button>
          <button
            className="bg-[#96F3C3] text-[#000000] text-sm font-dmsans font-medium px-6 py-2 rounded-lg shadow-sm w-auto flex items-center justify-center h-[36px]"
            onClick={createRefund}
            disabled={isCreatingRefund}
          >
            {isCreatingRefund ? (
              <img src={WhiteLoadingAnimation} alt="Loading..." className="h-10 w-10 py-0" />
            ) : (
              'Confirm Refund & Send Email'
            )}
          </button>
        </div>
      </div>

      <div className="absolute  mt-4 left-[12px] top-[120px] rounded-lg border-solid border-[1.782px] border-gray-950 flex items-center px-2 py-2 w-[239px]">
        <div className="flex-grow text-[#000000CC] text-sm font-medium font-['DM Sans']">
          XXXX XXXX XXXX 2345
        </div>
        <div className="w-[46px]">
          <img className="h-[17px]" src={VisaCardIcon} alt="Visa" />
        </div>
      </div>

      {/* Show sent Modal */}
      {showRefundSucessModal && (
        <SucessModal text={'Refund Placed Successfully'} handleModal={handleCloseRefundModal} />
      )}
    </div>
  );
};

export default RefundConfirmation;
