import React, { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { IoSearch, IoSearchSharp } from "react-icons/io5";
import { CiFilter } from "react-icons/ci";
import { useSelector } from "react-redux";
import DateUpDown from "../../assets/documentPageAssets/DateUpdown.svg";
import axios from "axios";
import { formatDate } from "../../services/logics";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import PopupModal from "../../components/PopupModal";
import DraftsPagination from "../../components/DraftsPagination";
import DraftShimmer from "../../components/DraftShimmer";
import axiosInstance from "../../services/axiosInstance";
import AdminAddStorePage from "../../components/AdminPageComponents/AdminAddStorePage";

const AdminStorePage = () => {
  const navigate = useNavigate();
  const [stores, setStores] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const API_URL = process.env.REACT_APP_API_URL;
  const [isAscending, setIsAscending] = useState(false);
  const [showAddStoreModal, setShowAddStoreModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const dropdownRef = useRef(null);
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("ALL");
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);

  const fetchStores = async (searchTerm, page = 1, is_active = selectedFilter) => {
    try {
      let isActiveFilter = null;

      // Determine the value for the is_active filter based on selectedFilter
      if (is_active === "ACTIVE") {
        isActiveFilter = true;
      } else if (is_active === "INACTIVE") {
        isActiveFilter = false;
      }

      const res = await axiosInstance.get(`${API_URL}/v1/admin/stores`, {
        params: {
          page: page,
          page_size: 10,
          search: searchTerm,
          is_active: isActiveFilter, // Applying filter here
        },
      });

      const data = res.data;
      setStores(data.stores);
      setTotalRecords(data.total);
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
    setIsFetching(false);
  };

  // Debounced search function to prevent multiple API calls
  const debouncedSearch = useCallback(
    debounce((term, selectedFilter) => {
      setPage(1);
      fetchStores(term, 1, selectedFilter); // Pass selectedFilter to debounced search
    }, 500),
    []
  );

  // Fetch stores whenever the page or selected filter changes
  useEffect(() => {
    fetchStores(searchTerm, page, selectedFilter);
  }, [page, selectedFilter]);

  // Handle the filter change (e.g., ALL, ACTIVE, INACTIVE)
  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    setPage(1); // Reset to first page when filter changes
    fetchStores(searchTerm, 1, filter);
  };


  // Modal handlers
  const handleAddStoreModal = () => {
    setShowAddStoreModal(true);
  };

  const handleCloseAddStoreModal = () => {
    setShowAddStoreModal(false);
    fetchStores(searchTerm, page, selectedFilter);
  };

  // Clean up event listeners when dropdown is not open
  useEffect(() => {
    if (filterDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterDropdownOpen]);

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedSearch(value, selectedFilter);
  };

  const handleSort = () => {
    setIsAscending((prev) => !prev);

    const sortedStores = [...stores].sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);

      return isAscending ? dateB - dateA : dateA - dateB;
    });

    setStores(sortedStores);
  };

  // Handle pagination change
  const handlePageChange = (newPage) => {
    if (newPage !== page) {
      setIsFetching(true);
      setPage(newPage);
      fetchStores(searchTerm, newPage, selectedFilter);
    }
  };

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen((prev) => !prev);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setFilterDropdownOpen(false);
    }
  };

  return (

    <div className={`py-3 flex flex-col  font-dmsans ${isSidebarOpen ? 'ml-[244px] px-6' : 'ml-[80px] px-8'} transition-all ease-in-out duration-500 overflow-hidden`}>

      <div className="flex items-center justify-between ml-1 mb-4 ">
        <div className="flex items-center "></div>

        <div className="flex items-center gap-[14px]">
          <div className="relative w-[260px] h-[40px] border border-[#868CA2] bg-white text-[#000000] rounded-md">
            <IoSearch className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-[#64748B]" />
            <input
              type="text"
              placeholder="Search"
              className="focus:outline-none w-[84%] h-full ml-8 text-[#64748B] text-[15px]"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>

          <div className="relative inline-block text-left" ref={dropdownRef}>
            <button
              onClick={toggleFilterDropdown}
              className={`flex items-center h-[40px] w-full md:w-[150px] justify-center cursor-pointer py-2 border border-gray-300 rounded-md hover:bg-gray-100 `}
            >
              <CiFilter className="mr-2 text-[#64748B] w-[15px] h-[15px]" strokeWidth={2} />
              <span className="text-sm font-medium text-[#64748B]">Filter by status</span>
            </button>
            {filterDropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-50">
                <button
                  onClick={() => { setSelectedFilter("ALL"); toggleFilterDropdown() }}
                  className={`w-full px-4 py-2 text-left text-gray-700 border-b border-gray-200   hover:bg-gray-100 ${selectedFilter === "ALL" ? "bg-[#57F0010D]" : ""}`}
                >
                  All
                </button>
                <button
                  onClick={() => { setSelectedFilter("ACTIVE"); toggleFilterDropdown() }}
                  className={`w-full px-4 py-2 text-left text-gray-700 border-b border-gray-200   hover:bg-gray-100 ${selectedFilter === "ACTIVE" ? "bg-[#57F0010D]" : ""}`}
                >
                  Active
                </button>
                <button
                  onClick={() => { setSelectedFilter("INACTIVE"); toggleFilterDropdown() }}
                  className={`w-full px-4 py-2 text-left text-gray-700 border-b border-gray-200   hover:bg-gray-100 ${selectedFilter === "INACTIVE" ? "bg-[#57F0010D]" : ""}`}
                >
                  Inactive
                </button>
              </div>
            )}
          </div>
          <button
            onClick={handleAddStoreModal}
            className="flex justify-center items-center bg-[#96F3C3] font-medium font-dmsans leading-normal text-base py-2 w-[156px] text-[#2B2E3A] h-[40px] rounded-md"
          >
            Add Store
          </button>
        </div>
      </div>

      {/* Header */}
      <div className="grid grid-cols-5 items-center bg-[#E1E1E64D] h-[40px]">
        <div className="pl-4 text-[#2B2E3A] font-medium text-[14px] w-[180px]">Store ID</div>
        <div className="pl-4 text-[#2B2E3A] font-medium text-[14px] w-[240px]">Store Name</div>
        <div className="pl-4 text-[#2B2E3A] font-medium text-[14px] w-[240px]">Owner Name</div>

        <div className="flex items-center text-[#2B2E3A] font-medium text-[14px] w-[240px] cursor-pointer" onClick={handleSort}>
          <span>Created On</span>
          <img className="ml-2" src={DateUpDown} alt="Sort Icon" loading="lazy" />
        </div>

        <div className="text-[#2B2E3A] font-medium text-[14px] w-[300px] text-start ml-2">Status</div>
      </div>


      {/* Records */}
      {
        isFetching ? (
          <DraftShimmer show={false} />
        ) : stores?.length === 0 ? (
          <div className="flex justify-center py-8 text-red-500">
            No records found.
          </div>
        ) : (
          <>
            <div className="overflow-auto  custom-scrollbar mb-2">
              {stores?.map((store) => (
                <div
                  key={store.id}
                  className="grid grid-cols-5 items-center py-2 border-b hover:bg-[#39F6A30D] min-h-[56px]"
                  onClick={() => navigate(`/admin/store-page/details/${store.id}`)}
                >

                  <div className="flex items-center pl-4 w-[180px] font-semibold">
                    <div className="">
                      <p className="font-medium text-[#191D23] text-xs">{store?.store_id ? `#${store.store_id}` : ''}</p>
                    </div>
                  </div>
                  {/* Name */}
                  <div className="flex items-center pl-4 w-[240px] font-semibold pr-8">
                    <div className="">
                      <p className="font-medium  text-[#191D23] text-xs break-all">{store?.name}</p>
                    </div>
                  </div>
                  <div className="flex items-center pl-4 w-[240px] font-semibold pr-10">
                    <div className="ml-1">
                      <p className="font-medium text-[#191D23] text-xs break-all">{store?.owner_name}</p>
                    </div>
                  </div>

                  <div className="text-[#868CA2] items-center text-xs w-[240px] break-words ml-1">
                    {formatDate(store?.created_at)}
                  </div>

                  {/* Status */}
                  <div className="flex  items-center ">
                    <span
                      className={`inline-block px-3 py-1 text-sm rounded-full text-start ${store.is_active
                        ? 'bg-[#57F0010D] text-[#59D912]'
                        : 'bg-[#4141410D] text-[#414141]'
                        }`}
                      style={{ minWidth: '80px', textAlign: 'center' }} // Ensure a consistent width for all badges
                    >
                      {store.is_active ? "Active" : "In-active"}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            {/* Pagination */}
            <DraftsPagination
              currentPage={page}
              onPageChange={handlePageChange}
              totalDrafts={totalRecords}
            />
          </>
        )
      }

      {
        showAddStoreModal && (
          <PopupModal closeModal={handleCloseAddStoreModal} className="bg-[#FFFFFF] border border-[#868CA280]">
            <AdminAddStorePage handleCloseAddStoreModal={handleCloseAddStoreModal} />
          </PopupModal>
        )
      }
    </div>

  );
};

export default AdminStorePage;
