import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";
import PopupModal from "../components/PopupModal";
import RestoreIcon from "../assets/IntegrationPageAssets/RestoreIcon.svg";
import axios from "axios";
import { LuTrash2 } from "react-icons/lu";
import { toast } from "react-toastify";
import EmailSkeleton from "../components/EmailSkeleton";
import { formatCustomDateTime } from "../services/logics";
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import DraftDetailsSidebar from "../components/DraftDetailsSidebar.jsx";
import { extractNameAndEmail } from "../services/emailUtils.js";
import RefundConfirmation from "../components/RefundConfirmation.jsx";
import AttachmentItem from "../components/DraftDetailPageComponents/AttachmentItem.jsx";
import NewAddressForm from "../components/DraftDetailPageComponents/NewAddressform.jsx";
import SucessModal from "../components/SucessModal.jsx";
import WhiteLoadingAnimation from "../assets/WhiteLoadingAnimation.svg";
import ExchangeItem from "../components/DraftDetailPageComponents/ExchangeItem.jsx";
import FeaturedIcon from "../assets/FeaturedIcon.svg";
import axiosInstance from "../services/axiosInstance.js";
const DraftDetailPage = () => {
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const navigate = useNavigate();
  const param = useParams();
  const location = useLocation();
  const [selectedReason, setSelectedReason] = useState("Draft should not have been created");
  const [selectedDraftReason, setSelectedDraftReason] = useState("Draft quality is not good");
  const [isOpen, setIsOpen] = useState(false);
  const [draftData, setDraftData] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [draftEmail, setDraftEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showSentModal, setShowSentModal] = useState(false);
  const [showSentMailModal, setShowSentMailModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false)
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  const [sentMessage, setSentMessage] = useState("");
  const [sendingDraft, setSendingDraft] = useState(false);
  const [orderData, setOrderData] = useState(null);
  const [isFetchingOrderData, setIsFetchingOrderData] = useState(true);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [userData, setUserData] = useState(null);
  const [transactionData, settransactionData] = useState([]);
  const [isFetchingUserData, setIsFetchingUserData] = useState(true);
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [refundSucess, setRefundSucess] = useState(false)
  const [showNewAddress, setShowNewAddress] = useState(false)
  const [addressUpdate, setAddressUpdated] = useState(false)
  const [addressSucessModal, setAddressSucessModal] = useState(false)
  const [expandedEmailIndex, setExpandedEmailIndex] = useState(null);
  const [isExchnageItem, setIsExchangeItem] = useState(null);
  const [deleteContext, setDeleteContext] = useState('');
  const [updatingDraft, setUpdatingDraft] = useState(false);
  const [orderExchangeUpdate, setorderExchangeUpdated] = useState(false)
  const [exchnageSucessModal, setExchangeSucessModal] = useState(false)


  useEffect(() => {
    // Expand the last email by default
    if (draftData?.mail_content?.length > 0) {
      setExpandedEmailIndex(draftData.mail_content.length - 1);
    }
  }, [draftData]);

  const toggleEmailExpansion = (index) => {
    setExpandedEmailIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const handleCloseSentModal = () => {
    setShowSentModal(false);
  };



  const handleCloseSentMailModal = () => {
    setShowSentMailModal(false);
    navigate("/draft-page", { state: location.state }); // Pass the previous state back
  };

  const handleDraftChange = (e) => {
    const updatedDraft = e.target.value;
    setDraftEmail(updatedDraft);
    debouncedUpdateDraftHandler(updatedDraft, false);
  };

  const handleOpenDeleteMailModal = () => {
    setDeleteContext('mail');
    setShowModal(true);
  };

  const handleOpenDeleteDraftModal = () => {
    setDeleteContext('draft');
    setShowModal(true);
  };


  const fetchData = async () => {
    setIsFetching(true);
    try {
      const response = await axiosInstance.get(
        `${API_URL}/v1/store/drafts/${param.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data;
      setDraftData(data);
      setDraftEmail(data.final_draft);
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
    setIsFetching(false);
  };

  const updateIsReadStatus = async () => {
    try {
      await axiosInstance.patch(
        `${API_URL}/v1/store/drafts/${param.id}`,
        { is_read: true },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
  };

  useEffect(() => {
    fetchData();
    updateIsReadStatus();
    fetchUserData();
    fetchOrderData();
    setShowRefundModal(false);
    setRefundSucess(false);
    const handleEscapeKey = (e) => {
      if (e.key === "Escape") {
        handleCloseModal();
      }
    };

    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [param?.id]);

  const handleCloseModal = () => {
    setShowModal(false);
    setDeleteContext('')
  };

  const handleDeleteMail = async () => {
    setIsDeleting(true);
    try {
      const res = await axiosInstance.delete(`${API_URL}/v1/store/drafts/${param.id}?delete_reason=${selectedReason}` );
      const data = res.data;
      toast.success(`${data.detail}`);
      handleCloseModal();
      navigate("/draft-page", { state: location.state }); // Pass the previous state back
    } catch (error) {
      toast.error(`${error.response.data.detail}`);
    }
    setIsDeleting(false)
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSendDraftModal = () => {
    setShowSentModal(true);
  };

  const handleSendDraft = async () => {
    try {
      setSendingDraft(true);

      const res = await axiosInstance.post(
        `${API_URL}/v1/store/drafts/${param.id}/send`, {
        delete_reason: ""
      },
       
      );

      const data = res.data;
      if (res.status === 200) {
        fetchData();
        fetchOrderData();
        fetchUserData();
        setShouldFetchData(true);
      }
      setSentMessage(data.msg);
      setSendingDraft(false);
    } catch (error) {
      setSentMessage(error.response?.data?.detail);
      setSendingDraft(false);
    }

    if (!sendingDraft) {
      setShowSentModal(false);
      setShowSentMailModal(true);
    }
  };

  const handleSelect = (reason) => {
    setSelectedReason(reason);
    setIsOpen(false);
  };

  const updateDraft = async (updatedDraft, updateActions,delete_reason="") => {
    setUpdatingDraft(true);
    try {
      let payload = {
        final_draft: updatedDraft,
        delete_reason:delete_reason
      };

      if (updateActions) {
        const updatedActions = draftData.actions_data.actions.filter(action => action !== 'order_refund');

        payload.actions_data = {
          ...draftData.actions_data,
          actions: updatedActions
        };
      }

      const res = await axiosInstance.patch(
        `${API_URL}/v1/store/drafts/${param.id}`,
        payload,
      
      );

      const data = res.data;
      if (delete_reason) {
        setDraftEmail(""); // Clear the textarea content
      }
      handleCloseModal();
      if (res.status === 200 && updateActions) {
        fetchData();
        fetchOrderData();
        fetchUserData();
        setShouldFetchData(true);
      }
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
    setUpdatingDraft(false)
  };
  const debouncedUpdateDraftHandler = useCallback(debounce((updatedDraft, updateActions) => {
    updateDraft(updatedDraft, updateActions);
  }, 500), []);

  const fetchOrderData = async () => {
    setIsFetchingOrderData(true);
    try {
      const response = await axiosInstance.get(
        `${API_URL}/v1/store/draft/orders?draft_id=${param.id}`,
       
      );
      const data = response.data;
      setOrderData(data);
    } catch (error) {
      console.log(error);
    }
    setIsFetchingOrderData(false);
  };

  const fetchUserData = async () => {
    setIsFetchingUserData(true);
    try {
      const response = await axiosInstance.get(
        `${API_URL}/v1/store/drafts/${param.id}/customer-details`

      );
      const data = response.data;
      setUserData(data);
    } catch (error) {
      console.log(error)
    }
    setIsFetchingUserData(false);
  };


  const handleOpenRefundModal = async () => {
    if (!isFetchingOrderData && !isFetchingUserData) {
      try {
        const response = await axiosInstance.get(
          `${API_URL}/v1/store/shopify/transactions?order_id=${orderData[0]?.id}`,
          
        );
        const data = response.data;
        settransactionData(data);
      } catch (error) {
        console.log(error);
      }
      setShowRefundModal(true);
    }
  };


  const handleCloseRefundModal = () => {
    setShowRefundModal(false);
  };


  const getActionsIconPath = (dataType) => {
    const type = typeof dataType === 'string' ? dataType.toLowerCase() : '';
    if (type.includes("address")) {
      return { name: 'Update Address', action: handleShowNewAddress };
    } else if (type.includes("refund")) {
      return { name: 'Refund', action: handleOpenRefundModal };
    } else if (type.includes("change")) {
      return { name: 'Exchange Item', action: handleExchangeItem };
    }
  };

  const handleShowNewAddress = () => {
    setShowNewAddress(true)
  }
  const handleClosShowNewAddress = () => {
    setShowNewAddress(false)
  }
  const handleCloseAddressModal = () => {
    setAddressSucessModal(false)
    handleClosShowNewAddress();
    setAddressUpdated(true);
    setShouldFetchData(true);
    fetchOrderData();
    fetchUserData();
  }
  const getInitials = (name) => {
    return name ? name.charAt(0).toUpperCase() : "";
  };


  const handleExchangeItem = () => {
    setIsExchangeItem(true)
  }
  const handleClosExchangeItem = () => {
    setIsExchangeItem(false)
  }
  const handleCloseExxchangeModal = () => {
    setExchangeSucessModal(false)
    handleClosExchangeItem ();
    setorderExchangeUpdated(true);
    setShouldFetchData(true);
    fetchOrderData();
    fetchUserData();
  }
  return (
    <div className={`${isSidebarOpen ? 'ml-[234px]' : 'ml-[80px]'} transition-all duration-300 bg-[#FFFFFF] h-screen font-dmsans`}>
      <div className="flex flex-col h-auto bg-[#FFFFFF]">
        <div className="flex flex-1">
          <div className={`flex flex-col flex-grow ${showRefundModal ? 'py-1 ml-4' : 'py-3 ml-5'} `}>
            {!showRefundModal ? (
              <div className="flex flex-col flex-grow ">
                <div className="flex items-start justify-between font-medium">
                  <div className="flex items-center">
                    <button data-testid="back-button" className="flex items-center mr-4" onClick={() => navigate("/draft-page", { state: location.state })}>
                      <IoIosArrowRoundBack className="w-7 h-7 text-[#2B2E3A]" />
                    </button>

                  </div>
                  <div className="flex items-center space-x-2">
                    {refundSucess ? (
                      <div className="inline-flex items-center px-3 h-[38px] bg-[#39F6A31A] rounded-md">
                        <div className="h-2.5 w-2.5 bg-[#28FB03] rounded-full mr-2 animate-pulse"></div>
                        <span className="text-[#000000A1] font-medium text-[10px]">Refunded</span>
                      </div>) : ""}
                    <button className="flex h-[32px] items-center justify-center gap-1.5  pl-1.5 pr-2.5 text-[12px]  text-gray-800 bg-white  leading-snug" onClick={handleOpenDeleteMailModal}>
                      <LuTrash2 className="w-[18px] h-[18px] text-[#868CA2]" />
                      <span className="text-[#868CA2]">Delete</span>
                    </button>
                  </div>
                </div>
                <div className="flex flex-col  mt-3 bg-white ">
                  {isFetching  ? (
                    <EmailSkeleton />
                  ) : (
                    <div className="overflow-y-auto custom-scrollbar overflow-x-hidden min-h-[300px]">
                      <div data-testid='draft-subject' className="text-base font-medium pl-2 text-[#2B2E3A] mb-3">
                        {draftData?.mail_content?.[0]?.subject}
                      </div>
                      {draftData?.mail_content?.map((data, index) => (
                        <React.Fragment key={index}>
                          <div
                            className={`flex justify-between gap-5 font-medium mr-2 cursor-pointer ml-3 mb-3 ${expandedEmailIndex !== index ? 'border-b border-[#868CA280]' : ''}`}
                            onClick={() => toggleEmailExpansion(index)}
                          >
                            <div className="flex flex-col w-full">
                              <div className="flex items-center gap-2.5 justify-between">
                                <div className="flex items-center gap-2.5">
                                  <div className="flex-shrink-0 w-8 h-8 bg-[black] rounded-full flex items-center justify-center text-[#61F8B5] text-lg font-semibold">
                                    {data?.sender ? getInitials(extractNameAndEmail(data?.sender)?.name) : getInitials('Unknown Sender')}
                                  </div>
                                  <div className="flex flex-col">
                                    <div className="text-xs font-medium text-[#1B1A22]">
                                      {data?.sender ? extractNameAndEmail(data.sender)?.name : 'Unknown Sender'}
                                    </div>
                                    <div className="text-[11px] font-medium text-[#868CA2]">
                                      {data?.sender ? extractNameAndEmail(data.sender)?.email : 'Unknown Sender'}
                                    </div>
                                  </div>
                                </div>
                                <div className="ml-auto flex flex-col font-medium text-[11px] text-[#868CA2]">
                                  <div className="flex space-x-1 text-[#1B1A22]">
                                    <span>{formatCustomDateTime(data.timestamp, true)}</span>
                                  </div>
                                  <span className="text-right">{new Date(data.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
                                </div>
                              </div>
                              {/* Conditionally display partial or full content */}
                              <div className="text-xs font-medium text-[#868CA2] w-[90%]  mt-2">
                                {expandedEmailIndex === index ? (
                                  <div data-testid='emailContent' className="ml-2 whitespace-pre-wrap break-words font-dmsans text-xs font-medium leading-14 text-[#868CA2] w-[90%] mb-3 pb-3">
                                    {data.body}
                                  </div>
                                ) : (
                                  <div className="ml-2 whitespace-pre-wrap break-words font-dmsans text-xs font-medium leading-14 text-[#868CA2] w-[90%] mb-2 pb-2">
                                    {data.body.slice(0, 60)}...
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          {expandedEmailIndex === index && data.attachments && data.attachments.length > 0 && (
                            <div className="mt-3 mb-4 ml-5 text-xs text-[#2B2E3A] pt-1  border-t border-[#868CA233]">
                              <div className="text-[#000000] text-sm font-medium">{data.attachments.length} Attachments</div>
                              <div className="flex gap-4 mt-2 ">
                                {data.attachments.map((attachment, attachmentIndex) => (
                                  <AttachmentItem key={attachmentIndex} attachment={attachment} message_id={data.messageId} />
                                ))}
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                  {showNewAddress ? (<NewAddressForm thread_id={draftData.thread_id} address={draftData?.actions_data?.address_details} orderData={orderData} handleClosShowNewAddress={handleClosShowNewAddress} setAddressSucessModal={setAddressSucessModal} draft_id={param.id} />) : isExchnageItem ? (
                    <ExchangeItem
                      thread_id={draftData.thread_id}
                      exchangeDetails={draftData?.actions_data?.action_details?.order_details || draftData?.actions_data?.order_details }
                      orderData={orderData}
                      handleClosExchangeItem ={handleClosExchangeItem}
                      setExchangeSucessModal={setExchangeSucessModal}
                      draft_id={param.id}
                    />
                  ) : (
                    <div className="flex flex-col pt-2  mt-10 border border-[#868CA24D] rounded-lg">
                      {!isFetching &&
                        <div className="flex gap-5 pb-2 text-xs font-medium border-b border-[#868CA24D] leading-none text-gray-800">
                          <div className="flex items-center gap-2 ml-4">
                            <div className="text-[#868CA2] text-[12px]">To:</div>
                            <div className="text-[#2B2E3A] p-2 rounded-md bg-[#F3F3F3] text-[12px]">{draftData?.sender_email ? extractNameAndEmail(draftData.sender_email)?.name : 'Unknown Sender'}</div>
                          </div>
                        </div>}
                      {isFetching ? (
                        <EmailSkeleton show={true} />
                      ) : (
                        <>
                          <div className="flex flex-col pt-2 mt-1.5 bg-white h-[340px] rounded-lg">
                            <textarea
                              onChange={handleDraftChange}
                              value={draftEmail}
                              className={`w-full font-dmsan focus:outline-none bg-white  text-xs font-medium leading-4 text-[#868CA2]  ${(refundSucess || addressUpdate || draftData?.is_email_sent === true || orderExchangeUpdate) ? 'h-[300px] min-h-[180px] cursor-default' : 'h-[260px] min-h-[180px]'} overflow-y-auto custom-scrollbar text-[#868CA2] resize-none pl-4 pr-[6%] pb-2`}
                              data-testid="draft-textarea"
                              disabled={(refundSucess || addressUpdate || draftData?.is_email_sent === true ||orderExchangeUpdate)}
                            />
                            {!(refundSucess || addressUpdate || draftData?.is_email_sent === true || orderExchangeUpdate) && (
                              <div className="flex justify-between items-center mt-6 px-2">
                                {/* Left side - Delete Button */}
                                <button className="flex h-[32px] items-center justify-center gap-1.5 pl-1.5 pr-2.5 text-[12px] text-gray-800 bg-white leading-snug" onClick={handleOpenDeleteDraftModal}>
                                  <LuTrash2 className="w-[18px] h-[18px] text-[#868CA2]" />
                                  <span className="text-[#868CA2]">Delete Draft</span>
                                </button>

                                {/* Right side - Action Buttons */}
                                <div className="flex gap-3 text-[#2B2E3A] justify-end">
                                  {draftData.actions_data?.actions && draftData.actions_data.actions.length > 0 && (
                                    draftData.actions_data.actions.map((action) => {
                                      const actionDetails = getActionsIconPath(action.name || action);
                                      return actionDetails && (
                                        <button
                                          key={action.name || action}
                                          className="flex items-center gap-2 justify-center text-[11px] min-w-[80px] w-auto px-2 h-[28px] font-medium bg-[white] border border-[#D0D5DD] rounded-md"
                                          onClick={actionDetails.action}
                                        >
                                          <div className="text-center text-[#2B2E3A]">{actionDetails.name}</div>
                                        </button>
                                      );
                                    })
                                  )}
                                  <button
                                    className="flex items-center gap-2 justify-center text-xs min-w-[80px] w-auto h-[28px] font-medium bg-[#96F3C3] rounded-md"
                                    onClick={handleSendDraftModal}
                                  >
                                    <div className="text-center text-[#2B2E3A]">Send Mail</div>
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>)}
                </div>
              </div>) : (
              <div className="bg-white min-w-[440px] flex flex-grow mb-2">
                <RefundConfirmation setShowRefundModal={setShowRefundModal} showRefundModal={showRefundModal} orderData={orderData} transactionData={transactionData} refundDetails={draftData.actions_data?.refund_details} draftData={draftData} updateDraft={updateDraft} draftEmail={draftEmail} setRefundSucess={setRefundSucess} />
              </div>
            )}
          </div>
          <div className="flex flex-col ml-1 ">
            <div className="flex flex-col font-medium  bg-opacity-20 min-h-[850px] h-screen px-2">
              <div className="bg-[#FFFFFF] my-2 ">
                <div className="flex flex-col min-h-[792px] w-[300px]  mb-1 bg-[#FFFFFF] overflow-y-auto scrollbar mr-[2px] pr-[3px] items-center">
                  {/* Add content here */}
                  {!isFetching && (<DraftDetailsSidebar thread_id={draftData.thread_id} userData={userData} isFetchingOrderDat={isFetchingOrderData} orderData={orderData} shouldFetchData={shouldFetchData} setShouldFetchData={setShouldFetchData} sender_email={draftData.sender_email} isFetchingUserData={isFetchingUserData} />)}
                </div>
              </div>
            </div>
          </div>
        </div>

        {showModal && (
          <PopupModal closeModal={handleCloseModal} className="bg-[#F9F9F9]">
            <div className="flex flex-col justify-center items-center h-[200px] w-[360px]">
              <img src={FeaturedIcon} alt="" className="mb-2" />
              <div className="text-[#101828] font-medium text-lg mb-2">
                {deleteContext === 'mail' ? 'Delete Mail' : 'Delete Draft'}
              </div>
              {deleteContext === 'mail' ? (
                <p className="text-[#667085] font-dmsans text-[15px] font-normal mb-4 text-center">
                  Are you sure you want to delete this mail? This action cannot be undone.
                </p>
              ) : (
                <p className="text-[#667085] font-dmsans text-[15px] font-normal mb-4 text-center">
                  Confirm deletion of this draft? This <br/> action is irreversible.
                </p>
              )}
              <div className="flex justify-center space-x-6 w-full">
                <button
                  className="text-base font-dmsans bg-[#F3F3F3] text-[#344054] rounded-md shadow-sm w-[150px] h-[39px] flex items-center justify-center"
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
                <button
                  data-testid='deleteButton'
                  type="button"
                  onClick={deleteContext === 'mail' ? handleDeleteMail : () => updateDraft("", false, selectedDraftReason)}
                  disabled={(isDeleting|| updatingDraft)}
                  className="w-[150px] h-[39px] rounded-md bg-[#D92D20] text-base font-medium text-[#FFFFFF] text-center flex items-center justify-center"
                >
                  {(isDeleting|| updatingDraft)? (
                    <img src={WhiteLoadingAnimation} alt="Loading..." className="h-10 w-10" />
                  ) : (
                    "Delete"
                  )}
                </button>
              </div>
            </div>
          </PopupModal>
        )}

        {/* send modal confirmation*/}
        {showSentModal  && (
          <PopupModal closeModal={handleCloseSentModal} className="bg-[#F9F9F9]">
            <div className="flex flex-col justify-center items-center h-[200px] w-[360px]">
              <img src={RestoreIcon} alt="" className="mb-3" />
              <div className="text-[#101828] font-medium text-lg mb-2">Send Draft</div>
              <p className="text-[#667085] font-dmsans text-[15px] font-normal mb-6 text-center">
                Are you sure, you want to send this draft?
              </p>
              <div className="flex justify-center space-x-6 w-full">
                <button
                  className="text-base font-dmsans bg-[#F3F3F3] text-[#344054] rounded-md shadow-sm w-[150px] h-[44px] flex items-center justify-center"
                  onClick={handleCloseSentModal}
                >
                  Cancel
                </button>
                <button
                  data-testid='sendDraft'
                  type="button"
                  onClick={() => handleSendDraft()}
                  disabled={sendingDraft}
                  className="w-[150px] h-[44px] rounded-md bg-[#039855] text-base text-[white] font-medium text-center flex items-center justify-center"
                >
                
                  {sendingDraft ? (
                    <img src={WhiteLoadingAnimation} alt="Loading..." className="h-10 w-10" />
                  ) : (
                    "Send Mail"
                  )}
                </button>
              </div>
            </div>
          </PopupModal>

        )}

        {/* successfully send modal*/}

        {showSentMailModal  && (
          <SucessModal text= {sentMessage} handleModal={handleCloseSentMailModal} />

        )}

        {addressSucessModal && (
          <SucessModal text={'Address updated successfully'} handleModal={handleCloseAddressModal} />
        )}

        {exchnageSucessModal && (
          <SucessModal text={'Order updated successfully'} handleModal={handleCloseExxchangeModal} />
        )}


      </div>
    </div>
  );
};

export default DraftDetailPage;
