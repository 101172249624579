import React, { useState, useEffect } from "react";
import AddFileIcon from "../assets/documentPageAssets/AddfileIcon.svg";
import AddFileIconGreen from "../assets/documentPageAssets/AddFileIconGreen.svg";
import AssignmentIcon from "../assets/documentPageAssets/AssignmentIcon.svg";
import { formatCustomDateTime } from "../services/logics";
import CheckCircle from "../assets/documentPageAssets/CheckCircle.svg";
import LoadingBar3 from "../assets/documentPageAssets/LoadingBar3.gif";
import BouncingGif from "../assets/documentPageAssets/bouncingGif.gif";
import axios from "axios";
import DocumentListShimmer from "../components/DocumentSkeleton.jsx";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { TbAlertTriangle } from "react-icons/tb";
import { useSelector } from 'react-redux';
import FeaturedIcon from "../assets/FeaturedIcon.svg";
import PopupModal from "../components/PopupModal";
import WhiteLoadingAnimation from "../assets/WhiteLoadingAnimation.svg";
import SucessModal from "../components/SucessModal.jsx";
import DeleteIcon from "../assets/documentPageAssets/DeleteIcon.jsx";
import DownloadIcon from "../assets/documentPageAssets/DownloadIcon.svg";
import DraftsPagination from "../components/DraftsPagination.jsx";
import axiosInstance from "../services/axiosInstance.js";


const statusSvgGenerator = (color) => {
  const svgString = `
    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
      <circle cx="3.5" cy="3.5" r="3.5" fill="${color}" />
    </svg>
  `;
  const base64EncodedSvg = btoa(unescape(encodeURIComponent(svgString)));
  const dataURI = `data:image/svg+xml;base64,${base64EncodedSvg}`;

  return dataURI;
};

const DocumentsPage = () => {
  const [file, setFile] = useState(null);
  const [isDragOver, setIsDragOver] = useState(false);
  // const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // State for error message
  const token = localStorage.getItem("token");
  const API_URL = process.env.REACT_APP_API_URL;
  const [isAscending, setIsAscending] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const navigate = useNavigate();
  const ownerName = localStorage.getItem("firstname");
  const [totalFiles, setTotalFiles] = useState(0);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null); // State for storing selected file ID
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    fetchData();
  }, [pageNum]);

  const fetchData = async () => {
    setIsFetching(true);
    try {
      const response = await axiosInstance.get(`${API_URL}/v1/store/documents?page=${pageNum}&page_size=5`, {
       
      });

      const data = response.data;

      const filteredData = data.documents.map(file => ({
        id: file.id,
        uploaded_on: file.uploaded_on,
        name: file.name,
        status: file.status,
        url: file.url,
        size: (file.size / 1024).toFixed(2) + " KB",
      }));
      setTotalFiles(data.total);
      setUploadedFiles(filteredData);
      setIsFetching(false);
    } catch (error) {
      toast.error(error.response?.data?.detail);
      setIsFetching(false);
    }
  };


  const handleCloseModal = () => {
    setFile(null);
  };
  const handleCloseDownloadModal = () => {
    setShowDownloadModal(false)
  }
  const handleDelete = () => {
    setShowDeleteModal(false);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e, type) => {

    e.preventDefault();
    setIsDragOver(false);
    let selectedFile;
    if (type === "drop") {
      selectedFile = e.dataTransfer.files[0];
    } else {
      selectedFile = e.target.files[0];
    }

    // Validate file format
    if (selectedFile && selectedFile.type !== 'application/pdf') {
      setErrorMessage('*Only .pdf files are allowed.');
      setFile(null);
      return;
    } else {
      // If valid, proceed with the upload
      setFile(selectedFile);
      setErrorMessage(''); // Clear any existing error message

      // Start the upload
      startUpload(selectedFile);
    }

  };


  function convertDateReverse(dateString) {
    const originalFormat = formatCustomDateTime(dateString);
    const [month, day, year] = originalFormat.replace(',', '').split(' ');
    return `${day} ${month} ${year}`; // Reverse the day and month without commas
  }

  const startUpload = async (uploadedFile) => {

    const maxSizeInBytes = 10 * 1024 * 1024;

    if (uploadedFile.size > maxSizeInBytes) {

      setFile(null);
      toast.error('File size exceeds the limit of 10 MB.');
      return;
    }

    if (!uploadedFile) return;

    const formData = new FormData();
    formData.append("file", uploadedFile);

    try {
      setIsUploading(true);
      const response = await axiosInstance.post(
        `${API_URL}/v1/store/documents`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = response.data;

      const dateFetched = convertDateReverse(data.uploaded_on);
      setUploadedFiles([
        ...uploadedFiles,
        {
          id: data.id,
          name: data.name,
          status: "Processing",
          uploaded_on: data.uploaded_on,
          url: data.url
        },
      ]);
      setTotalFiles(totalFiles+1);

      setIsUploading(false);
    } catch (error) {
      if (error.response && error.response.status) {
        toast.error(error.response?.data?.detail);
      }
      setIsUploading(false);

    }

  };

  const handlePageChange = (page) => {
    setPageNum(page);
  }

  const handleDeleteDocument = async () => {
    setIsDeleting(true);
    try {
      const res = await axiosInstance.delete(`${API_URL}/v1/store/documents/${selectedFileId}`);
      const data = res.data;
      toast.success('Deleted Successfully');
      // Fetch the updated list of files after deletion
      const updatedFiles = uploadedFiles.filter(file => file.id !== selectedFileId);
      setUploadedFiles(updatedFiles);
  
      // If the current page becomes empty after deletion, move to the previous page
      if (updatedFiles.length === 0 && pageNum > 1) {
        setPageNum(prevPage => prevPage - 1);
      } else {
        fetchData(); // If there are still documents left, simply refresh the current page
      }
      setShowDeleteModal(false);
      setIsDeleting(false);
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
      setIsDeleting(false);
    }

  };
  const handleDownloadClick = (doc) => {
    setSelectedFile(doc); // Store the file details
    setShowDownloadModal(true); // Show the modal
  };

  // Function to initiate the download inside the modal
  const handleConfirmDownload = () => {
    if (selectedFile) {
      const link = document.createElement('a');
      link.href = selectedFile.url;
      link.download = selectedFile.name;
      link.click();
    }
    setShowDownloadModal(false);
  };


  return (
    <div
      className={`flex  bg-[#F9F9FB]  flex-col items-center justify-center ${isSidebarOpen ? 'ml-[240px]' : 'ml-[80px]'} transition-all duration-300 pt-12 `}
    >
      <div
        className={`bg-[#DEFBEC]  mr-20 h-[343px]  rounded-lg shadow-md p-6 border-[1px] border-primary-700 border-opacity-50 w-[688px] bg-[#868CA21A]`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={(e) => handleDrop(e, "drop")}
      >
        <div className="flex items-center justify-center mb-4">
          <span
            className={`border-[1px] bg-secondary-400  border-opacity-50  text-white rounded-full p-[22px] ${isUploading && "hidden"
              } ${file ? "" : ""}`}
          >
            <img src={file ? CheckCircle : AddFileIconGreen} alt="" />
          </span>
          {isUploading && (
            <img
              src={BouncingGif}
              alt=""
              className={`${isUploading && "h-20 w-20"}`}
            />
          )}
        </div>
        <h2
          className={`${file ? "mb-4" : ""
            } text-xl text-secondary-400  leading-normal font-dmsans capitalize  font-bold text-center`}
        >
          {isUploading
            ? "Uploading Your Document"
            : file
              ? "Successfully Uploaded"
              : "Drag and Drop your File here"}
        </h2>
        <p
          className={`${file && "hidden"
            } text-secondary-400 text-opacity-50 font-dmsans text-base font-medium mb-2 text-center`}
        >
          {isUploading ? "Please wait while we upload your document" : "Or"}
        </p>

        {isUploading ? (
          <div className="flex flex-col mb-4 w-full justify-center items-center">
            <img
              src={LoadingBar3}
              alt="Loading..."
              className="w-64 h-[10px] p-0 m-0"
            />
            <div className="text-center text-[#868CA2] font-dmsans mt-3 text-xs">Please wait while we upload your document</div>
          </div>
        ) : (
          <div className={`flex justify-center ${file ? "mb-20" : ""}`}>
            <input
              type="file"
              accept=".pdf" // Restricting the input to accept only PDF files
              value=""
              onChange={(e) => { handleDrop(e, "input-file"); console.log('called') }}
              className="hidden"
              id="fileInput"
            />
            <label
              htmlFor="fileInput"
              className={` justify-center gap-2 font-bold font-dmsans text-base ${file
                ? " rounded-lg bg-white px-3 text-secondary-400"
                : "w-[400px] px-4 bg-primary-700 text-secondary-700 "
                } inline-flex border-[1px]   py-2 cursor-pointer`}
            >
              <img
                src={AddFileIcon}
                width={"20px"}
                height={"20px"}
                className=""
                alt=""
              />
              {file ? file.name : "Upload Document"}
            </label>
            {/* {file && (
              <button
                onClick={() => setFile(null)}
                className="text-black font-bold bg-primary-700 rounded-full w-10 h-10 ml-7"
              >
                X
                <img src="" alt="" />
              </button>
            )} */}
          </div>
        )}

        {/* Display error message if any */}
        {errorMessage && (
          <p className="text-red-500 text-xs text-center">{errorMessage}</p>
        )}

        {!isUploading && <div className="flex justify-center mt-10 items-center">
          <TbAlertTriangle className="mr-2 text-[#787878] text-opacity-50 w-[20px] h-[20px]" />
          <span className="  text-[#787878]  text-opacity-50 text-xs text-center">
            Only PDF files are accepted, with a maximum file size of 10 MB.
          </span>
        </div>}
      </div>

      <div className="mt-8 mx-auto w-full pb-[101px] relative ">
        <h2 className="text-[#000000] text-base font-medium  mb-4 mt-6 text-left mx-8">
          Uploaded Document
        </h2>
        <div className="overflow-x-auto  mx-8 mb-2">
          <div className="flex items-center justify-between px-6 py-2 bg-[#FEFEFE] h-[50px] text-[#2B2E3A] font-medium text-sm border-b border-gray-200">
            <div className="w-[100px] text-center">Date</div>
            <div className="flex-1 text-center">Name</div>
            <div className="w-[186px] ">Status</div>
            <div className="w-[90px] ">Actions</div>
          </div>
          {isFetching ? (
            <DocumentListShimmer items={5} />
          ) : (
            uploadedFiles.length === 0 && !isUploading ? (
              <div className="text-center mt-20 text-red-400">No documents found</div>
            ) : (uploadedFiles.map((doc, index) => (
              <div
                key={index}
                className="flex items-center justify-between px-4 py-4 bg-[#FEFEFE] h-[60px]"
              >
                {/* Date */}
                <div className="flex items-center text-sm text-[#2B2E3A] font-medium w-[150px] mr-4">
                  {convertDateReverse(doc.uploaded_on)}
                </div>

                {/* Document Name */}
                <div className="flex flex-1 items-center gap-2 text-sm font-medium text-[#2B2E3A] truncate">
                  <img src={AssignmentIcon} alt="file icon" className="h-5 w-5" />
                  <span className="truncate">
                    {doc.name}
                  </span>
                </div>

                {/* File Size and Action Buttons */}
                <div className="flex items-center space-x-16 ml-10">
                  {/* File Size */}
                  <div className=" flex justify-start items-center w-[100px]">
                    <img
                      className="h-fit"
                      src={statusSvgGenerator(
                        `${doc.status === "Ready"
                          ? "#1BFF4D"
                          : doc.status === "Processing"
                            ? "#FFE500"
                            : "#FF2D2D"
                        }`
                      )}
                      alt=""
                    />
                    <span className={`font-dmsans px-2 py-1 rounded-full  text-base`}>
                      {doc.status}
                    </span>
                  </div>

                  {/* Download Button */}


                  <button
                    className="text-gray-500 hover:text-gray-700 bg-[#F6F6F633] border border-[#868CA21A] w-[30px] h-[30px] flex items-center justify-center"
                    onClick={() => handleDownloadClick(doc)}
                  >
                    <img
                      src={DownloadIcon}
                      alt="Download"
                      className="w-[22px] h-[22px]"
                      data-testid='download'
                    />
                  </button>


                  {/* Delete Button */}

                  <button className="text-gray-500 hover:text-gray-700 bg-[#F6F6F633] border border-[#868CA21A] flex items-center justify-center  w-[30px] h-[30px]" onClick={() => {
                    setShowDeleteModal(true); setSelectedFileId(doc.id);
                  }} data-testid="delete">
                    <DeleteIcon className="  w-[22px] h-[22px]" />
                  </button>
                </div>
              </div>
            )))
          )}
          {isUploading && (
            <div>
              <DocumentListShimmer items={1} />
            </div>
          )}
        </div>
        {uploadedFiles.length >0 &&<div className="mx-8"> {/* Adjust this container to align pagination to the left */}
          <DraftsPagination
            currentPage={pageNum}
            totalDrafts={totalFiles}
            onPageChange={handlePageChange}
          />
        </div>}
      </div>

      {file && !isUploading && (
        <SucessModal text={'Document uploaded Successfully'} handleModal={handleCloseModal}/>
        
      )}

      {/* Show delete Modal */}
      {showDeleteModal && (
        <PopupModal closeModal={handleDelete} className="bg-[#F9F9F9]">
          <div className="flex flex-col justify-center items-center  h-[200px] w-[360px]">
            <img src={FeaturedIcon} alt="" className="mb-2" />
            <div className="text-[#101828] font-medium text-lg mb-2">Delete document</div>
            <p className="text-[#667085] font-dmsans text-[15px] font-normal mb-4 text-center">
            Are you sure you want to delete this <br/> document? This action cannot be undone.
            </p>
            <div className="flex justify-center space-x-6 w-full">
              <button
                className="text-base font-dmsans bg-[#F3F3F3] text-[#344054] rounded-md shadow-sm w-[150px] h-[39px] flex items-center justify-center"
                onClick={handleDelete}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleDeleteDocument}
                disabled={isDeleting}
                className="w-[150px] h-[39px] rounded-md bg-[#D92D20] text-base font-medium text-[#FFFFFF] text-center flex items-center justify-center"
              >
                {isDeleting ? (
                  <img src={WhiteLoadingAnimation} alt="Loading..." className="h-10 w-10" />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </div>
        </PopupModal>
      )}

      {/* Download Confirmation Modal */}
      {showDownloadModal && (
        <PopupModal closeModal={handleCloseDownloadModal} className="bg-[#F9F9F9]">
          <div className="flex flex-col justify-center items-center h-[200px] w-[360px]">
            <div className="flex flex-col items-center space-y-7">
              <img
                src={DownloadIcon}
                alt="Download"
                className="w-[44px] h-[44px]"
                style={{
                  filter: 'invert(73%) sepia(84%) saturate(336%) hue-rotate(82deg) brightness(97%) contrast(97%)'
                }}
              />
              <p className="text-lg font-medium  text-center">Do you want to download this file?</p>
              <div className="flex space-x-4">
                <button
                  className="text-base font-dmsans bg-[#F3F3F3] text-[#344054] rounded-md shadow-sm w-[150px] h-[44px] flex items-center justify-center"
                  onClick={handleCloseDownloadModal}
                >
                  Cancel
                </button>
                <button
                  className="px-3 w-[150px] h-[44px] rounded-md  font-dmsans bg-[#96F3C3] text-base text-[#000000] font-medium flex items-center justify-center"
                  onClick={handleConfirmDownload}
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        </PopupModal>

      )}
    </div>
  );
};

export default DocumentsPage;
