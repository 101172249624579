import React, { useState } from 'react';
import { 
  UndoRedo, 
  BoldItalicUnderlineToggles, 
  ListsToggle, 
  CreateLink,
  Separator,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';


const CustomToolbar = ({showVariables,setShowVariables,handleshowVariables }) => {

  return (
    <>
      <UndoRedo />
      <Separator />
      <CreateLink />
      <BoldItalicUnderlineToggles />
      <Separator />
      <ListsToggle />
      <Separator />

      {/* Variable insertion button */}
      <button  onClick={handleshowVariables} className={`text-[#2B2E3A] text-[16px] w-[20px] h-[20px] pb-7 font-medium ${showVariables ? 'bg-gray-200':''} `}>
        {'{ }'}
      </button>
    </>
  );
};

export default CustomToolbar;
