import React, { useState, useRef } from 'react';
import { toast } from "react-toastify";
import WhiteLoadingAnimation from "../../assets/WhiteLoadingAnimation.svg";
import axiosInstance from "../../services/axiosInstance";

const NewAddressForm = ({ thread_id, address, handleClosShowNewAddress, setAddressSucessModal, orderData, draft_id }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(false)

  const initialFormData = useRef({
    firstName: address?.firstName || orderData?.[0]?.shipping_address?.first_name || '',
    lastName: address?.lastName || orderData?.[0]?.shipping_address?.last_name || '',
    country: address?.country || orderData?.[0]?.shipping_address?.country || '',
    phoneNumber: address?.phone || orderData?.[0]?.shipping_address?.phone || '',
    company: address?.company || orderData?.[0]?.shipping_address?.company || '',
    address1: address?.address || orderData?.[0]?.shipping_address?.address1 || '',
    latitude: address?.latitude || orderData?.[0]?.shipping_address?.latitude || '',
    address2: address?.address2 || orderData?.[0]?.shipping_address?.address2 || '',
    longitude: address?.longitude || orderData?.[0]?.shipping_address?.longitude || '',
    zipCode: address?.zip || orderData?.[0]?.shipping_address?.zip || '',
    countryCode: address?.countryCode || orderData?.[0]?.shipping_address?.country_code || '',
    province: address?.province || orderData?.[0]?.shipping_address?.province || '',
    provinceCode: address?.provinceCode || orderData?.[0]?.shipping_address?.province_code || '',
    city: address?.city || orderData?.[0]?.shipping_address?.city || '',
  });
  const [formData, setFormData] = useState(initialFormData.current);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdateAddress = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    try {
      const payload = {
        thread_id: thread_id,
        draft_id: draft_id,
        order_id: orderData[0].id,
        shipping_address: formData,
      };

      await axiosInstance.put(`${API_URL}/v1/store/shopify/orders/${orderData[0].id}`, payload);
      toast.success("Address updated successfully.");
      setAddressSucessModal(true);

    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
    setIsLoading(false);
  };

  const getInputBgColor = (name) => {
    return formData[name] !== initialFormData.current[name] ? '#F1F9F7' : 'white';
  };

  return (
    <div className="w-full mx-auto px-6 mt-10 border-[#868CA24D] border rounded-lg">
      <div className="text-[#000000] text-lg py-3 font-semibold">New Address</div>
      <form onSubmit={handleUpdateAddress}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-[12px]">
          {/* First Name */}
          <div className="flex flex-col md:flex-row items-center mr-6">
            <label htmlFor="firstName" className="w-full md:w-[40%] text-[#868CA2] text-[13px] mb-1 md:mb-0">First Name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="flex-grow text-[#2B2E3A] text-[13px] text-right border border-[#868CA280] rounded-lg p-2 w-[40%] focus:outline-none"
              style={{ backgroundColor: getInputBgColor('firstName') }}
              required
            />
          </div>
          {/* Last Name */}
          <div className="flex flex-col md:flex-row items-center ml-6">
            <label htmlFor="lastName" className="w-full md:w-[40%] text-[#868CA2] text-[13px] mb-1 md:mb-0">Last Name</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="flex-grow text-right text-[#2B2E3A] text-[13px] border border-[#868CA280] rounded-lg p-2 w-[40%] focus:outline-none"
              style={{ backgroundColor: getInputBgColor('lastName') }}
              required
            />
          </div>

          {/* Country */}
          <div className="flex flex-col md:flex-row items-center mr-6">
            <label htmlFor="country" className="w-full md:w-[40%] text-[#868CA2] text-[13px] mb-1 md:mb-0">Country</label>
            <input
              type="text"
              id="country"
              name="country"
              value={formData.country}
              onChange={handleChange}
              className="flex-grow text-right text-[#2B2E3A] text-[13px] border border-[#868CA280] rounded-lg p-2 w-[40%] focus:outline-none"
              style={{ backgroundColor: getInputBgColor('country') }}
              required
            />
          </div>

          {/* Phone Number */}
          <div className="flex flex-col md:flex-row items-center ml-6">
            <label htmlFor="phoneNumber" className="w-full md:w-[40%] text-[#868CA2] text-[13px] mb-1 md:mb-0">Phone Number</label>
            <input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              className="flex-grow text-right text-[#2B2E3A] text-[13px] border border-[#868CA280] rounded-lg p-2 w-[40%] focus:outline-none"
              style={{ backgroundColor: getInputBgColor('phoneNumber') }}
              required
            />
          </div>

          {/* Company */}
          <div className="flex flex-col md:flex-row items-center mr-6">
            <label htmlFor="company" className="w-full md:w-[40%] text-[#868CA2] text-[13px] mb-1 md:mb-0">Company</label>
            <input
              type="text"
              id="company"
              name="company"
              value={formData.company}
              onChange={handleChange}
              className="flex-grow text-right text-[#2B2E3A] text-[13px] border border-[#868CA280] rounded-lg p-2 w-[40%] focus:outline-none"
              style={{ backgroundColor: getInputBgColor('company') }}
              required
            />
          </div>

          {/* Address 1 */}
          <div className="flex flex-col md:flex-row items-center ml-6">
            <label htmlFor='address1' className="w-full md:w-[40%] text-[#868CA2] text-[13px] mb-1 md:mb-0">Address 1</label>
            <input
              type="text"
              id="address1"
              name="address1"
              value={formData.address1}
              onChange={handleChange}
              className="flex-grow text-right text-[#2B2E3A] text-[13px] border border-[#868CA280] rounded-lg p-2 w-[40%] focus:outline-none"
              style={{ backgroundColor: getInputBgColor('address1') }}
              required
            />
          </div>

          {/* Latitude */}
          <div className="flex flex-col md:flex-row items-center mr-6">
            <label htmlFor='latitude' className="w-full md:w-[40%] text-[#868CA2] text-[13px] mb-1 md:mb-0">Latitude</label>
            <input
              type="text"
              id='latitude'
              name="latitude"
              value={formData.latitude}
              onChange={handleChange}
              className="flex-grow text-right text-[#2B2E3A] text-[13px] border border-[#868CA280] rounded-lg p-2 w-[40%] focus:outline-none"
              style={{ backgroundColor: getInputBgColor('latitude') }}
              required
            />
          </div>

          {/* Address 2 */}
          <div className="flex flex-col md:flex-row items-center ml-6">
            <label htmlFor='address2' className="w-full md:w-[40%] text-[#868CA2] text-[13px] mb-1 md:mb-0">Address 2</label>
            <input
              type="text"
              id="address2"
              name="address2"
              value={formData.address2}
              onChange={handleChange}
              className="flex-grow text-right text-[#2B2E3A] text-[13px] border border-[#868CA280] rounded-lg p-2 w-[40%] focus:outline-none"
              style={{ backgroundColor: getInputBgColor('address2') }}
              required
            />
          </div>

          {/* Longitude */}
          <div className="flex flex-col md:flex-row items-center mr-6">
            <label htmlFor='longitude' className="w-full md:w-[40%] text-[#868CA2] text-[13px] mb-1 md:mb-0">Longitude</label>
            <input
              type="text"
              id="longitude"
              name="longitude"
              value={formData.longitude}
              onChange={handleChange}
              className="flex-grow text-right text-[#2B2E3A] text-[13px] border border-[#868CA280] rounded-lg p-2 w-[40%] focus:outline-none"
              style={{ backgroundColor: getInputBgColor('longitude') }}
              required
            />
          </div>

          {/* ZIP Code */}
          <div className="flex flex-col md:flex-row items-center ml-6">
            <label htmlFor='zipCode' className="w-full md:w-[40%] text-[#868CA2] text-[13px] mb-1 md:mb-0">ZIP Code</label>
            <input
              type="text"
              id="zipCode"
              name="zipCode"
              value={formData.zipCode}
              onChange={handleChange}
              className="flex-grow text-right text-[#2B2E3A] text-[13px] border border-[#868CA280] rounded-lg p-2 w-[40%] focus:outline-none"
              style={{ backgroundColor: getInputBgColor('zipCode') }}
              required
            />
          </div>

          {/* Country Code */}
          <div className="flex flex-col md:flex-row items-center mr-6">
            <label htmlFor="countryCode" className="w-full md:w-[40%] text-[#868CA2] text-[13px] mb-1 md:mb-0">Country Code</label>
            <input
              type="text"
              id="countryCode"
              name="countryCode"
              value={formData.countryCode}
              onChange={handleChange}
              className="flex-grow text-right text-[#2B2E3A] text-[13px] border border-[#868CA280] rounded-lg p-2 w-[40%] focus:outline-none"
              style={{ backgroundColor: getInputBgColor('countryCode') }}
              required
            />
          </div>

          {/* Province */}
          <div className="flex flex-col md:flex-row items-center ml-6">
            <label htmlFor='province' className="w-full md:w-[40%] text-[#868CA2] text-[13px] mb-1 md:mb-0">Province</label>
            <input
              type="text"
              id ="province"
              name="province"
              value={formData.province}
              onChange={handleChange}
              className="flex-grow text-right text-[#2B2E3A] text-[13px] border border-[#868CA280] rounded-lg p-2 w-[40%] focus:outline-none"
              style={{ backgroundColor: getInputBgColor('province') }}
              required
            />
          </div>

          {/* Province Code */}
          <div className="flex flex-col md:flex-row items-center mr-6">
            <label htmlFor='provinceCode' className="w-full md:w-[40%] text-[#868CA2] text-[13px] mb-1 md:mb-0">Province Code</label>
            <input
              type="text"
              id="provinceCode"
              name="provinceCode"
              value={formData.provinceCode}
              onChange={handleChange}
              className="flex-grow text-right text-[#2B2E3A] text-[13px] border border-[#868CA280] rounded-lg p-2 w-[40%] focus:outline-none"
              style={{ backgroundColor: getInputBgColor('provinceCode') }}
              required
            />
          </div>
          <div className="flex flex-col md:flex-row items-center ml-6">
            <label htmlFor='city' className="w-full md:w-[40%] text-[#868CA2] text-[13px] mb-1 md:mb-0">City</label>
            <input
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
              className="flex-grow text-right text-[#2B2E3A] text-[13px] border border-[#868CA280] rounded-lg p-2 w-[40%] focus:outline-none"
              style={{ backgroundColor: getInputBgColor('city') }}
              required
            />
          </div>

        </div>

        {/* Buttons */}
        <div className="flex justify-end mt-6 py-2 space-x-4">
          <button
            type="button"
            className="px-2 w-[69px] h-[30px] text-[#2B2E3A] text-[13px] border rounded-lg border-[#D0D5DD]"
            onClick={handleClosShowNewAddress}
          >
            Cancel
          </button>

          <button
            type="submit"
            onClick={handleUpdateAddress}
            disabled={isLoading}
            className="w-auto px-2 min-w-[154px] h-[30px] text-[#2B2E3A] text-[13px] bg-[#96F3C3] rounded-lg flex items-center justify-center"
          >
            {isLoading ? (
              <img src={WhiteLoadingAnimation} alt="Loading..." className="h-10 w-14" />
            ) : (
              "Confirm New Address & Send Email"
            )}
          </button>

        </div>
      </form>
    </div>
  );
};

export default NewAddressForm;
