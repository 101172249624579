import React from 'react';

const EmailSkeleton = ({ show =false }) => {

  return (
    <div className=" p-4 w-full h-full mx-auto ">
      <div className="flex items-center mb-4">
        <div className="w-20 h-6 bg-gray-200 rounded-full"></div>
      </div>
      <div className="mb-2">
        <div className="h-4 bg-gray-200 rounded w-1/3 mb-2"></div>
        <div className="h-4 bg-gray-200 rounded w-2/3"></div>
      </div>
      <div className="mb-4">
        <div className="h-4 bg-gray-200 rounded w-1/2"></div>
      </div>
      <div className="mb-3">
        <div className="h-4 bg-gray-200 rounded w-full mb-1"></div>
        <div className="h-4 bg-gray-200 rounded w-5/6 mb-1"></div>
        <div className="h-4 bg-gray-200 rounded w-4/6"></div>
      </div>
     {show &&  <div className="flex justify-start">
        <div className="w-20 h-8 bg-gray-200 rounded-full"></div>
      </div>
     }
    </div>
  );
};

export default EmailSkeleton;
