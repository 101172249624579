import React from 'react';
import { Outlet } from 'react-router-dom';
import AdminSidebar from '../components/AdminSidebar';

const AdminLayout = () => {
  return (
    <div className="flex">
      <AdminSidebar />
      <div className="flex-3  w-full h-full">
        <Outlet />
      </div>
    </div>
  );
};

export default AdminLayout;