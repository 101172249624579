import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { isTokenExpired } from "../services/login";
import { TbAlertTriangle } from "react-icons/tb";
import { FaUserCircle } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { LuChevronDown } from "react-icons/lu";
import { AiOutlineClose } from "react-icons/ai";
import { isAuthenticated } from "../services/login";

const Navbar = () => {
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState(
    localStorage.getItem("profile")
  );
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [token, setToken] = useState(localStorage.getItem("token"));
  const API_URL = process.env.REACT_APP_API_URL;
  const isSigninPage = (window.location.pathname === "/signin" || window.location.pathname === "/invite");
  const [isDropDown, setIsDropDown] = useState(false);
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const location = useLocation();
  const dropdownRef = useRef(null);
  const [isClicked, setIsClicked] = useState(false);
  const [isNotification, setNotification] = useState(true);
  const [authStatus, setAuthStatus] = useState('checking');
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const isAuth = await isAuthenticated();
        setAuthStatus(isAuth ? "authenticated" : "unauthenticated");
      } catch (error) {
        console.error("Authentication error:", error);
        setAuthStatus("unauthenticated");
      }
    };

    checkAuth();
  }, []);

  useEffect(() => {
    setUserProfile(localStorage.getItem("profile"));
    setEmail(localStorage.getItem("email"))
  }, [location.pathname]);

  const handleLogout = () => {
    // Clear token from local storage
    localStorage.clear();
    setIsDropDown(false);
    setIsClicked(true);
    setTimeout(() => setIsClicked(false), 2000); 

    // Redirect to login route
    window.location.href = "/signin";
  };

  const handleOutsideClick = (e) => {
    // Check if the click is outside the dropdown
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropDown(false);
    }
  };

  useEffect(() => {
    if (isDropDown) {
      // Add event listener to detect clicks outside when dropdown is open
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      // Remove event listener when dropdown is closed
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      // Clean up event listener on component unmount
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isDropDown]);

  const firstname = localStorage.getItem("firstname") && localStorage.getItem("firstname") !== "null" ? localStorage.getItem("firstname") : "";
  const lastname = localStorage.getItem("lastname") && localStorage.getItem("lastname") !== "null" ? localStorage.getItem("lastname") : "";
  
  
  const fullname = `${firstname + " " + lastname}`
  const getPageTitle = (pathname) => {
    switch (true) {
      case pathname.includes("document"):
        return "Documents";
      case pathname.includes("accounts"):
        return "Accounts";
      case pathname.includes("integration"):
        return "Integrations";
      case pathname.includes("example"):
        return "Examples";
      case pathname.includes("draft"):
        return "Drafts";
      case pathname.includes("/admin/user-page"):
        return "User Management";
      case pathname.includes("/admin/store-page"):
        return "Store Management";
      case pathname.includes("/admin/dashboard-page"):
        return "Dashboard";
     
      // Add more cases as needed
     
    }
  };

  return (
    !(location.pathname.includes("/signin")) && window.location.pathname !== "/invite" && (
    <>
      <header
        className="sticky top-0 z-10  h-16 bg-white border-b border-black border-opacity-10 flex items-center px-4 lg:px-8 transition-all duration-300 font-dmsans"
      >
        <div className={`${isSidebarOpen ? "ml-[230px]" : "ml-[80px]"} flex justify-between w-full`}>
          {/* Left Side: Page Title */}
          <div className="flex items-center">
            <h1 className="text-xl font-medium text-[#000000] ">
              {getPageTitle(location.pathname)}
            </h1>
          </div>

          {/* Right Side: Profile and Notifications */}
          <div className="flex items-center space-x-6" onClick={() => setIsDropDown(!isDropDown)}>
            <div className="relative flex items-center cursor-pointer" ref={dropdownRef}>
              {userProfile ? (
                <img
                  src={userProfile}
                  alt="User Avatar"
                  className="w-8 h-8 rounded-full object-cover"
                />
              ) : (
                <FaUserCircle className="w-8 h-8 text-[#39F6A3] rounded-full" />
              )}

              {/* <span className="ml-2 text-gray-900 font-medium">{fullname}</span> */}
              <LuChevronDown className="ml-1 text-black" onClick={() => setIsDropDown(!isDropDown)} />

              {isDropDown && (
                <div className="absolute right-0 top-12 z-10 w-[250px] bg-[#FFFFFF] border border-[#F2F4F7] rounded-md shadow-lg p-2"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="flex items-center">
                    {userProfile ? (
                      <img
                        src={userProfile}
                        alt="User Avatar"
                        className="w-8 h-8 rounded-full object-cover"
                      />
                    ) : (
                      <FaUserCircle className="w-8 h-8 text-[#39F6A3] rounded-full" />
                    )}
                    <div className="ml-2 max-w-full overflow-hidden">
                      <span className="block text-[#344054] font-medium text-xs break-words">{fullname}</span>
                      <span className="block text-[#667085] font-medium text-xs break-all whitespace-normal">{email}</span>
                    </div>
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent dropdown from closing when clicking on the button itself
                      handleLogout(); // Add your logout logic here
                    }}
                    className={`${isClicked ? 'bg-[#39F6A3]' : 'hover:bg-[#D7FDED]'} mt-[8px] py-1 text-left font-dmsans px-2 w-full text-[#344054] text-[14px] rounded-md transition-colors duration-200 ease-in-out`}
                  >
                    Log out
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
        {/* Conditional Notification Div Below the Header */}
        {(location.pathname.includes("document") && isNotification && authStatus !== "checking") && (
          <div className={`${isSidebarOpen ? "ml-[230px]" : "ml-[80px]"} flex bg-[#96F3C3] text-[#868CA2] text-[12px] justify-between sticky top-0 h-8 items-center px-4 lg:px-8 mb-2 font-dmsans`}>
            <div className="flex items-center">
              {/* Triangle Icon */}
              <TbAlertTriangle className="mr-2 text-opacity-50 w-[12px] h-[12px]" />

              {/* Notification Message */}
              <span>
                In order to start the draft generation process, it is mandatory to add{' '}
                <>
                  a
                  <span
                    className="underline cursor-pointer text-[#000000] mx-1"
                    onClick={() => navigate("/document-page")}
                  >
                    Document (from here)
                  </span>
                </>
                <>
                  and a
                  <span
                    className="underline cursor-pointer text-[#000000] mx-1"
                    onClick={() => navigate("/integration-page")}
                  >
                    Shopify Store (from here)
                  </span>
                </>
              </span>
            </div>

            {/* Close Button */}
            <button className="text-[#2B2E3A]" onClick={() => setNotification(false)}>
              <AiOutlineClose size={12} />
            </button>
          </div>
        )}
      </>
    )
  );
};

export default Navbar;
