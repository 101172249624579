import React, { useEffect, useState } from "react";
import '@mdxeditor/editor/style.css';
import '../../components/ExamplePageComponents/CustomTheme.css';
import {
    MDXEditor,
    listsPlugin,
    linkDialogPlugin,
    linkPlugin,
    headingsPlugin
} from '@mdxeditor/editor';
import EditIcon from "../../assets/DraftsPageAssets/EditIcon.svg";
import { IoIosArrowRoundBack } from "react-icons/io";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import ExampleDetailSkeleton from "../../components/ExamplePageComponents/ExampleDetailSkeleton";
import axiosInstance from "../../services/axiosInstance";

const allPlugins = [
    listsPlugin(),
    headingsPlugin(),
    linkPlugin(),
    linkDialogPlugin(),
];

const ExampleDetailPage = () => {
    const [selectedOption, setSelectedOption] = useState('');
    const [isFetchingExampleDetails, setIsFetchingExampleDetails] = useState(false);
    const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
    const [headingMarkdown, setHeadingMarkdown] = useState("");
    const [contentMarkdown, setContentMarkdown] = useState("");
    const navigate = useNavigate();
    const API_URL = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem("token");
    const { id } = useParams();

    const fetchExampleDetails = async () => {
        setIsFetchingExampleDetails(true);
        try {
            const response = await axiosInstance.get(
                `${API_URL}/v1/store/draft/examples/${id}`);
            const data = response.data;
            setSelectedOption(data.type);
            setHeadingMarkdown(data.name);
            setContentMarkdown(data.template);
        } catch (error) {
            toast.error(`${error.response?.data?.detail}`);
        }
        setIsFetchingExampleDetails(false);
    };

    useEffect(() => {
        fetchExampleDetails();
    }, [id]);
    const getBgColor = (dataType) => {
        if (dataType.includes("Enquiry")) {
            return 'bg-[#1A13E326] text-[#1A13E3]';
        } else if (dataType.includes("Refund")) {
            return 'bg-[#23BE5426] text-[#23BE54]';
        } else if (dataType.includes("Order")) {
            return 'bg-[#FA343426] text-[#FA3434]';
        } else if (dataType.includes("Product")) {
            return 'bg-yellow-100 text-yellow-500';
        } else {
            return 'bg-gray-100 text-gray-500';
        }
    };

    return (
        <div className={`pt-3 overflow-y-auto flex font-dmsans flex-col ${isSidebarOpen ? 'ml-[230px] px-6' : 'ml-[60px] px-12'} transition-all ease-in-out duration-500 `}>
            <div className="flex items-center mb-4">
                <button
                    className="mr-2 pb-2  flex"
                    onClick={() => navigate(-1)}
                >
                    <IoIosArrowRoundBack className="w-7 h-7 text-[#2B2E3A]" />
                </button>
                <div className="flex">
                    <h2 className="text-[18px] font-dmsans fon-tmedium text-[#000000] mb-1">Example</h2>
                </div>
            </div>
            {isFetchingExampleDetails ? (
                <ExampleDetailSkeleton show={false}/>
            ) : (
                <>
                    <div className={`${isSidebarOpen ? 'px-6 ml-4' : ' px-12'}`}>
                        <div className="mb-4 flex w-[490px] h-[40px] relative">
                            <label htmlFor="actionName" className="mt-2 text-[16px] font-medium text-[#000000] w-[146px]">Action Name</label>
                            <div className="flex flex-col">
                                <div
                                    className=" w-[344px] p-2  text-[#000000] flex items-center"
                                >
                                    {selectedOption}
                                </div>

                            </div>
                        </div>

                        <div className="mb-4 flex w-full h-[40px]">
                            <label htmlFor="title" className="mt-2 text-[16px] font-medium text-[#000000] w-[146px]">Title</label>
                            <div className="flex flex-col max-w-[84%]  py-2 px-2">
                                <div>{headingMarkdown}</div>

                            </div>
                        </div>

                        <div className="flex mb-3 w-full relative">
                            <label htmlFor="message" className="mt-2 text-[16px] font-medium text-[#000000] w-[146px]">Message</label>
                            <div className={` rounded-md w-[90%] relative font-dmsans`}>
                                <div data-testid="message-editor" className="flex flex-col gap-2 ">
                                    <MDXEditor
                                        markdown={contentMarkdown}
                                        plugins={allPlugins}
                                        readOnly={true}
                                        className="full-demo-mdxeditor mdx-editor whitespace-pre-wrap  break-words"
                                        contentEditableClassName="prose max-w-full font-dmsans whitespace-pre-wrap  break-words"
                                    />
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className="flex justify-end pb-6 gap-2 ">
                        <button
                            type="button"
                            onClick={() => navigate(`/create-example/${id}`)}
                            className="px-4 gap-1 text-[#2B2E3A] font-dmsans rounded-md shadow-sm bg-[#88FAC8] w-[90px] h-[34px] text-center flex items-center justify-center"
                        >

                            Edit
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default ExampleDetailPage;
