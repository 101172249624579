import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";
import { formatDate } from "../../services/logics";
import { toast } from "react-toastify";
import axiosInstance from "../../services/axiosInstance";
import LoadingAnimation from "../../assets/LoadingAnimation.svg";
import { TbCircleCheck } from "react-icons/tb";
import { LuStore } from "react-icons/lu";
import { useSelector } from "react-redux";
import { FaUserCircle } from "react-icons/fa";
const AdminStorePageDetails = () => {
  const navigate = useNavigate();
  const { id: storeId } = useParams();
  const [showInviteModal, setShowInviteModal] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  const [storeData, setStoreData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isFetchingUser, setIsFetchinguser] = useState(true);
  const [sendingInvite, setSendingInvite] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);
  const [inputEmail, setInputEmail] = useState("");
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const [isSuspending, setIsSuspending] = useState(false);
  const [isReactivating, setIsReactivating] = useState(false);
  const [userData, setUserData] = useState([]);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isImageError, setIsImageError] = useState(false);

  useEffect(() => {
    const fetchStoreData = async () => {
      try {
        const res = await axiosInstance.get(`${API_URL}/v1/admin/stores/${storeId}`, {
        });
        const data = res.data;
        setStoreData(data);
      } catch (e) {
        toast.error("Error occured while fetching data");
      }
      setIsFetching(false);
    };
    fetchStoreData();
  }, []);

  const handleCloseInvite = () => {
    setShowInviteModal(false);
  };

  const handleSendInvite = async () => {
    setSendingInvite(true);
    const email = inputEmail;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email && !emailRegex.test(email)) {
      toast.error("Enter a valid email address");
      setSendingInvite(false);
      return;
    }
    const details = {
      email: email,
      store_id: storeId,
    };
    try {
      const res = await axiosInstance.post(`${API_URL}/v1/invites/send`, details, {
      });

      const data = res.data;
      handleCloseInvite();
      setInviteSent(true);
    } catch (error) {
      setInputEmail("");
      toast.error(
        `${error.response ? error.response.details : "Error while sending email"
        }`
      );
    }

    setSendingInvite(false);
  };

  const closeSentInviteModal = () => {
    setInviteSent(false);
    setInputEmail("");
  };

  const deleteStoreHandler = async () => {
    try {
      const res = await axiosInstance.put(
        `${API_URL}/v1/admin/stores/${storeId}`,
        {
          is_active: false,
        },
      );
      const data = res.data;
      setStoreData((prev) => ({ ...prev, is_active: data.is_active }));
      toast.success(`${data.name} suspended`);
    } catch (error) {
      toast.error(`${error.response?.data?.detail || "Error while suspending Store"}`);
    }
  };

  const handleReactivateStore = async () => {
    try {
      const res = await axiosInstance.put(
        `${API_URL}/v1/admin/stores/${storeId}`,
        {
          is_active: true,
        },
      );
      const data = res.data;
      setStoreData((prev) => ({ ...prev, is_active: res.data.is_active }));
      toast.success(`${data.name} reactivated`);
    } catch (error) {
      toast.error(`${error.response?.data?.detail || "Error reactivating user"}`);
    }
  };
  const getInitials = (name) => {
    const nameParts = name?.split(' ');
    if (nameParts?.length > 1) {
      return nameParts[0][0] + nameParts[1][0];
    }
    return nameParts[0][0];
  };

  const handleReactivateUser = async (userId) => {
    setIsReactivating(true)
    try {
      const res = await axiosInstance.put(
        `${API_URL}/v1/admin/users/${userId}`,
        {
          is_active: true,
        },
      );
      const data = res.data;
      setStoreData((prev) => ({
        ...prev,
        active_user: {
          ...prev.active_user, // spread the existing fields of active_user
          is_active: true, // update only the is_active field
        }
      }));
      toast.success("user reactivated");
    } catch (error) {
      toast.error("Error reactivating user");
    }
    setIsReactivating(false)
  };
  const handleSuspendUser = async (userId) => {
    setIsSuspending(true)
    try {
      const res = await axiosInstance.put(
        `${API_URL}/v1/admin/users/${userId}`,
        {
          is_active: false,
        },
      );
      const data = res.data;
      setStoreData((prev) => ({
        ...prev,
        active_user: {
          ...prev.active_user, // spread the existing fields of active_user
          is_active: false, // update only the is_active field
        }
      }));
      toast.success("user suspended");
    } catch (error) {
      toast.error("Error deleting user");
    }
    setIsSuspending(false)
  };
  useEffect(() => {
  const fetchUserData = async () => {
    try {
      const res = await axiosInstance.get(
        `${API_URL}/v1/admin/drafts/store-metrices?store_id=${storeId}`
      );
      const data = res.data;
      setUserData(data);
    } catch (e) {
      toast.error("Error occured while fetching data");
    }
    setIsFetchinguser(false);
  };
  fetchUserData();
}, []);


  return (
    <div className={`py-2 mb-8 bg-[#FFFFFF] flex flex-col font-dmsans ${isSidebarOpen ? 'ml-[230px] px-6' : 'ml-[80px] px-8'} transition-all ease-in-out duration-500 overflow-hidden`}>
      <div className="ml-auto mr-auto flex flex-col h-full w-full">
        <button className={`flex gap-[6px] `} onClick={() => navigate(-1)}>
          <IoIosArrowRoundBack className="w-6 h-6" />
          <span className="text-[#1B1A22] font-dmsans font-medium text-sm mt-[2px]">Back</span>
        </button>

        <div className="relative flex justify-between w-full  pl-10">
          {/* Left side: Store Details */}
          <div className="flex flex-col w-1/2 border-r border-[#868CA280]  px-6">
            {/* Store Details Section */}
            <div className="mb-4">
              <LuStore className="text-[#39F6A3] w-[86px] h-[86px]" />
              <h4 className="text-[16px] font-medium font-dmsans my-3 pb-3">Store Details</h4>
              <div className="grid gap-3">
                <div className="flex w-full gap-[8px] pb-2 border-b border-[#868CA233]">
                  <span className="font-dmsans w-1/2 text-[#868CA2] text-[16px] font-medium">Store ID</span>
                  {isFetching ? (
                    <div className="bg-gray-300 h-4  w-40 rounded-lg animate-pulse"></div>
                  ) : (
                    <span className="font-medium w-1/2 text-[16px] text-[#2B2E3A] break-words">{storeData?.store_id ? `#${storeData.store_id}` : ''}</span>)}
                </div>
                <div className="flex w-full gap-[8px] pb-2 border-b border-[#868CA233]">
                  <span className="font-dmsans w-1/2 text-[#868CA2] text-[16px] font-medium">Store Name</span>
                  {isFetching ? (
                    <div className="bg-gray-300 h-4  w-40 rounded-lg animate-pulse"></div>
                  ) : (
                    <span className="font-medium w-1/2 text-[16px] text-[#2B2E3A] break-words">{storeData?.name}</span>)}
                </div>
                <div className="flex w-full gap-[8px] pb-2  border-b border-[#868CA233]">
                  <span className="font-dmsans w-1/2 text-[#868CA2] text-[16px] font-medium">Owner Name</span>
                  {isFetching ? (
                    <div className="bg-gray-300 h-4  w-40 rounded-lg animate-pulse"></div>
                  ) : (
                    <span className="font-medium w-1/2 text-[16px] text-[#2B2E3A] break-words">{storeData?.owner_name}</span>)}
                </div>
                <div className="flex w-full gap-[8px] pb-2 border-b border-[#868CA233]">
                  <span className="font-dmsans w-1/2 text-[#868CA2] text-[16px] font-medium">Signed in on</span>
                  {isFetching ? (
                    <div className="bg-gray-300 h-4  w-40 rounded-lg animate-pulse"></div>
                  ) : (
                    <span className="font-medium w-1/2 text-[16px] text-[#2B2E3A] break-words"> {formatDate(storeData?.created_at)}</span>)}
                </div>
              </div>
            </div>

            {/* Invite Users Section */}
            <div className="flex flex-col gap-[10px]">
              <h5 className="font-medium text-sm ">Invite Users</h5>
              <div className="flex items-center border border-[#FCFEFF] rounded-md  bg-[#F5F7F8] h-[40px]">
                <input type="email" placeholder="Enter email" className="pl-4  bg-[#F5F7F8] w-[75%] h-full  text-[#000000] text-[15px] focus:outline-none" onChange={(e) => (setInputEmail(e.target.value), setInviteSent(false))} />
                {inviteSent ? <div className="flex items-center cursor-default w-[25%] bg-[#88FAC8] rounded-md justify-center mr-1">
                  <TbCircleCheck className="text-[#2B2E3A] w-4 h-4" />
                  <button
                    className="m-1 h-[70%] text-[#2B2E3A] text-[12px]  font-semibold "
                    disabled={sendingInvite}
                  >
                    Invite Sent
                  </button>
                </div>
                  :
                  <button
                    className="m-1 h-[70%] text-[#5D5C6A] w-[25%] text-[12px] font-semibold bg-white flex justify-center items-center"
                    onClick={handleSendInvite}
                    disabled={sendingInvite}
                  >
                    {sendingInvite ? (
                      <img src={LoadingAnimation} alt="Loading..." className="h-10 w-10" />
                    ) : (
                      "Send Invite"
                    )}
                  </button>
                }
              </div>
            </div>

            {/* Deactivate Button */}
            {storeData?.is_active ? (
              <button
                onClick={deleteStoreHandler}
                className="mt-auto px-4 py-2 border border-[#D0D5DD] text-[#2B2E3A] text-sm rounded-md hover:bg-[#F5F7F8]"                  >
                Deactivate Store
              </button>
            ) : (
              <button
                onClick={handleReactivateStore}
                className="mt-auto px-4 py-2 border border-[#D0D5DD] text-[#2B2E3A] text-sm rounded-md hover:bg-[#F5F7F8]"                  >
                Activate Store
              </button>
            )}
          </div>

          {/* Right side: User Details */}
          <div className="flex flex-col w-1/2  rounded-lg px-4 ">
            {/* User Details Section */}
            <div className="mb-4">
              {isFetchingUser ? <div className="rounded-full w-[86px] h-[86px] object-cover  bg-gray-300 animate-pulse"></div> :
                !isImageError ? (
                  <>
                    {/* Show image only if it loads successfully */}
                    <img
                  src={storeData?.active_user?.profile_image || ''}
                  alt="Profile"
                      className={`rounded-full text-[#39F6A3] w-[86px] h-[86px] object-cover shadow-md ${isImageLoaded ? '' : 'hidden'}`}
                      onLoad={() => setIsImageLoaded(true)} // Set loaded state to true when image loads
                      onError={() => setIsImageError(true)} // Set error state if image fails to load
                    />
                    {/* Fallback icon shown if image is still loading */}
                    {!isImageLoaded && (
                      <FaUserCircle className="rounded-full text-[#39F6A3] w-[86px] h-[86px] object-cover shadow-md bg-white" />
                    )}
                  </>
                ) : (
                  // Show the fallback icon if the image fails to load
                  <FaUserCircle className="rounded-full text-[#39F6A3] w-[86px] h-[86px] object-cover shadow-md bg-white" />
                )}

              <h4 className="text-[16px] font-medium font-dmsans my-3 pb-3">User Details</h4>
              <div className="grid gap-3">
                <div className="flex w-full gap-[8px] pb-2 border-b border-[#868CA233]">
                  <span className="font-dmsans w-1/2 text-[#868CA2] text-[16px] font-medium">Name</span>
                  {isFetchingUser ? (
                    <div className="bg-gray-300 h-4  w-40 rounded-lg animate-pulse"></div>
                  ) : (
                  <span className="font-medium w-1/2 text-[16px] text-[#2B2E3A] break-words">{storeData?.active_user?.first_name || storeData?.active_user?.last_name
                    ? `${storeData.active_user.first_name || ''} ${storeData.active_user.last_name || ''}`
                    : ''}</span>)}
                </div>
                <div className="flex w-full gap-[8px] pb-2 border-b border-[#868CA233]">
                  <span className="font-dmsans w-1/2 text-[#868CA2] text-[16px] font-medium">Email</span>
                  {isFetchingUser ? (
                    <div className="bg-gray-300 h-4  w-40 rounded-lg animate-pulse"></div>
                  ) : (
                  <span className="font-medium w-1/2 text-[16px] text-[#2B2E3A] break-words">{storeData?.active_user?.email}</span>)}
                </div>
                <div className="flex w-full gap-[8px] pb-2  border-b border-[#868CA233]">
                  <span className="font-dmsans w-1/2 text-[#868CA2] text-[16px] font-medium">Status</span>
                  {isFetchingUser ? (
                    <div className="bg-gray-300 h-4  w-40 rounded-lg animate-pulse"></div>
                  ) : (
                  <span className="font-medium w-1/2 text-[16px] text-[#2B2E3A] break-words">
                    <span
                      className={`inline-block px-3 py-1 text-sm rounded-full text-start ${storeData?.active_user?.is_active
                        ? 'bg-[#57F0010D] text-[#59D912]'
                        : 'bg-[#4141410D] text-[#414141]'
                        }`}
                      style={{ minWidth: '80px', textAlign: 'center' }} // Ensure a consistent width for all badges
                    >
                      {storeData?.active_user?.is_active ? "Active" : "In-active"}
                    </span>
                  </span>)}
                </div>
                <div className="flex w-full gap-[8px] pb-2 border-b border-[#868CA233]">
                  <span className="font-dmsans w-1/2 text-[#868CA2] text-[16px] font-medium">Signed in on</span>
                  {isFetchingUser ? (
                    <div className="bg-gray-300 h-4  w-40 rounded-lg animate-pulse"></div>
                  ) : (
                  <span className="font-medium w-1/2 text-[16px] text-[#2B2E3A] break-words"> {storeData?.active_user?.created_at ? formatDate(new Date(storeData?.active_user?.created_at)) : ''}</span>)}
                </div>
              </div>
            </div>

            {/* Draft Statistics Section */}
            <div className="flex flex-col gap-[20px] mb-16">
            {isFetchingUser ? <div className="bg-gray-300 w-[238px]  h-[86px] rounded-lg animate-pulse"></div>:
              <div className="flex flex-row justify-between items-center w-[238px] bg-[#F5F7F8] h-[86px] p-4">
                <span className="font-semibold text-[#000000] font-dmsans text-[15px]">Draft Created</span>
                <span className="font-medium text-2xl">{userData?.created_drafts_count}</span>
              </div>}
              {isFetchingUser  ? <div className="bg-gray-300 w-[238px]  h-[86px] rounded-lg animate-pulse"></div>:

              <div className="flex flex-row justify-between items-center w-[238px] bg-[#F5F7F8] h-[86px] p-4">
                <span className="font-semibold text-[#000000] font-dmsans text-[15px]">Draft Sent</span>
                <span className="font-medium text-2xl">{userData?.sent_drafts_count}</span>
              </div>}
              {isFetchingUser ? <div className="bg-gray-300 w-[238px]  h-[86px] rounded-lg animate-pulse"></div>:

              <div className="flex flex-row justify-between items-center w-[238px] bg-[#F5F7F8] h-[86px] p-4">
                <span className="font-semibold text-[#000000] font-dmsans text-[15px]">Draft Deleted</span>
                <span className="font-medium text-2xl">{userData?.deleted_drafts_count}</span>
              </div>}
            </div>

            {/* Suspend Button */}
            {storeData?.active_user?.is_active ? (
              <button
                onClick={() => handleSuspendUser(storeData?.active_user?.id)}
                disabled={isSuspending}
                className="mt-auto px-4 py-2 border border-[#D0D5DD] text-[#2B2E3A] text-sm rounded-md hover:bg-[#F5F7F8]"                  >
                Deactivate User
              </button>
            ) : (
              <button
                onClick={() => handleReactivateUser(storeData?.active_user?.id)}
                disabled={isReactivating}
                className="mt-auto px-4 py-2 border border-[#D0D5DD] text-[#2B2E3A] text-sm rounded-md hover:bg-[#F5F7F8]"                  >
                Activate User
              </button>
            )}          </div>
        </div>
      </div>
    </div>


  );
};

export default AdminStorePageDetails;
