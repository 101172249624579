import React, { useState } from "react";
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";

const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-[278px] py-2 rounded-lg ml-3 transition duration-300 ease-in-out">
      <div
        className="flex justify-between cursor-pointer items-center pl-1 bg-[#E7E8EC33] h-[27px] "
        onClick={toggleAccordion}
      >
        <span className="text-xs font-medium leading-7 text-gray-800">
          {title}
        </span>
        <span className="text-[#2B2E3A] mr-2">
          {isOpen ? <MdOutlineKeyboardArrowUp size={16} /> : <MdOutlineKeyboardArrowDown size={16} />}
        </span>
      </div>
      <div
        className={`transition-max-height duration-300 ease-in-out overflow-hidden ${
          isOpen ? "max-h-screen" : "max-h-0"
        }`}
      >
        {isOpen && <div className="mt-2  w-[274px] ">{children}</div>}
      </div>
    </div>
  );
};

export default Accordion;
