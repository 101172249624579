import React from 'react';

const PaperPlaneIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <circle
        cx="9.5"
        cy="9.5"
        r="9.2"
        fill="#F3F3F3"
        stroke="black"
        strokeWidth="0.6"
      />
      <g transform="translate(4.5, 4.5) scale(1.0)">
        <g id="fi-rr-paper-plane" clipPath="url(#clip0_2880_3797)">
          <path
            id="Vector"
            d="M9.63278 0.367251C9.48339 0.216055 9.29754 0.105941 9.09317 0.04755C8.8888 -0.0108412 8.67282 -0.0155363 8.46611 0.0339181L1.79945 1.43808C1.41552 1.49074 1.05392 1.64953 0.755363 1.89659C0.456808 2.14365 0.233159 2.46915 0.109601 2.83645C-0.0139562 3.20374 -0.0325143 3.59824 0.0560169 3.97551C0.144548 4.35278 0.336652 4.69784 0.610695 4.97183L1.32653 5.68725C1.36527 5.72598 1.396 5.77198 1.41695 5.82259C1.43789 5.87321 1.44865 5.92747 1.44861 5.98225V7.30225C1.44953 7.48785 1.49226 7.67085 1.57361 7.83767L1.57028 7.84058L1.58111 7.85142C1.7032 8.09689 1.9026 8.29541 2.14861 8.41642L2.15945 8.42725L2.16236 8.42392C2.32918 8.50527 2.51218 8.548 2.69778 8.54892H4.01778C4.12822 8.54883 4.23417 8.59259 4.31236 8.67058L5.02778 9.386C5.21966 9.58001 5.44805 9.73413 5.69977 9.83946C5.95149 9.9448 6.22157 9.99928 6.49445 9.99975C6.72184 9.99947 6.94769 9.96232 7.16319 9.88975C7.52713 9.77025 7.85043 9.55142 8.09663 9.25796C8.34282 8.9645 8.50211 8.60809 8.55653 8.22892L9.96278 1.54767C10.0148 1.33919 10.0117 1.12076 9.95388 0.913819C9.89603 0.706879 9.78538 0.518531 9.63278 0.367251ZM1.91653 5.09892L1.20028 4.3835C1.03349 4.22074 0.9166 4.01377 0.863314 3.7869C0.810028 3.56003 0.822562 3.32267 0.899445 3.10267C0.973985 2.87697 1.11178 2.67743 1.29644 2.52778C1.48111 2.37813 1.70487 2.28466 1.94111 2.2585L8.54153 0.868918L2.28111 7.13017V5.98225C2.28174 5.81822 2.24985 5.6557 2.18727 5.50408C2.12469 5.35246 2.03267 5.21475 1.91653 5.09892ZM7.73778 8.08642C7.70579 8.31656 7.61028 8.53323 7.46199 8.7121C7.31369 8.89098 7.11847 9.02498 6.89825 9.09906C6.67802 9.17315 6.44151 9.18439 6.21525 9.13152C5.98899 9.07865 5.78195 8.96376 5.61736 8.79975L4.9007 8.08308C4.78502 7.96677 4.64742 7.87455 4.49587 7.81175C4.34432 7.74896 4.18182 7.71684 4.01778 7.71725H2.86986L9.13111 1.45808L7.73778 8.08642Z"
            fill="#2B2E3A"
          />
        </g>
        <defs>
          <clipPath id="clip0_2880_3797">
            <rect width="10" height="10" fill="white" />
          </clipPath>
        </defs>
      </g>
    </svg>
  );
};

export default PaperPlaneIcon;
