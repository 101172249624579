import React, { useEffect } from 'react';
import { CgFileDocument } from 'react-icons/cg';
import { MdOutlineArticle } from "react-icons/md";
import { MdIntegrationInstructions } from 'react-icons/md';
import { RiAccountCircleFill } from "react-icons/ri";
import { LuFolderEdit } from 'react-icons/lu';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar, openSidebar } from '../redux/sidebarSlice';
import MailLogo from "../assets/SidebarAssets/MailLogo.svg";
import { LuChevronRightCircle, LuChevronLeftCircle } from "react-icons/lu";

const Sidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const isActive = (path) => location.pathname === path;

  useEffect(() => {
    dispatch(openSidebar());
  }, [dispatch]);

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  const textStyles = "text-base font-semibold";
  return (
    <div className={`flex-shrink-0 top-0 z-10 fixed h-screen ${isSidebarOpen ? 'w-[236px]' : 'w-[80px]'} bg-[#F3F3F3] text-white transition-all ease-in-out duration-300 flex flex-col justify-between`}
      style={{
        background: 'linear-gradient(176.62deg, #0B0C16 39.26%, #184139 92.26%)',
        border: '1.15px solid',
        borderImageSource: 'linear-gradient(180deg, #0E151C 0%, #21905F 100%)',
      }}
    >
      {/* Navigation and Menu */}
      <div>
        <div className=" pt-4 mb-[60px] pl-[22px]">
          <div
            className={`flex items-center py-[10px] space-x-2  mr-2 `}
          >
            <img src={MailLogo} alt="" />
            <span className={`${isSidebarOpen ? 'block' : 'hidden'} text-base font-semibold`}>Custo.in</span>
          </div>        </div>

        <ul className="space-y-2">
          <li className={`${isActive('/document-page') ? 'border-r-2 border-primary-700 rounded-sm' : ''}`}>
            <Link
              to="/document-page"
              className={`flex items-center px-3 py-[10px] space-x-2 ml-2 mr-2 ${isActive('/document-page') ? 'bg-[#39F6A333] bg-opacity-10 rounded-full text-[#39F6A3] relative' : 'text-[#868CA2] rounded-full hover:bg-gray-600'}`}
            >
              <MdOutlineArticle size={20} />
              <span className={`${isSidebarOpen ? 'block' : 'hidden'} text-base font-semibold`}>Documents</span>
            </Link>
          </li>
          <li className={`${isActive('/draft-page') || location.pathname.startsWith('/draft-page/detail') ? 'border-r-2 border-primary-700 rounded-sm' : ''}`}>
            <Link
              to="/draft-page"
              className={`flex items-center px-3 py-[10px] space-x-2 ml-2 mr-2 ${isActive('/draft-page') || location.pathname.startsWith('/draft-page/detail') ? 'bg-[#39F6A333] bg-opacity-10 rounded-full text-[#39F6A3] relative' : 'text-[#868CA2] rounded-full hover:bg-gray-600'}`}
            >
              <LuFolderEdit size={20} />
              <span className={`${isSidebarOpen ? 'block' : 'hidden'} text-base font-semibold`}>Drafts</span>
            </Link>
          </li>
          <li className={`${isActive('/integration-page') ? 'border-r-2 border-primary-700 rounded-sm' : ''}`}>
            <Link
              to="/integration-page"
              className={`flex items-center px-3 py-[10px] space-x-2 ml-2 mr-2 ${isActive('/integration-page') ? 'bg-[#39F6A333] bg-opacity-10 rounded-full text-[#39F6A3] relative' : 'text-[#868CA2] rounded-full hover:bg-gray-600'}`}
            >
              <MdIntegrationInstructions size={20} />
              <span className={`${isSidebarOpen ? 'block' : 'hidden'} text-base font-semibold`}>Integrations</span>
            </Link>
          </li>
          <li className={`${isActive('/examples-page') || location.pathname.startsWith('/example') || location.pathname.startsWith('/create-example') ? 'border-r-2 border-primary-700 rounded-sm' : ''}`}>
            <Link
              to="/examples-page"
              className={`flex items-center px-3 py-[10px] space-x-2 ml-2 mr-2 ${isActive('/examples-page') || location.pathname.startsWith('/example') || location.pathname.startsWith('/create-example') ? 'bg-[#39F6A333] bg-opacity-10 rounded-full text-[#39F6A3] relative' : 'text-[#868CA2] rounded-full hover:bg-gray-600'}`}
            >
              <CgFileDocument size={20} />
              <span className={`${isSidebarOpen ? 'block' : 'hidden'} text-base font-semibold`}>Examples</span>
            </Link>
          </li>

          <li className={`${isActive('/accounts-page') ? 'border-r-2 border-primary-700 rounded-sm' : ''}`}>
            <Link
              to="/accounts-page"
              className={`flex items-center px-3 py-[10px] space-x-2 ml-2 mr-2 ${isActive('/accounts-page') ? 'bg-[#39F6A333]  bg-opacity-10 rounded-full text-[#39F6A3] relative' : 'text-[#868CA2] rounded-full hover:bg-gray-600'}`}
            >
              <RiAccountCircleFill size={20} />
              <span className={`${isSidebarOpen ? 'block' : 'hidden'} text-base font-semibold`}>Accounts</span>
            </Link>
          </li>
        </ul>
      </div>

      <div className="flex flex-grow items-end">
        <div className={`absolute bottom-4 ${isSidebarOpen ? 'right-5' : 'right-1/2 transform translate-x-1/2'}`}>
          {isSidebarOpen ? (
            <LuChevronLeftCircle
              size={38}
              className="cursor-pointer text-[#D7FDED] hover:text-gray-400"
              style={{ strokeWidth: 0.6 }} // Adjust stroke width here
              onClick={handleToggleSidebar}
            />
          ) : (
            <LuChevronRightCircle
              size={38}
              className="cursor-pointer text-[#D7FDED] hover:text-gray-400"
              style={{ strokeWidth: 0.6 }} // Adjust stroke width here
              onClick={handleToggleSidebar}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
