import React, { useState } from 'react';
import { FaFileAlt } from "react-icons/fa";
import DownloadIcon from "../../assets/documentPageAssets/DownloadIcon.svg";
import axiosInstance from "../../services/axiosInstance";
import { toast } from "react-toastify";
import { AiOutlineClose } from "react-icons/ai";
const AttachmentItem = ({ attachment, message_id }) => {
  const [isViewing, setIsViewing] = useState(false);
  const [attachmentUrl, setAttachmentUrl] = useState(null);
  const { filename = '', mimeType } = attachment || {};
  const attachment_id = attachment.attachmentId;
  const API_URL = process.env.REACT_APP_API_URL;

  const fetchAttachmentUrl = async () => {
    try {
      const res = await axiosInstance.get(
        `${API_URL}/v1/store/gmail/${message_id}/attachment/${attachment_id}`,
        {
          
          responseType: 'arraybuffer', // Handle binary data
        }
      );
      const blob = new Blob([res.data], { type: mimeType });
      const url = URL.createObjectURL(blob);
      setAttachmentUrl(url);
      return url;
    } catch (error) {
      toast.error(`${error.response?.data?.detail || 'Failed to fetch attachment'}`);
      return null;
    }
  };

  const toggleViewAttachment = async () => {
    if (!isViewing && !attachmentUrl) {
      await fetchAttachmentUrl();
    }
    setIsViewing(!isViewing);
  };

  const downloadAttachment = async (event) => {
    // Prevent click from propagating to the parent div
    event.stopPropagation();

    const url = attachmentUrl || await fetchAttachmentUrl();
    if (url) {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const renderContent = () => {
    if (!attachmentUrl) return null;

    if (mimeType.startsWith('image/')) {
      return <img src={attachmentUrl} alt={filename} className="max-w-full max-h-full" />;
    } else if (mimeType === 'application/pdf') {
      return <iframe src={attachmentUrl} title={filename} className="w-full h-96" />;
    } else {
      return (
        <div>
          <p>Preview not available for this file type. You can download it using the button below.</p>
          <button
            onClick={downloadAttachment}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Download {filename}
          </button>
        </div>
      );
    }
  };

  return (
    <div className="min-h-[46px] max-w-[200px] font-dmsans h-auto relative m-2">
      <div className="p-2 border bg-[#F3F3F3] w-auto flex items-center py-2 shadow-sm cursor-pointer relative" onClick={toggleViewAttachment}>
        <div className="flex items-center mt-1 w-auto">
          <FaFileAlt className="text-xl text-[#868CA2]" />
          <div className="mx-2 pr-2 flex-1 py-1 ">
            <p className="font-medium text-[12px] text-[#2B2E3A] break-words max-w-[160px]">{filename}</p>
          </div>
        </div>
        <button
          onClick={downloadAttachment}
          className="absolute top-1 right-1 w-[20px] h-[14px] flex justify-center items-start hover:bg-[#E8FFF5] rounded-full"
          data-testid='download'
        >
          <img
            src={DownloadIcon}
            alt="Download"
            className="w-[12px] h-[12px]"
          />
        </button>
      </div>

      {isViewing && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded-lg max-w-full max-h-full shadow-lg relative">
            <button
              onClick={toggleViewAttachment}
              className="absolute top-2 right-2 text-gray-700"
              data-testid="close"
            >
              <AiOutlineClose size={18} />
            </button>
            <div className="max-w-full max-h-full flex justify-center items-center">
              {renderContent()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AttachmentItem;
