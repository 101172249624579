import React from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../services/login';

const ProtectedAdminRoutes = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const isSuperAdmin = localStorage.getItem("is_super_admin");

  isAuthenticated();
  
  if (!token) {
    return <Navigate to="/signin" replace />;
  }

  if(isSuperAdmin === "True"){
   
    return <Outlet />
  }
  return <Navigate  to = '/error' replace/>
}

export default ProtectedAdminRoutes