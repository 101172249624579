import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

const AccountPage = () => {
  const [user, setUser] = useState({
    email: "",
    fullname: "",
    profileImage: "",
    phoneNumber: "",
  });
  const token = localStorage.getItem("token");
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [storeData, setStoreData] = useState({
    ownerName: "",
    storeName: "",
    storeURL: "",
  });
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);

  const [isEditingUser, setIsEditingUser] = useState(false);
  const [isEditingStore, setIsEditingStore] = useState(false);
  useEffect(() => {
    const fetchUserData = async () => {
      const firstname = localStorage.getItem("firstname")
        ? localStorage.getItem("firstname")
        : "";
      const lastname = localStorage.getItem("lastname")
        ? localStorage.getItem("lastname")
        : "";

      setUser((prev) => ({
        ...prev,
        fullname: `${firstname + " " + lastname}`,
        email: localStorage.getItem("email"),
        profileImage: localStorage.getItem("profile"),
      }));

      setStoreData((prev) => ({
        ...prev,
        ownerName: localStorage.getItem("firstname"),
        storeName: localStorage.getItem("storename"),
      }));
    };

    fetchUserData();
  }, []);

  // const updateStoreDetails = async (updatedName) => {
  //   try {
  //     const details = {
  //       name: updatedName,
  //     };
  //     const res = await axios.put(
  //       `${API_URL}/v1/stores/${storeData.id}`,
  //       details,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     console.log(res.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const onChangeDetails = ({ name, value }) => {
  //   updateStoreDetails(value);
  //   setStoreData((prevData) => ({ ...prevData, [name]: value }));
  // };
  // const handleInputChange = (e, name) => {
  //   const value = e.target.innerText; // Get the updated value from the contentEditable div
  //   onChangeDetails({ name, value });
  // };

  return (
    <div className="bg-[#FFFFFF] font-dmsans">
      <div className={`transition-all duration-300 ${isSidebarOpen ? "ml-[230px]" : "ml-[100px] mr-[60px]"} flex justify-center`}>
        <div className="max-w-4xl w-full px-6 py-8">
          <div className="flex flex-col items-center space-y-4">
            {/* Profile Image */}
            <div className="w-full flex ">
              <img
                src={user.profileImage}
                alt="User Avatar"
                className="w-[120px] h-[120px] rounded-full object-cover"
              />
            </div>

            {/* User Settings & Signature */}
            <div className="w-full">
              {/* User Settings */}
              <div className="py-6 mb-6 relative">
              <div className="flex justify-between items-center bg-[#F3F3F3] py-1">
                <div className="text-lg text-[#1B1A22] font-medium pl-2 ">User Account</div>
               
              </div>

              {/* Profile Info */}
              <div className="grid grid-cols-1 gap-3 mt-3 relative pl-2">
                <div className="flex items-center justify-between">
                  <span className="text-sm font-medium text-[#868CA2] w-[30%]">Name</span>
                  <div
                    className={`mt-1 text-[#2B2E3A] font-medium text-[15px] min-w-[300px] max-w-[70%] pr-1 bg-[#FFFFFF] focus:outline-none border-none
                      text-right sm:text-sm`}
                    contentEditable={isEditingUser}
                    suppressContentEditableWarning={true}
                  >
                    {user.fullname}
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-sm font-medium text-[#868CA2] w-[30%]">Email</span>
                  <div
                    className={`mt-1 text-[#2B2E3A] font-medium text-[15px] min-w-[300px] max-w-[70%] pr-1  bg-[#FFFFFF] focus:outline-none border-none
                      text-right sm:text-sm`}
                    contentEditable={isEditingUser}
                    suppressContentEditableWarning={true}
                  >
                    {user.email}
                  </div>
                </div>
                
              </div>
            </div>

              {/* Signature */}
              <div className="py-6 relative">
              <div className="flex justify-between  items-center bg-[#F3F3F3] py-1">
                <div className="text-lg text-[#1B1A22] font-medium pl-2">Store Account</div>
              
              </div>
              <div className="grid grid-cols-1 gap-3 mt-3 pl-2">
                <div className="flex items-center justify-between">
                  <span className="text-sm font-medium text-[#868CA2] w-[30%]">Store Name</span>
                  <div
                    className={`mt-1 text-[#2B2E3A] text-right font-medium text-[15px] min-w-[300px] max-w-[70%] pr-1  bg-[#FFFFFF] focus:outline-none border-none
                      sm:text-sm`}
                    contentEditable={isEditingStore}
                    suppressContentEditableWarning={true}
                  >
                    {storeData.storeName}
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-sm font-medium text-[#868CA2] w-[30%]">Owner Name</span>
                  <div
                    className={`mt-1 min-w-[300px] text-right max-w-[70%] pr-1  bg-[#FFFFFF] focus:outline-none border-none
                       sm:text-sm`}
                    contentEditable={isEditingStore}
                    suppressContentEditableWarning={true}
                  >
                    {storeData.ownerName}
                  </div>
                </div>
                {/* <div className="flex items-center">
                  <span className="text-sm font-medium text-[#868CA2] w-[30%]">Store URL</span>
                  <div
                    className={`mt-1 text-[#2B2E3A] text-right font-medium text-[15px] min-w-[300px] max-w-[70%] pr-1 ${
                      isEditingStore
                        ? "rounded-sm border border-[#868CA280] focus:outline-none bg-white"
                        : "bg-[#F9F9FB] focus:outline-none border-none"
                    } sm:text-sm`}
                    contentEditable={isEditingStore}
                    suppressContentEditableWarning={true}
                  >
                    {storeData.storeURL}
                  </div>
                </div> */}
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
