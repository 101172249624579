import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setActiveUser } from "../redux/userSlice";
import axios from "axios";

const Home = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  
  // const dispatch = useDispatch();
  // const { activeUser } = useSelector((state) => state);

  // useEffect(() => {
  //   const fetchUserData = async () => {
  //     const token = localStorage.getItem("token");
  //     const API_URL = process.env.REACT_APP_API_URL;

  //     try {
  //       const res = await axios.get(`${API_URL}/auth/me`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });

  //       const data = res.data;
  //       const userInfo = {
  //         id: data.user.id,
  //         first_name: data?.user?.first_name,
  //         last_name: data?.user?.last_name,
  //         email: data?.user?.email,
  //         profile_image: data?.user?.profile_image,
  //         is_active: data?.user?.is_active,
  //         is_verified: data?.user?.is_verified,
  //         is_super_admin: data?.user?.is_super_admin,
  //       };
  //       dispatch(setActiveUser(userInfo));
  //     } catch (error) {
  //       console.log(error);
  //       navigate('/signin')
  //     }
  //   };
  //   fetchUserData();
  // }, [dispatch, activeUser]);
  // useEffect(()=>{
  //   try{

  //   }catch(e){

  //   }
  // },[]);

  const handleClick = () => {
    // Check if token is present in local storage

    if (!token || isTokenExpired(token)) {
      // Navigate to sign-in page if token is expired or not present
      navigate('/signin');
    } else {
      // Navigate to draft page if token is valid
      navigate('/draft-page');
    }
    const token = localStorage.getItem("token");
  };

  const isTokenExpired = (token) => {
    // For example, you can decode the token and check its expiration date
    // If the token is expired, return true; otherwise, return false
    return false;
  };

  return (
    <div className="flex flex-col h-screen justify-center items-center">
      <button className="btn btn-info" onClick={handleClick}>
        Go to Draft Page
      </button>
    </div>
  );
};

export default Home;
