import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export const googleLogin = async (code, hostname) => {

  let res = await axios.get(`${API_URL}/v1/auth/google/callback?code=${code}`,{
    withCredentials : true
  } );

  localStorage.setItem("email", res.data.email);
  localStorage.setItem("token", res.data.token);
  localStorage.setItem("expiry_in", res.data.expires_in);
  localStorage.setItem("is_super_admin", res.data.is_super_admin);
};

export const isAuthenticated = async () => {
  // Check if the token is expired before making the request
  if (isTokenExpired()) {
    const refreshSuccess = await getRefreshToken();
    if (!refreshSuccess) {
      // If the refresh failed, return false to indicate the user is not authenticated
      return false;
    }
  }

  const token = localStorage.getItem("token");
  try {
    const res = await axios.get(`${API_URL}/v1/auth/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials : true,
    });


    const data = res.data;
    localStorage.setItem("profile", data.user.profile_image);
    localStorage.setItem("firstname", data.user.first_name);
    localStorage.setItem("lastname", data.user.last_name);
    if (data.user.is_super_admin !== true) {
      localStorage.setItem("storename", data.store.name);
    }
   
    localStorage.setItem("id", data.user.id);
    return true;
  } catch (error) {
    console.log(error);
    getRefreshToken();
    return false;
  }
};

export const getRefreshToken = async()=>{
  try{
    const res = await axios.post(`${API_URL}/v1/auth/token/refresh` ,{} ,{
      withCredentials : true
    });
    // Assuming the response contains the new token and its expiration time
    localStorage.setItem("email", res.data.email);
    localStorage.setItem("token", res.data.token);
    localStorage.setItem("expiry_in", res.data.expires_in);
    localStorage.setItem("is_super_admin", res.data.is_super_admin);
    return true;
  } catch (err) {
    console.error("Error during token refresh:", err);
    return false;
  }
}

export const isTokenExpired = () => {
  const tokenExpiryTime = localStorage.getItem("expiry_in");

  if (!tokenExpiryTime) {
    return true;
  }

  const currentTime = new Date();
  const expiryTime = new Date(tokenExpiryTime);
  // console.log(currentTime > expiryTime);
  return currentTime > expiryTime;
};
