import axiosInstance from "../../services/axiosInstance";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AiOutlineClose } from "react-icons/ai";
import WhiteLoadingAnimation from "../../assets/WhiteLoadingAnimation.svg";
import LoadingAnimation from "../../assets/LoadingAnimation.svg";

const AdminAddStorePage = ({ handleCloseAddStoreModal }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [isAdding, setIsAdding] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [storeValid, setStoreValid] = useState(false);
  const [clientDetails, setClientDetails] = useState(null);
  // This will hold the input values
  const [storeInput, setStoreInput] = useState({
    store_name: "",
    store_id: "",
  });

  // This will control whether the store name is non-editable
  const [isStoreNameReadOnly, setIsStoreNameReadOnly] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setStoreInput((prev) => ({ ...prev, [name]: value }));

    if (e.target.name === "store_id" && storeValid) {
      setStoreValid(false);
      setStoreInput((prev) => ({ ...prev, store_name: "" })); // Clear the store name if the store ID changes
    }
  };

  const handleAddStore = async (e) => {
    e.preventDefault();
    if (!storeValid) {
      toast.error("Store Id is not validated");
      return;
    }
    setIsAdding(true);
    const details = {
      ...clientDetails, // Spread the rest of the details from the client API response
      name: storeInput.store_name,
      store_id: String(clientDetails?.store_id), // Explicitly convert store_id to a string
      access_token: clientDetails?.access_token, // Include access_token if available
    };
    try {
      const res = await axiosInstance.post(`${API_URL}/v1/admin/stores`, details);
      const data = res.data;
      toast.success("Store added successfully");
      handleCloseAddStoreModal();
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
    setIsAdding(false);
  };

  const handleShopNameValidation = async () => {
    setIsFetching(true);
    const details = { store_id: storeInput.store_id };
    try {
      const response = await axiosInstance.post(`${API_URL}/v1/servicepoints/get-client-details`, details);
      if (response.status === 200 || response.status === 301) {
        const storeNameFromAPI = response.data.store_name; // Assuming API returns store_name
        setStoreInput((prev) => ({ ...prev, store_name: storeNameFromAPI }));
        setStoreValid(true);
        setClientDetails(response.data); 
        toast.success("Store Id is valid");
      }
    } catch (error) {
      toast.error(error.response?.data?.detail || "Store Id is invalid or request failed");
    }
    setIsFetching(false);
  };

  return (
    <div className="w-[480px] h-[234px] font-dmsans">
      <div className="relative flex items-center mb-10">
        <div className="flex justify-between w-full">
          <div className="text-[16px] font-medium ml-1 text-[#000000]">Add Shopify Store</div>
          <button className="text-[#2B2E3A] mt-[-10px]" onClick={handleCloseAddStoreModal}>
            <AiOutlineClose size={16} />
          </button>
        </div>
      </div>
      <div className="rounded-md w-full ml-2 items-center">
        <form onSubmit={handleAddStore}>
          <div className="grid grid-cols-1 gap-8">
            <div className="relative flex border-[1px] border-[#868CA280] w-[464px] h-[38px]">
              <input
                id="store_id"
                type="text"
                name="store_id"
                onChange={handleInputChange}
                required
                value={storeInput.store_id}
                autoComplete="off"
                className="peer appearance-none bg-transparent text-[#2B2E3A] text-sm leading-tight focus:outline-none focus:shadow-outline w-full pl-4"
              />
              {isFetching ? (
                <div className="flex items-center justify-center absolute right-2 h-full">
                  <img src={LoadingAnimation} alt="loading..." className="text-green-500 w-14 h-14" />
                </div>
              ) : (
                <button
                  type="button"
                  className="absolute right-1 text-[#F3F3F3] bg-[#2B2E3A] w-[80px] flex items-center justify-center h-[80%] mt-1 text-sm"
                  onClick={handleShopNameValidation}
                >
                  {storeValid ? <p>VERIFIED</p> : <p>VERIFY</p>}
                </button>
              )}
              <label
                htmlFor="store_id"
                className="absolute left-3 -top-3 px-1 text-base text-[#2B2E3A] transition-all bg-[#FFFFFF]"
              >
                Store ID
              </label>
            </div>

            {/* Store Name Input Field */}
            <div className="relative flex border-[1px] border-[#868CA280] w-[464px] h-[38px]">
              <input
                id="store_name"
                type="text"
                name="store_name"
                value={storeInput.store_name}
                autoComplete="off"
                className="peer appearance-none bg-transparent text-[#2B2E3A] text-sm leading-tight focus:outline-none focus:shadow-outline w-full pl-4"
                readOnly              />
              <label
                htmlFor="store_name"
                className="absolute left-3 -top-3 px-1 text-base text-[#2B2E3A] transition-all bg-[#FFFFFF]"
              >
                Store Name
              </label>
            </div>
          </div>
          <div className="mt-8 flex justify-center">
            <button
              disabled={isAdding}
              className="bg-[#96F3C3] w-[100px] h-[30px] rounded-[8px] text-[#2B2E3A] font-normal px-4 focus:outline-none focus:shadow-outline flex items-center justify-center text-sm"
            >
              {isAdding ? (
                <img src={WhiteLoadingAnimation} alt="Loading..." className="h-10 w-10" />
              ) : (
                "Add"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminAddStorePage;
