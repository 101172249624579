import React, { useEffect, useState,useRef } from "react";
import { useNavigate, Link ,useParams} from "react-router-dom";
import GoogleSignInButton from "../components/GoogleSigninButton";
import { toast } from "react-toastify";
import axios from "axios";
import MailLogo from "../assets/SidebarAssets/MailLogo.svg";
import DraftBoardImage from "../assets/DraftBoardImage.svg";
const Signin = () => {

  const navigate = useNavigate();
  const [errorPopup, setErrorPopup] = useState(true);
  const [inviteId, setInviteId] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;
  const hasInvitedPosted = useRef(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const inviteIdParam = urlParams.get('invite_id');
    if (inviteIdParam && !hasInvitedPosted.current) {
      setInviteId(inviteIdParam);
      const postInviteId = async () => {
        try {
          const details = {
            invite_id: String(inviteIdParam)
          };          
          const response = await axios.post(`${API_URL}/v1/invites/accept`, details);
          toast.success("Invitation accepted!");
          navigate('/signin', { replace: true });
        } catch (error) {
          toast.error(`${error.response?.data?.detail || error.response?.data?.error || 'An unexpected error occurred'}`);
        }
      };
      hasInvitedPosted.current = true;
      postInviteId();
    }
  }, [navigate, API_URL]);

  const boxShadow = `
  0 21px 294px 83px rgba(0, 0, 0, 0.00),
  13px 188px 76px 0px rgba(0, 0, 0, 0.01),
  7px 106px 64px 0px rgba(0, 0, 0, 0.05),
  3px 47px 47px 0px rgba(0, 0, 0, 0.09),
  1px 12px 26px 0px rgba(0, 0, 0, 0.10)
`;
  const boxShadowStyle = {
    boxShadow: boxShadow,
  };


  return (
    <section className="h-screen grid grid-cols-2 font-dmsans overflow-hidden">
      {/* Left Section */}
      <div className="flex justify-center items-center bg-white p-8 mb-10">
        <div className="text-center max-w-sm">
          {/* Top Cloud Decoration */}
          <img src={MailLogo} alt="Mail Box" className="mb-3 w-[56px] mx-auto" />

          {/* Welcome and Login Form */}
          <h1 className="text-xl font-medium text-[#000000] mb-10 ">Welcome to Custo.In</h1>
          <p className="text-[#090914] font-medium text-[18px] mb-6">
            Sign in to your account          </p>
          <GoogleSignInButton inviteId={inviteId} />
        </div>
      </div>

      {/* Right Section */}
      <div className="flex flex-col justify-between bg-[#D7FDED80] pl-14  pt-8 pr-0">
        {/* Top Descriptive Text */}
        <div className="text-[13px] font-dmsans tracking-[0.32px] text-[#2B2E3A] mb-1 ml-2 w-[678px]">
          <p className="font-normal">Welcome to Custo.in - Your One-Stop Shop for Efficient Order Processing With Custo.in,</p>
          <p>streamline your entire order management process through a single, user-<br />friendly platform. Easily manage <span className="font-bold text-black">purchase orders, shipment tracking, delivery</span></p>
          <p><span className="font-bold text-black">updates,refunds, cancellations, product availability,</span> and <span className="font-bold text-black">order tracking</span> all in one place.</p>
          <p>Spend less time navigating the complexities of order administration and more time </p>
          <p>focusing on what truly matters—<span className="font-bold text-black">growing your business.</span> Simplify your workflow </p>
          <p>with Custo.in and unlock the full potential of your success.</p>

        </div>

        {/* Bottom Image */}
        <div className="flex justify-center items-end mt-2">
          <img src={DraftBoardImage} alt="Draft Board" className="w-full mt-8" />
        </div>
      </div>
    </section>

  );
};

export default Signin;
