import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { isAuthenticated } from "../services/login";

const PrivateRoute = ({ children, isAdminRoute }) => {
  const token = localStorage.getItem("token");
  const tokenExpiration = localStorage.getItem("expiry_in");
  const isSuperAdmin = localStorage.getItem("is_super_admin");
  const location = useLocation();
  const [authStatus, setAuthStatus] = useState('checking');

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const isAuth = await isAuthenticated();
        setAuthStatus(isAuth ? "authenticated" : "unauthenticated");
      } catch (error) {
        console.error("Authentication error:", error);
        setAuthStatus("unauthenticated");
      }
    };

    checkAuth();
  }, []);

  if (authStatus === "checking") {
    return <div>Loading...</div>; 
  }

  if (authStatus === "unauthenticated") {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  if (!token) {
    return <Navigate to="/signin" replace />;
  }
  
  if(isSuperAdmin === "True"  ){
    return <Navigate to = "/admin/dashboard-page" replace />;
  }
  if (location.pathname === '/') {
    return <Navigate to="/document-page" replace />;
  }
  
  if (isSuperAdmin === "False") {
    return <Outlet />;
  }
  
 
  return <Navigate to="/error" replace />;
  
  

};

export default PrivateRoute;
