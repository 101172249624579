import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa6";
import PopupModal from "../components/PopupModal";
import { ShopifyAccountDetails } from "../components/ShopifyAccountDetails";
import ShopIcon from "../assets/IntegrationPageAssets/ShopIcon.svg";
import DeleteIcon from "../assets/documentPageAssets/DeleteIcon.jsx";
import RestoreIcon from "../assets/IntegrationPageAssets/RestoreIcon.svg";
import { toast } from "react-toastify";
import axios from "axios";
import WhiteLoadingAnimation from "../assets/WhiteLoadingAnimation.svg";
import { useSelector } from 'react-redux';
import AddStoreIcon from "../assets/IntegrationPageAssets/AddStoreIcon.svg";
import FeaturedIcon from "../assets/FeaturedIcon.svg";
import axiosInstance from "../services/axiosInstance.js";
const IntegrationPage = () => {
  const [showShopifyModal, setShowShopifyModal] = useState(false);
  const [showDeleteShopifyModal, setShowDeleteShopifyModal] = useState(false);
  const [showRestoreShopifyModal, setShowRestoreShopifyModal] = useState(false);
  const [stores, setStores] = useState([]);
  const [prevStoresConnected, setPrevStoresConnected] = useState([]);
  const [storeIdToDelete, setStoreIdToDelete] = useState(null);
  const [isLoadingStores, setIsLoadingStores] = useState(true);
  const [isLoadingPrevStores, setIsLoadingPrevStores] = useState(true);
  const token = localStorage.getItem("token");
  const API_URL = process.env.REACT_APP_API_URL;
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [showdeleteModal, setShowdeleteModal] = useState(false)
  const handleShopifyModal = () => {
    setShowShopifyModal(true);
  };

  const handleCloseShopifyModal = () => {
    setShowShopifyModal(false);
  };

  const handleShopifyDeleteModal = (storeId) => {
    setStoreIdToDelete(storeId);
    setShowDeleteShopifyModal(true);
  };

  const handleCloseShopifyDeleteModal = () => {
    setShowDeleteShopifyModal(false);
    setStoreIdToDelete(null);
  };

  const handleShopifyRestoreModal = (storeId) => {
    setStoreIdToDelete(storeId);
    setShowRestoreShopifyModal(true);
  };

  const handleCloseShopifyRestoreModal = () => {
    setShowRestoreShopifyModal(false);
    setStoreIdToDelete(null);
  };

  const fetchStores = async () => {
    setIsLoadingStores(true);
    try {
      const response = await axiosInstance.get(`${API_URL}/v1/store/shopify/accounts`, {
       
      });
      const data = response.data;
      setStores(data);
    } catch (error) {
      toast.error(error.response?.data?.detail || "Error fetching stores");
    } finally {
      setIsLoadingStores(false);
    }
  };

  const fetchPrevConnectedStores = async () => {
    setIsLoadingPrevStores(true);
    try {
      const response = await axiosInstance.get(`${API_URL}/v1/store/shopify/accounts?disconnected=True`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      setPrevStoresConnected(data);
    } catch (error) {
      toast.error(error.response?.data?.detail || "Error fetching stores");
    } finally {
      setIsLoadingPrevStores(false);
    }
  };

  const handleUpdateAccount = async (storeId, isDeleted) => {
    setIsLoading(true)
    try {
      const response =await axiosInstance.patch(
        `${API_URL}/v1/store/shopify/accounts/${storeId}?is_deleted=${isDeleted}`
       
      );
      fetchStores();
      fetchPrevConnectedStores();
      setShowDeleteShopifyModal(false);
      setShowRestoreShopifyModal(false);
    } catch (error) {
      toast.error(error.response?.data?.detail || `Error updating store`);
    }
    setIsLoading(false)
  };

  useEffect(() => {
    fetchStores();
    fetchPrevConnectedStores();
  }, []);

  const handleDeleteAccount = async (storeId) => {
    setIsDeleting(true)
    try {
      const response = await axiosInstance.delete(
        `${API_URL}/v1/store/shopify/accounts/${storeId}`
      
      );
      toast.success("Store Deleted Successfully");
      fetchStores();
      fetchPrevConnectedStores();
      setShowDeleteShopifyModal(false);
      setShowRestoreShopifyModal(false);
      setShowdeleteModal(false);
    } catch (error) {
      toast.error(error.response?.data?.detail || `Error deleting store`);
    }
    setIsDeleting(false)
  };

  const handleDeleteAccountModal = (storeId) => {
    setStoreIdToDelete(storeId);
    setShowdeleteModal(true);
  };

  const handleCloseDeleteAccountModal = () => {
    setShowdeleteModal(false);
    setStoreIdToDelete(null);
  };

  return (
    <div className="bg-[#FFFFFF]">
      <div className={`flex justify-center ${isSidebarOpen ? 'ml-[260px]' : 'ml-[140px]'} transition-all duration-300 font-dmsans mt-2`}>
        <div className="w-full max-w-[80%]">

          {isLoadingStores && isLoadingPrevStores? (
            <div className="flex justify-center mt-10 min-h-screen">
              <div className="space-y-24 w-[80%]">
                {/* Connected Stores Shimmer */}
                <div className="space-y-4">
                  <div className="h-4 bg-gray-300 rounded w-[20%] animate-pulse"></div>
                  <div className="h-[100px] bg-gray-200 rounded-md flex items-center animate-pulse">
                    <div className="w-[50px] h-[50px] bg-gray-300 rounded-full mx-4"></div>
                    <div className="flex-1 space-y-2 py-1">
                      <div className="h-4 bg-gray-300 rounded w-[80%]"></div>
                      <div className="h-4 bg-gray-300 rounded w-[80%]"></div>
                    </div>
                    <div className="w-[100px] h-[40px] bg-gray-300 rounded mr-4"></div>
                  </div>
                </div>

                {/* Disconnected Stores Shimmer */}
                <div className="space-y-4 ">
                  <div className="h-4 bg-gray-300 rounded w-[20%] animate-pulse"></div>
                  <div className="h-[100px] bg-gray-200 rounded-md flex items-center animate-pulse">
                    <div className="w-[50px] h-[50px] bg-gray-300 rounded-full mx-4"></div>
                    <div className="flex-1 space-y-2 py-1">
                      <div className="h-4 bg-gray-300 rounded w-[80%]"></div>
                      <div className="h-4 bg-gray-300 rounded w-[80%]"></div>
                    </div>
                    <div className="flex space-x-2 mr-4">
                      <div className="w-[70px] h-[40px] bg-gray-300 rounded"></div>
                      <div className="w-[40px] h-[40px] bg-gray-300 rounded"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          

          ) : (
            <>
              {(stores.length === 0 && prevStoresConnected.length === 0) && (
                <div className="flex items-center justify-center max-h-screen">
                  <div className="flex flex-col items-center border border-[#868CA280] justify-center h-[214px] w-[200px] bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 ease-in-out cursor-pointer transform translate-y-2/3" onClick={handleShopifyModal}>
                    <img src={AddStoreIcon} alt="Add Store" className="h-[90px] w-[90px]" />
                    <span className="mt-3 text-[#000000] text-lg font-medium">Add Store</span>
                    <div className="text-[#000000] mt-1 text-sm font-medium">Add Store to get started</div>
                  </div>
                </div>
              )}

              {showShopifyModal && (
                <PopupModal closeModal={handleCloseShopifyModal} className="bg-[#FFFFFF]">
                  <ShopifyAccountDetails closeModal={handleCloseShopifyModal} fetchStores={fetchStores} />
                </PopupModal>
              )}

              {(stores.length !== 0 || prevStoresConnected.length !== 0) && (
                <div className="bg-white overflow-y-auto mt-10">

                  {/* Connected Stores Section */}

                  <div className="max-w-full px-6 py-8 transition-all duration-300 pt-4 items-center justify-center">
                    <div className="flex justify-between items-center mb-4 px-6">
                      <h1 className="text-[#000000] text-[16px] font-medium ">Connected Stores</h1>
                      {stores.length === 0 && <button className="bg-[#96F3C3] border border-[#868CA280] text-[#2B2E3A] text-sm px-2 py-2 rounded shadow-md flex items-center hover:shadow-lg transition-shadow duration-200 gap-1" onClick={handleShopifyModal}>
                        <FaPlus className="text-[#28282E]" />
                        Add Store
                      </button>}
                    </div>
                    {stores.length > 0 ? (
                      <div className="px-6">
                        {stores.map((store, index) => (
                          <div key={index} className="bg-white rounded-lg shadow-md px-4 py-2 flex justify-between items-center mb-2 h-[100px]">
                            <div className="flex items-center">
                              <img src={ShopIcon} alt="Shop Icon" className="h-14 w-14" />
                              <div className="ml-4">
                                <div className="text-[16px] font-medium">
                                  {store.shop_domain}
                                </div>
                              </div>
                            </div>
                            <button className="bg-[#F3F3F3] text-[#1B1A22] px-3 py-1 rounded-lg text-sm border border-[#868CA2]" onClick={() => handleShopifyDeleteModal(store.id)}>Disconnect</button>
                          </div>
                        ))}
                      </div>
                    ) : (<p className="text-center my-10 text-red-400">No connected stores available.</p>)}
                  </div>


                  {/* Disconnected Stores Section */}
                  {prevStoresConnected.length > 0 && (
                    <div className="max-w-full px-6 py-8 transition-all duration-300 pt-4 items-center justify-center">
                      <div className="flex justify-between items-center mb-4 px-6">
                        <h1 className="text-[#000000] text-[16px] font-medium pt-2">Disconnected Stores</h1>
                      </div>

                      <div className="px-6">
                        {prevStoresConnected.map((store) => (
                          <div key={store.id} className="bg-white rounded-lg shadow-md px-4 py-2 flex justify-between items-center mb-6 h-[100px]">
                            <div className="flex items-center">
                              <img src={ShopIcon} alt="Shop Icon" className="h-14 w-14" />
                              <div className="ml-4">
                                <div className="text-[16px] text-[#000000] font-medium">{store.shop_domain}</div>
                              </div>
                            </div>
                            <div className="flex space-x-2">
                              <button
                                className={`px-3 h-[30px] my-auto text-sm rounded-lg ${stores.length > 0
                                  ? 'bg-[#A0A0A0] text-[#FFFFFF] cursor-not-allowed' // Disabled style
                                  : 'bg-[#039855] text-[#FFFFFF]'
                                  }`}
                                onClick={() => handleShopifyRestoreModal(store.id)}
                                disabled={stores.length > 0} // Disable if there are stores
                              >
                                Restore
                              </button>
                              <button data-testid="delete-button" className=" text-red-600 px-2 py-2 rounded-md" onClick={() => handleDeleteAccountModal(store.id)}><DeleteIcon className="  w-[22px] h-[22px]" /></button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}


              {(showDeleteShopifyModal || showdeleteModal) && (
                <PopupModal closeModal={handleCloseShopifyDeleteModal} className="bg-[#F9F9F9]">
                  <div className="flex flex-col justify-center items-center h-[200px] w-[360px]">
                    <img src={FeaturedIcon} alt="" className="mb-3" />
                    <div className="text-[#101828] font-medium text-lg mb-2">
                      {showdeleteModal ? "Delete Store" : "Disconnect Store"}
                    </div>
                    <p className="text-[#667085] font-dmsans text-[14px] font-normal mb-3 text-center">
                      {showdeleteModal ? (
                        <span>Are you sure you want to delete this store<br /> permanently?</span>
                      ) : (
                        "Are you sure you want to disconnect this store?"
                      )}
                    </p>
                    <div className="flex justify-center space-x-6 w-full">
                      <button
                        className="text-base font-dmsans bg-[#F3F3F3] text-[#344054] rounded-md shadow-sm w-[150px] h-[44px] flex items-center justify-center"
                        onClick={showdeleteModal ? handleCloseDeleteAccountModal : handleCloseShopifyDeleteModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        onClick={() => showdeleteModal ? handleDeleteAccount(storeIdToDelete) : handleUpdateAccount(storeIdToDelete, true)}
                        disabled={(isLoading || isDeleting)}
                        className="w-[150px] h-[44px] rounded-md bg-[#D92D20] text-base font-medium text-[#FFFFFF] text-center flex items-center justify-center"
                      >
                        {(isLoading || isDeleting) ? (
                          <img src={WhiteLoadingAnimation} alt="Loading..." className="h-10 w-10" />
                        ) : (
                          showdeleteModal ? "Delete" : "Disconnect"
                        )}
                      </button>
                    </div>
                  </div>
                </PopupModal>

              )}

              {showRestoreShopifyModal && (
                <PopupModal closeModal={handleCloseShopifyRestoreModal} className="bg-[#F9F9F9]">
                  <div className="flex flex-col justify-center items-center h-[200px] w-[360px]">
                    <img src={RestoreIcon} alt="" className="mb-3" />
                    <div className="text-[#101828] font-medium text-lg mb-2">Restore Store</div>
                    <p className="text-[#667085] font-dmsans text-[14px] font-normal mb-6 text-center">
                      Are you sure you want to restore this store?
                    </p>
                    <div className="flex justify-center space-x-6 w-full">
                      <button
                        className="text-base font-dmsans bg-[#F3F3F3] text-[#344054] rounded-md shadow-sm w-[150px] h-[44px] flex items-center justify-center"
                        onClick={handleCloseShopifyRestoreModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        onClick={() => handleUpdateAccount(storeIdToDelete, false)}
                        disabled={isLoading}
                        className="w-[150px] h-[44px] rounded-md bg-[#039855] text-base text-[white] font-medium text-center flex items-center justify-center"
                        data-testid="restore"
                      >
                        {isLoading ? (
                          <img src={WhiteLoadingAnimation} alt="Loading..." className="h-10 w-10" />
                        ) : (
                          "Restore"
                        )}
                      </button>
                    </div>
                  </div>
                </PopupModal>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default IntegrationPage;
