import React, { useState, useRef } from 'react';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa6';
import { toast } from "react-toastify";
import WhiteLoadingAnimation from "../../assets/WhiteLoadingAnimation.svg";
import axiosInstance from "../../services/axiosInstance";

const ExchangeItem = ({ thread_id, exchangeDetails, handleClosExchangeItem, setExchangeSucessModal, orderData, draft_id }) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem("token");
    const [isLoading, setIsLoading] = useState(false);

    const lineItems = exchangeDetails?.line_items || [];
    const availableSizes = ['S', 'M', 'L', 'XL']; // Define available sizes here

    // Store initial values in useRef to keep them constant
    const initialQuantities = useRef(lineItems.map(item => item.quantity));
    const initialSizes = useRef(lineItems.map(item => item.size || availableSizes[0]));

    // State for handling quantity, prices, and selected sizes
    const [quantities, setQuantities] = useState(initialQuantities.current);
    const [prices, setPrices] = useState(lineItems.map(item => item.price * item.quantity));
    const [selectedSizes, setSelectedSizes] = useState(initialSizes.current);

    const handleQuantityChange = (index, newQuantity) => {
        const newQuantities = [...quantities];
        const newPrices = [...prices];

        newQuantities[index] = newQuantity;
        newPrices[index] = newQuantity * lineItems[index].price;

        setQuantities(newQuantities);
        setPrices(newPrices);
    };

    const handleSizeChange = (index, newSize) => {
        const newSizes = [...selectedSizes];
        newSizes[index] = newSize;
        setSelectedSizes(newSizes);
    };

    const handleExchangeOrder = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            // Construct updated line items with the new quantities, prices, and sizes
            const updatedLineItems = lineItems.map((item, index) => ({
                ...item,
                quantity: quantities[index],
                price: prices[index],
                ...(item?.size && { size: selectedSizes[index] }), 
                title: item.title ? item.title : item.name
            }));            

            const payload = {
                thread_id: thread_id,
                draft_id: draft_id,
                order_id: orderData[0].id.toString(),
                line_items: updatedLineItems,
            };

            const response = await axiosInstance.post(
                `${API_URL}/v1/store/shopify/order/exchange`,
                payload,
            );
            toast.success("Order updated successfully.");
            setExchangeSucessModal(true);

        } catch (error) {
            toast.error(`${error.response?.data?.detail}`);
        }
        setIsLoading(false);
    };

    const isQuantityChanged = (index) => quantities[index] !== initialQuantities.current[index];
    const isSizeChanged = (index) => selectedSizes[index] !== initialSizes.current[index];

    return (
        <div className="flex flex-col font-dmsans pt-2 mt-10 border border-[#868CA24D] rounded-lg">
            <div className="flex gap-5 pb-2 text-xs font-medium border-b border-[#868CA24D] leading-none text-gray-800">
                <div className="flex items-center gap-2 ml-2 h-[30px]">
                    <div className="text-[#2B2E3A] text-[12px]">Exchange Items</div>
                </div>
            </div>
            
            <div className="pl-2 py-3">
                <div className="">
                    <div className="text-[#000000] underline text-xs font-medium pb-0">Updated Cart</div>
                    {lineItems.map((item, index) => (
                        <div key={index} className="flex items-center justify-between mt-3 gap-4">
                            <div className="w-1/4 mr-4">
                                <span className="text-[12px] capitalize text-[#000000] font-medium">{item.name}</span>
                            </div>

                            {/* Conditionally render Size dropdown if size is available */}
                            {item.size  && (
                                <div className="w-1/4">
                                    <div className="flex items-center gap-2">
                                        <span className="text-[12px] capitalize text-[#000000]">Size</span>
                                        <select
                                            value={selectedSizes[index]}
                                            onChange={(e) => handleSizeChange(index, e.target.value)}
                                            className={`border rounded text-[11px] text-[#000000] h-[24px] focus:outline-none ${isSizeChanged(index) ? 'bg-[#F1F9F7]' : ''}`}
                                        >
                                            {availableSizes.map((size) => (
                                                <option key={size} value={size}>{size}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            )}

                            <div className={`w-${item.size ? '1/4' : '1/3'}`}>
                                <div className="flex items-center gap-2">
                                    <span className="text-[12px] capitalize text-[#000000]">Quantity</span>
                                    <div className="relative flex items-center w-[50px]">
                                        <input
                                            type="number"
                                            value={quantities[index]}
                                            onChange={(e) => {
                                                const newValue = parseInt(e.target.value);
                                                if (newValue >= 1) {  // Minimum value 1
                                                    handleQuantityChange(index, newValue);
                                                }
                                            }}
                                            className={`no-arrows w-full text-center pr-1 border border-[#1212121A] text-xs rounded-md focus:outline-none h-[23px] ${isQuantityChanged(index) ? 'bg-[#F1F9F7]' : ''}`}
                                            min={1} // Minimum value 1
                                        />
                                        <div className="absolute right-1 top-1/2 transform -translate-y-1/2 flex flex-col justify-center h-[26px]">
                                            <button
                                                type="button"
                                                onClick={() => handleQuantityChange(index, quantities[index] + 1)}
                                                className="flex"
                                            >
                                                <FaAngleUp className={`text-[#000000] w-3 h-2`} />
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => handleQuantityChange(index, quantities[index] - 1)}
                                                className="flex"
                                                disabled={quantities[index] <= 1} // Disable when quantity is 1
                                            >
                                                <FaAngleDown className={`text-[#000000] w-3 h-2 ${quantities[index] <= 1 ? 'text-gray-400' : ''}`} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-1/4">
                                <div className="flex items-center gap-2">
                                    <span className="text-[12px] capitalize text-[#000000]">Price</span>
                                    <input 
                                        type="text" 
                                        className="w-20 text-center border rounded text-[12px] h-[26px]  focus:outline-none"
                                        value={prices[index].toFixed(2)}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="flex justify-end mt-6 space-x-4 pr-2">
                    <button
                        type="button"
                        className="px-2 w-[69px] h-[30px] text-[#2B2E3A] text-[13px] border rounded-lg border-[#D0D5DD]"
                        onClick={handleClosExchangeItem}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="w-auto px-2 min-w-[154px] h-[30px] text-[#2B2E3A] text-[13px] bg-[#96F3C3] rounded-lg flex items-center justify-center"
                        disabled={isLoading}
                        onClick={handleExchangeOrder}
                    >
                        {isLoading ? (
                            <img src={WhiteLoadingAnimation} alt="Loading..." className="h-10 w-14" />
                        ) : (
                            "Confirm Exchange & Send Email"
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ExchangeItem;
