export const extractNameAndEmail = (str) => {
  const regex = /^(.*?)\s*<(.+?)>$/;
  const match = str.match(regex);
  if (match) {
    return {
      name: match[1].trim(),
      email: match[2].trim(),
    };
  } else if (str.includes('@')) { // Handle case where only email is provided
    return {
      name: str.trim(),
      email: str.trim(),
    };
  }
  return {
    name: 'Unknown Sender',
    email: '',
  };
};
