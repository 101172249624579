import React, { useEffect, useState, useCallback } from "react";
import { IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import axios from "axios";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import DraftsPagination from "../components/DraftsPagination.jsx";
import LoadingAnimation from "../assets/LoadingAnimation.svg";
import { useSelector } from 'react-redux';
import '../components/ExamplePageComponents/CustomTheme.css';
import { marked } from 'marked';
import { formatCustomDateTime } from "../services/logics";
import DocumentListShimmer from "../components/DocumentSkeleton.jsx";
import DeleteIcon from "../assets/documentPageAssets/DeleteIcon.jsx";
import FeaturedIcon from "../assets/FeaturedIcon.svg";
import PopupModal from "../components/PopupModal";
import WhiteLoadingAnimation from "../assets/WhiteLoadingAnimation.svg";
import axiosInstance from "../services/axiosInstance.js";

const ExamplesPage = () => {
    const navigate = useNavigate();
    const [examplesData, setExamplesData] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const API_URL = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem("token");
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(1);
    const [totalExamples, setTotalExamples] = useState(0);
    const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null); 

    // Fetch examples data with the current search term and page number
    const fetchExamplesData = async (searchTerm, page = 1) => {
        try {
            const response = await axiosInstance.get(
                `${API_URL}/v1/store/draft/examples`, {
                params: {
                    page,
                    page_size: 10,
                    search: searchTerm,
                },
               
            });
            const data = response.data;
            setExamplesData(data.items);
            setTotalExamples(data.total);
        } catch (error) {
            toast.error(`${error.response.data.detail}`);
            // if (error.response && error.response.status === 401) {
            //     toast.error("Your session has expired");
            //     navigate("/signin");
            // }
        }
        setIsFetching(false);
    };

    // Debounced function to handle search term changes
    const debouncedFetchData = useCallback(
        debounce((term) => {
            setPage(1);
            fetchExamplesData(term, 1); // Reset to first page on new search
        }, 500),
        []
    );

    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        debouncedFetchData(value);
    };

    useEffect(() => {
        fetchExamplesData(searchTerm, page);
    }, [page]);

    const handlePageChange = (newPage) => {
        if (newPage !== page) {
            setIsFetching(true);
        }
        setPage(newPage);
    };

    const stripMarkdown = (markdown) => {
        const html = marked(markdown);
        const tmp = document.createElement("div");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    };
    const getBgColor = (dataType) => {
        if (dataType.includes("Enquiry")) {
            return 'bg-[#1A13E326] text-[#1A13E3]';
        } else if (dataType.includes("Refund")) {
            return 'bg-[#23BE5426] text-[#23BE54]';
        } else if (dataType.includes("Order")) {
            return 'bg-[#FA343426] text-[#FA3434]';
        } else if (dataType.includes("Product")) {
            return 'bg-yellow-100 text-yellow-500';
        } else {
            return 'bg-gray-100 text-gray-500';
        }
    };
    const handleDelete = () => {
        setShowDeleteModal(false);
    };

    const handleDeleteExample = async () => {
        try {
            setIsDeleting(true)
            await axiosInstance.delete(`${API_URL}/v1/store/draft/examples/${selectedId}`);
            // Fetch data for the current page
            const response = await axiosInstance.get(
                `${API_URL}/v1/store/draft/examples`, {
                params: {
                    page,
                    page_size: 10,
                    search: searchTerm,
                },
            });
            const data = response.data;
    
            // If the current page has no items and it's not the first page, go to the previous page
            if (data.items.length === 0 && page > 1) {
                setPage(page - 1);
                fetchExamplesData(searchTerm, page - 1);
            } else {
                setExamplesData(data.items);
                setTotalExamples(data.total);
            }
            toast.success("Example Deleted successfully");
            setShowDeleteModal(false);
    
        } catch (error) {
            toast.error(`${error.response?.data?.detail}`);
        }
        setIsDeleting(false)
    };

    return (
        <div className={`py-3  overflow-y-auto  flex flex-col  font-dmsans ${isSidebarOpen ? 'ml-[244px] px-6' : 'ml-[80px] px-14'} transition-all ease-in-out duration-500`}>
            <div className="flex items-center justify-between ml-1 mb-4 ">
                <div className="flex items-center ">
                   
                </div>
                <div className="flex items-center space-x-4 relative">
                    <div className="relative w-[270px] h-[40px] border border-[#868CA2] bg-white text-[#000000] rounded-sm">
                        <IoSearch className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-[#64748B]" />
                        <input
                            type="text"
                            placeholder="Search"
                            className="focus:outline-none w-[84%] h-full ml-8 text-[#64748B] text-[15px]"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </div>

                    <div className="flex items-center justify-center rounded-md font-medium leading-none text-[#2B2E3A] w-[147px] h-[40px] bg-[#96F3C3] cursor-pointer" onClick={() => navigate(`/create-example`)}>
                        <span className="self-stretch my-auto text-[16px]">Create Example</span>
                    </div>
                </div>
            </div>
            <div className="overflow-y-auto  custom-scrollbar bg-white h-auto justify-between border-gray-200  w-auto mt-4">
                {isFetching ? (
                    <DocumentListShimmer items={9} show={false} />
                ) : (
                    <>
                        {examplesData.length === 0 ? (
                            <p className="text-center mt-20 text-red-400">No Examples found</p>
                        ) : (
                            <>
                                <table className="min-w-full  bg-white">
                                    <thead className="bg-[#E1E1E64D] h-[40px] text-[#000000] text-sm font-normal font-dmsans">
                                        <tr>
                                            <th className="py-2 w-[574px] text-left pl-4 ">Title</th>
                                            <th className="py-2 w-[242px] text-center ">Status</th>
                                            <th className="py-2 w-[212px] text-center">Date | Time</th>
                                            <th className="text-center w-[60px]"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {examplesData.map((data) => (
                                            <tr
                                                key={data.id}
                                                className="h-[10px]  hover:border-[#39F6A3] hover:bg-[#39F6A30D] cursor-pointer"
                                                onClick={() => navigate(`/example/${data.id}`)}
                                            >
                                                <td className="px-4 py-3 min-w-[180px] max-w-[574px] truncate ">
                                                    <span className="text-xs font-medium text-[#000000] pr-1">
                                                        {data?.name} -
                                                    </span>
                                                    <span className="text-xs min-w-[180px] font-normal truncate transition duration-300 text-[#2B2E3AA1]">
                                                        {stripMarkdown(data?.template)}
                                                    </span>
                                                </td>
                                                <td className="w-[242px] min-w-[180px] text-center ">
                                                    <span className={`px-2 py-1 text-xs rounded-full ${getBgColor(data?.type)}`}>
                                                        {data?.type}
                                                    </span>
                                                </td>
                                                <td className=" w-[212px]  min-w-[180px] text-center text-[#2B2E3A] font-normal text-xs">
                                                    {formatCustomDateTime(data.updated_at, false)}
                                                </td>
                                                <td>
                                                    <button data-testid="delete" className=" flex items-center justify-start  w-[28px] h-[28px]" onClick={(event) => {
                                                        event.stopPropagation(); // Prevent row click
                                                        setShowDeleteModal(true);
                                                        setSelectedId(data?.id)
                                                    }}>
                                                        <DeleteIcon className="  w-[20px] h-[20px]" />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </>
                        )}
                    </>
                )}
            </div>
           {examplesData.length >0 && <DraftsPagination
                currentPage={page}
                onPageChange={handlePageChange}
                totalDrafts={totalExamples}
            />}

            {/* Show delete Modal */}
            {showDeleteModal && (
                <PopupModal closeModal={handleDelete} className="bg-[#F9F9F9]">
                    <div className="flex flex-col justify-center items-center  h-[200px] w-[360px]">
                        <img src={FeaturedIcon} alt="" className="mb-3" />
                        <div className="text-[#101828] font-medium text-lg mb-2">Delete Example</div>
                        <p className="text-[#667085] font-dmsans text-[15px] font-normal mb-6 text-center">
                            Are you sure, you want to delete it?
                        </p>
                        <div className="flex justify-center space-x-6 w-full">
                            <button
                                className="text-base font-dmsans bg-[#F3F3F3] text-[#344054] rounded-md shadow-sm w-[150px] h-[44px] flex items-center justify-center"
                                onClick={handleDelete}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                onClick={handleDeleteExample}
                                disabled={isDeleting}
                                className="w-[150px] h-[44px] rounded-md bg-[#D92D20] text-base font-medium text-[#FFFFFF] text-center flex items-center justify-center"
                            >
                                {isDeleting ? (
                                    <img src={WhiteLoadingAnimation} alt="Loading..." className="h-10 w-10" />
                                ) : (
                                    "Delete"
                                )}
                            </button>
                        </div>
                    </div>
                </PopupModal>
            )}
        </div>
    );
};

export default ExamplesPage;
