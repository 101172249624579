import React, { useState } from "react";
import axios from "axios";
import { googleLogin } from "../services/login";
import { FcGoogle } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import LoadingAnimation from "../assets/LoadingAnimation.svg";
function GoogleSignInButton(props) {
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const hostname = window.location.hostname;
  const token = localStorage.getItem("token");
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [loading, setLoading] = useState(false);
  /*global google*/
  let client;
  if (typeof google != "undefined") {
    client = google.accounts.oauth2.initCodeClient({
      client_id: process.env.REACT_APP_CLIENT_ID,
      scope: "https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email openid",
      callback: async (response) => {
        setLoading(true);
        try {
          const res = await googleLogin(response.code, hostname).then((res) => {
            console.log("");
          });

          if (props.inviteId) {
            const res = await axios.post(`${API_URL}/v1/invites/accept`, { inviteId });
            console.log(res);
          }

          navigate("/");
        }catch(err){
          console.log(err);
        } finally {
          setLoading(false);
        }
        
      },
    });
  } else {
    async function wait_for_1_sec() {
      await delay(1000);
      client = google.accounts.oauth2.initCodeClient({
        client_id: process.env.REACT_APP_CLIENT_ID,
        scope: "https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email openid",
        callback: async (response) => {
          setLoading(true);
          console.log(response, "response");
          try {
            await googleLogin(response.code, hostname).then((res) => {
              props.setVerified(true);
            });
          } catch (err) {
            console.log(err);
          } finally {
            setLoading(false);
          }
        },
      });
    }
    wait_for_1_sec();
  }
  return (
    <div className="flex mt-3">
      <button
        id="google-login-btn"
        onClick={() => client.requestCode()}
        className="py-[10px]  bg-[#F7F7F7] border border-[#0000001A] gap-2 flex justify-center items-center h-[58px] w-[430px]  border-[#39F6A3] border-opacity-10 rounded-[5px]"
        disabled={loading}
      >
       {loading ? (
          <span className="h-full w-14 flex justify-center items-center rounded-md">
          <img src={LoadingAnimation} alt="loading..." />
            </span>
    
        ) : (
          <>
            <span className="h-full w-8 flex justify-center items-center rounded-md">
              <FcGoogle className="w-[25px] h-[25px]" />
            </span>
            <div className="text-[15px] h-[25px] font-semibold font-dmsans leading-normal w-[163px] text-[#1E293B]">
              Sign in with Google
            </div>
          </>
        )}
      </button>
    </div>
  );
}
export default GoogleSignInButton;