export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = date.getUTCFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

export const formatTime = (dateString) => {
  const date = new Date(dateString);
  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'UTC'
  });
  return formattedTime;
};

export const formatDay = (dateString) => {
  const date = new Date(dateString);
  const formattedDay = date.toLocaleString('en-US', { 
    weekday: 'short', 
    timeZone: 'UTC' 
  });
  return formattedDay;
};

export const formatCustomDateTime = (dateString, fullYear = true) => {
  const date = new Date(dateString);
  
  const day = date.getUTCDate();
  const month = date.toLocaleString('en-US', { 
    month: 'long', 
    timeZone: 'UTC' 
  });
  const year = fullYear ? date.getUTCFullYear() : date.getUTCFullYear().toString().slice(-2); // Conditionally format year
  const time = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'UTC'
  }).toLowerCase();
  
  if (fullYear) {
    return `${month} ${day}, ${year}`; 
  } else {
    return `${day} ${month} '${year}, ${time}`;
  }
};

