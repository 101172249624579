import React, { useEffect, useState, useRef } from "react";
import OrderLogsShimmer from "../components/DraftDetailPageComponents/OrderLogsShimmer.jsx"
import Accordion from "./Accordion.jsx";
import ListButtonIcon from "../assets/DraftsPageAssets/ListButtonIcon.svg"
import axios from "axios";
import { useParams } from "react-router-dom";
import { formatCustomDateTime } from "../services/logics.js";
import EnvelopeDownloadIcon from "../assets/DraftsPageAssets/EnvelopeDownloadIcon.jsx";
import PaperPlaneIcon from "../assets/DraftsPageAssets/PaperPlaneIcon.jsx";
import { useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { GoChevronLeft } from "react-icons/go";
import PopupModal from "../components/PopupModal";
import { AiOutlineClose } from "react-icons/ai";
import {  formatTime } from "../services/logics";
import axiosInstance from "../services/axiosInstance.js";

const DraftDetailsSidebar = ({
    thread_id,
    userData,
    isFetchingOrderData,
    orderData,
    shouldFetchData,
    setShouldFetchData,
    sender_email,
    isFetchingUserData
}) => {
    const token = localStorage.getItem("token");
    const [userActivityLogs, setUserActivityLogs] = useState([]);
    const [isFetchingActivityLogs, setIsFetchingActivityLogs] = useState(true);
    const [isFetchingPastProductData, setIsFetchingPastProductData] = useState(false);
    // const [isFetchingPastOrderData, setIsFetchingPastOrderData] = useState(true);
    const [isFetchingProductData, setIsFetchingProductData] = useState(true);
    const [isFetchingAllOrders, setIsFetchingAllOrders] = useState(false);
    // const [pastOrderData, setPastOrderData] = useState(null);
    const [productData, setProductData] = useState(null);
    const [showMoreActivityLogs, setShowMoreActivityLogs] = useState(false);
    // const [showMorePastOrders, setShowMorePastOrders] = useState(false);
    const [showMoreAllOrders, setShowMoreAllOrders] = useState(false);
    const [isFetchingConversationsData, setIsFetchingConversationsData] = useState(false);
    const [allOrders, setAllOrders] = useState([]);
    const [conversations, setConversations] = useState([]);
    const [currentConversationPage, setCurrentConversationPage] = useState(1);
    const [totalConversation, setTotalConversation] = useState(0);
    const navigate = useNavigate();
    const param = useParams();
    const API_URL = process.env.REACT_APP_API_URL;
    const [pastProductData, setPastProductData] = useState([]);
    const activityLogRef = useRef(null);
    // const pastOrdersRef = useRef(null);
    const allOrdersRef = useRef(null);
    const conversationsRef = useRef(null);
    const savedScrollPosition = useRef(0); // To store the scroll position before loading new data
    const [activeProductIndex, setActiveProductIndex] = useState(null); // Track which product is expanded
    const pastProductsRef = useRef(null); // Reference for the "Past Products" section
    const [showMore, setShowMore] = useState(false);
    const [expandedOrderIndex, setExpandedOrderIndex] = useState(null); // Track which order is expanded
    const [expandedAllOrderIndex, setExpandedAllOrderIndex] = useState(null); // For tracking expanded orders in All Orders
    const [orderLogs, setOrderLogs] = useState(false)
    const [isOrderLogsModalOpen, setIsOrderLogsModalOpen] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [isFetchingOrderlogs, setIsFetchingOrderLogs] = useState(false);

    const openOrderLogsModal = (orderId) => {
        setSelectedOrderId(orderId);
        setIsOrderLogsModalOpen(true);
        fetchOrderLogs(orderId);
    };

    const closeOrderLogsModal = () => {
        setIsOrderLogsModalOpen(false);
        setSelectedOrderId(null);
    };
    const groupLogsByDate = (logs) => {
        // Ensure logs is an array
        if (!Array.isArray(logs)) return {};

        return logs.reduce((acc, log) => {
            const date = new Date(log.created_at).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'long',
                year: 'numeric'
            });

            if (!acc[date]) {
                acc[date] = [];
            }

            acc[date].push(log);
            return acc;
        }, {});
    };


    const groupedLogs = groupLogsByDate(orderLogs);
    const formattedType = (dataType) => {
        return dataType?.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    };


    const fetchUserActivityLogs = async () => {
        setIsFetchingActivityLogs(true);
        try {
            const response = await axiosInstance.get(
                `${API_URL}/v1/store/draft/activities/${thread_id}`
            );
            
            const data = response.data;
            setUserActivityLogs(data);
        } catch (error) {
            setUserActivityLogs([])
        }
        setIsFetchingActivityLogs(false);
    };


    const fetchProductData = async () => {
        setIsFetchingProductData(true);
        try {
            const response =  await axiosInstance.get(
                `${API_URL}/v1/store/draft/products?draft_id=${param.id}`
                
            );
            const data = response.data;
            setProductData(data);
        } catch (error) {
            setProductData([]);
        }
        setIsFetchingProductData(false);
    };

    // const fetchPastOrdersData = async () => {
    //     setIsFetchingPastOrderData(true);
    //     try {
    //         const response = await axios.get(
    //             `${API_URL}/v1/store/draft/orders?draft_id=${param.id}&thread_id=${thread_id}`,
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             }
    //         );
    //         const data = response.data;
    //         setPastOrderData(data);
    //     } catch (error) {
    //         setPastOrderData([])
    //     }
    //     setIsFetchingPastOrderData(false);
    // };

    const fetchPastProductsData = async () => {
        setIsFetchingPastProductData(true);
        try {
            const response = await axiosInstance.get(
                `${API_URL}/v1/store/draft/products?draft_id=${param.id}&thread_id=${thread_id}`
               
            );
            const data = response.data;
            setPastProductData(data);
        } catch (error) {
            setPastProductData([])
        }
        setIsFetchingPastProductData(false);
    };


    const fetchOrderLogs = async (selectedOrderId) => {
        setIsFetchingOrderLogs(true)
        try {
            const response =  await axiosInstance.get(
                `${API_URL}/v1/store/shopify/orders/${selectedOrderId}/events`
               
            );
            const data = response.data;
            setOrderLogs(data?.events);
        } catch (error) {
            setOrderLogs([]);
        }
        setIsFetchingOrderLogs(false)

    };


    const fetchAllOrdersData = async () => {
        setIsFetchingAllOrders(true);
        try {
            const response =  await axiosInstance.get(
                `${API_URL}/v1/store/customers/${userData?.default_address?.customer_id}/orders`
                
            );
            const data = response.data;
            setAllOrders(data);
        } catch (error) {
            setAllOrders([])
        }
        setIsFetchingAllOrders(false);
    };

    const fetchConversations = async (page = 1) => {
        setIsFetchingConversationsData(true);
        try {
            // Save the current scroll position before fetching new data
            if (conversationsRef.current) {
                savedScrollPosition.current = conversationsRef.current.scrollTop;
            }

            const response =  await axiosInstance.get(
                `${API_URL}/v1/store/customer-threads?page=${page}&page_size=10&customer_email=${sender_email}`
               
            );
            const data = response.data;

            // Append the new conversations to the existing ones
            if (page === 1) {
                setConversations(data.drafts);
            } else {
                setConversations(prevConversations => [...prevConversations, ...data.drafts]);
            }
            setTotalConversation(data.total);

            // Restore the scroll position after new data is loaded
            setTimeout(() => {
                if (conversationsRef.current) {
                    conversationsRef.current.scrollTop = savedScrollPosition.current;
                }
            }, 0); // Use a timeout to ensure the DOM has updated before scrolling
        } catch (error) {
            console.error("Error fetching conversations:", error);
        } finally {
            setIsFetchingConversationsData(false);
        }
    };

    useEffect(() => {
        setCurrentConversationPage(1);
        setConversations([]);
        // Scroll to the top when the draft ID changes
        if (conversationsRef.current) {
            conversationsRef.current.scrollTop = 0;
        }
    }, [param.id]);

    useEffect(() => {
        fetchConversations(currentConversationPage);
    }, [thread_id, param.id, currentConversationPage]);

    useEffect(() => {
        { userData && fetchAllOrdersData(); }
    }, [userData]);

    useEffect(() => {
        if (thread_id || shouldFetchData) {
            fetchUserActivityLogs();
            fetchProductData();
            fetchPastProductsData();
            { userData && fetchAllOrdersData(); }
            fetchConversations();
            setShouldFetchData(false);
        }
    }, [thread_id, shouldFetchData]);

    const getIconPath = (dataType) => {
        const lowerCaseDataType = dataType?.toLowerCase();
    
        if (lowerCaseDataType?.includes("query")) {
            return <EnvelopeDownloadIcon className="w-6 h-6" />;
        } else if (lowerCaseDataType?.includes("refund")) {
            return <PaperPlaneIcon className="w-6 h-6" />;
        } else if (lowerCaseDataType?.includes("cancel")) {
            return <EnvelopeDownloadIcon className="w-6 h-6" />;
        } else if (lowerCaseDataType?.includes("send")) {
            return <PaperPlaneIcon className="w-6 h-6" />;
        } else if (lowerCaseDataType?.includes("address")) {
            return <PaperPlaneIcon className="w-6 h-6" />;
        } else {
            return <EnvelopeDownloadIcon className="w-6 h-6" />;
        }
    };

    const toggleShowMoreActivityLogs = () => {
        setShowMoreActivityLogs(!showMoreActivityLogs);
        if (showMoreActivityLogs && activityLogRef.current) {
            activityLogRef.current.scrollTop = 0;
        }
    };

    // const toggleShowMorePastOrders = () => {
    //     setShowMorePastOrders(!showMorePastOrders);
    //     if (showMorePastOrders && pastOrdersRef.current) {
    //         pastOrdersRef.current.scrollTop = 0;
    //     }
    // };

    const toggleShowMoreAllOrders = () => {
        setShowMoreAllOrders(!showMoreAllOrders);
        if (showMoreAllOrders && allOrdersRef.current) {
            allOrdersRef.current.scrollTop = 0;
        }
    };

    const handleShowMoreConversations = () => {
        if (conversations.length < totalConversation) {
            setCurrentConversationPage(prevPage => prevPage + 1);
        }
    };

    const handleShowLessConversations = () => {
        setCurrentConversationPage(1);
        conversationsRef.current.scrollTop = 0;
    };

    const toggleProductDetails = (index) => {
        if (activeProductIndex === index) {
            setActiveProductIndex(null); // Close if the same product is clicked
        } else {
            setActiveProductIndex(index); // Open the clicked product
        }
    };

    const toggleShowMorePastProducts = () => {
        setShowMore(!showMore);
        if (pastProductsRef.current) {
            pastProductsRef.current.scrollTop = 0; // Scroll to the top when toggling "Show Less"
        }
    };
    const toggleOrderDetails = (index) => {
        setExpandedOrderIndex(expandedOrderIndex === index ? null : index);
    };
    const toggleAllOrderDetails = (index) => {
        setExpandedAllOrderIndex(expandedAllOrderIndex === index ? null : index);
    };

    return (
        <>
            <div className="flex flex-col w-[274px] min-h-[146px] items-center bg-gradient-to-b from-[rgba(176,251,218,0.4)] to-[#FFFFFF]">
                {/* User Icon */}
                <div className="flex justify-center items-center mb-2 mt-2">
                    <div className="flex justify-center items-center rounded-full w-[66px] h-[66px] bg-[#FFFFFF] p-2">
                        <FaUser className="text-gray-600 w-[50px] h-[50px]" />
                    </div>
                </div>
                {userData && (
                    // User Details
                    <div className="text-center">
                        {/* User Name */}
                        <div className="text-[#2B2E3A] font-medium text-sm">
                            {userData?.first_name || userData?.last_name
                                ? `${userData?.first_name || ''} ${userData?.last_name || ''}`
                                : 'N/A'}
                        </div>

                        {/* Email */}
                        <div className="text-[#2B2E3A] text-xs underline">
                            {userData?.email || ''}
                        </div>

                        {/* Phone Number */}
                        <div className="text-[#2B2E3A] text-xs underline">
                            {userData?.phone || ''}
                        </div>
                    </div>
                )}

            </div>


            {(!isFetchingOrderData && orderData?.length > 0) && (
                <Accordion title="Order Details">
                    {orderData?.map((data, index) => {
                        const { address1, city, zip, province, country, address2, company } = data?.shipping_address || {};
                        return (
                            <div className="w-[274px] relative top-1 left-[5px] min-h-[120px]" key={index}>
                                <div className="left-0 top-0 absolute text-xs font-medium font-['DM Sans'] leading-[1.20px] mt-2 w-[274px] flex">
                                    <div className="text-[#868CA2] w-[94px] min-h-[26px]">Order Number</div>
                                    <div className="text-[#2B2E3A] w-[164px] min-h-[26px]">{data?.name}</div>
                                </div>

                                <div className="left-0 top-[24px] absolute text-xs font-medium font-['DM Sans'] leading-[1.20px] mt-2 flex">
                                    <div className="text-[#868CA2] w-[94px] min-h-[26px]">Order Date</div>
                                    <div className="text-[#2B2E3A] w-[164px] min-h-[26px]">{formatCustomDateTime(data?.created_at, true)}</div>
                                </div>

                                <div className="w-[262px] left-0 top-[52px] absolute text-xs font-medium font-['DM Sans'] leading-3 flex">
                                    <div className="w-[106px] text-[#868CA2] min-h-[26px]">Shipping Address</div>
                                    <div className="w-auto text-[#2B2E3A] min-h-[26px] leading-[14px]">
                                        {address1 && <>{address1},</>}
                                        {address2 && <>{address2},<br /></>}
                                        {company && <>{company},</>}
                                        {city && <>{city},</>}
                                        {province && <>{province},<br /></>}
                                        {zip && <>{zip},</>}
                                        {country && <>{country}<br /></>}
                                    </div>
                                </div>
                                {/* Chevron Left Icon with Tooltip */}
                                <div className="absolute left-0 bottom-[-4px] cursor-pointer h-[26px] " onClick={() => openOrderLogsModal(data?.id)}>
                                    <div className="relative group flex items-center">
                                        {/* Circle Container */}
                                        <div className="w-4 h-4 bg-white rounded-full flex items-center justify-center shadow-lg ">
                                            <GoChevronLeft className="w-5 h-5 text-black" />
                                        </div>
                                        {/* Tooltip */}
                                        <div className="  text-xs text-[#868CA2]">
                                            Order Logs
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Accordion>
            )}

            {(!isFetchingAllOrders && allOrders?.length > 0) && (
                <Accordion title="All Orders">
                    <div ref={allOrdersRef} className={`relative w-[278px] ${allOrders.length > 10 ? 'max-h-[250px] overflow-y-auto custom-scrollbar' : 'h-auto'} overflow-x-hidden`}>
                        {(showMoreAllOrders ? allOrders : allOrders.slice(0, 10))?.map((order, index) => (
                            <div key={order.id} className="mb-2">
                                {/* Order Header */}
                                <div className={`flex justify-between items-center  ${allOrders.length > 10 ? 'w-[270px]' : 'w-[272px]'} cursor-pointer pr-1`} onClick={() => toggleAllOrderDetails(index)} >
                                    <span className="text-xs font-medium text-[#2B2E3A] w-[60px] pl-2">
                                        {order.name}
                                    </span>
                                    <span className="text-xs font-medium text-[#2B2E3A] whitespace-nowrap">
                                        {formatCustomDateTime(order.created_at, false)}
                                    </span>
                                    <button className="text-[9px] font-medium underline text-[#46434380] cursor-pointer pb-3">
                                        Details
                                    </button>
                                </div>

                                {/* Expanded Order Details */}
                                {expandedAllOrderIndex === index && (
                                    <div className="mt-2 ml-3 pr-2">
                                        <div className="text-xs font-medium text-[#868CA2]">
                                            <div className="flex justify-between">
                                                <span>Total Amount</span>
                                                <span className="text-[#868CA2]">{order.total_price}</span>
                                            </div>
                                            <div className="flex justify-between mt-2">
                                                <span>Order Status</span>
                                                <span className="text-[#868CA2]">{order.financial_status}</span>
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <span className="text-xs font-medium text-[#868CA2]">Items:</span>
                                            <table className="mt-1 text-xs w-[252px] ">
                                                <thead>
                                                    <tr className="text-[#868CA2] border-t border-b h-[24px]">
                                                        <th className="text-left font-medium w-[80px]">Title</th>
                                                        <th className="text-center font-medium w-[76px]">Qty.</th>
                                                        <th className="text-right font-medium w-[76px]">Unit Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {order.line_items?.map((item, itemIndex) => (
                                                        <tr key={itemIndex} className="text-[#868CA2]">
                                                            <td className="text-left w-[80px]">{item.name}</td>
                                                            <td className="text-center">{item.quantity}</td>
                                                            <td className="text-right">{item.price}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <div className="text-center my-2">
                                                <button
                                                    className="text-[9px] font-medium underline text-[#2B2E3A] cursor-pointer pb-2 "
                                                    onClick={() => openOrderLogsModal(order?.id)}
                                                >
                                                    Show Order Logs
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>

                    {allOrders?.length > 10 && (
                        <div className="text-center">
                            <button className="text-[8px] text-gray-400 underline font-Lato" onClick={toggleShowMoreAllOrders}>
                                {showMoreAllOrders ? 'Show Less' : 'Show More'}
                            </button>
                        </div>
                    )}
                </Accordion>
            )}


            {(!isFetchingPastProductData && pastProductData?.length > 0) && (
                <Accordion title="Past Products">
                    <div
                        ref={pastProductsRef} className={`space-y-2 ${pastProductData.length > 10 ? 'max-h-[250px] overflow-y-auto w-[278px]' : 'h-auto'} overflow-x-hidden  custom-scrollbar`}
                    >
                        {(showMore ? pastProductData : pastProductData.slice(0, 10))?.map((product, index) => (
                            <div
                                key={product.id}
                                className={`p-1 ${pastProductData.length > 10 ? 'w-[270px]' : 'w-auto'} font-['DM Sans'] ${activeProductIndex === index ? 'border border-gray-100 mt-1' : ''}`}
                            >
                                <div className="flex justify-end items-center">
                                    <div
                                        className="text-[9px] font-medium underline text-[#46434380] cursor-pointer"
                                        onClick={() => toggleProductDetails(index)}
                                    >
                                        Details
                                    </div>
                                </div>
                                <div className="flex items-center mt-[1px]">
                                    <span className="font-medium w-[86px] break-words text-[#2B2E3A] text-[11px]">#{product.id}</span>
                                    <span className="font-medium text-[#2B2E3A] text-[11px] ml-[10px]">{product.title}</span>
                                </div>
                                {activeProductIndex === index && (
                                    <div className="mt-2 space-y-1 w-[254px] pl-2 mx-auto">
                                        <div className="flex justify-between h-[24px] text-[#868CA2] text-[11px]">
                                            <span>Unit Price</span>
                                            <span>{product.variants[0].price}</span>
                                        </div>
                                        <div className="flex justify-between text-[#868CA2] text-[11px] h-[24px]">
                                            <span>Inventory Status</span>
                                            <span>{product.status}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    {pastProductData?.length > 10 && (
                        <div className="text-center mt-2">
                            <button className="text-[8px] text-gray-400 underline font-Lato" onClick={toggleShowMorePastProducts}>
                                {showMore ? 'Show Less' : 'Show More'}
                            </button>
                        </div>
                    )}
                </Accordion>
            )}


            {(!isFetchingProductData && productData?.length > 0) && (
                <Accordion title="Product Details">
                    {productData?.map((data, index) => (
                        <div className="space-y-4 h-auto font-['DM Sans'] leading-[1.20px] mb-2 pl-1" key={index}>
                            <p className="text-xs font-medium text-[#868CA2] flex h-auto mb-2 ">
                                Description <span className="text-[#2B2E3A] ml-10">{data.title}</span>
                            </p>
                            <p className="text-xs font-medium text-[#868CA2] flex ">
                                Quantity <span className="text-[#2B2E3A] ml-[54px]">{data?.variants?.[0]?.inventory_quantity}</span>
                            </p>
                            <p className="text-xs font-medium text-[#868CA2] flex">
                                Unit Price <span className="text-[#2B2E3A] ml-[49px]">{data?.variants?.[0]?.price} {data?.variants?.[0]?.presentment_prices?.[0]?.price?.currency_code}</span>
                            </p>
                        </div>

                    ))}
                </Accordion>
            )}

            {!isFetchingActivityLogs && (
                <Accordion title="Activity Log">
                    {userActivityLogs.length > 0 ? (
                        <div ref={activityLogRef} className={`relative min-h-[140px] h-auto max-h-[200px] ${showMoreActivityLogs ? 'overflow-auto custom-scrollbar' : 'overflow-hidden'}`}>
                            {userActivityLogs.map((data, index) => (
                                <div key={index} className="flex items-center space-x-2 relative pb-3 ml-8">
                                    <div className="flex-shrink-0 w-6 h-6 flex items-center justify-center">
                                    {getIconPath(data.type)} 
                                    </div>
                                    {index < userActivityLogs.length - 1 ? (
                                        <div className="absolute top-6 left-1 w-px h-full border-l border-dotted border-black"></div>
                                    ) : (
                                        !showMoreActivityLogs && (
                                            <div className="absolute top-2 left-1 w-px h-4 border-l border-dotted border-black"></div>
                                        )
                                    )}
                                    <div className="ml-2">
                                        <p className="text-sm text-gray-800">{formatCustomDateTime(data.timestamp, false)}</p>
                                        <p className="text-xs text-[#000000]">
                                            <span className="text-[#868CA2]">{data.details}</span>: {formattedType(data.type)}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="text-center mt-14 h-[80px] text-[#868CA2]">No activity logs found</p>
                    )}
                    {userActivityLogs.length > 4 && (
                        <div className="mt-1 text-center">
                            <button className="text-[8px] text-gray-400 underline font-Lato " onClick={toggleShowMoreActivityLogs}>
                                {showMoreActivityLogs ? 'Show Less' : 'Show More'}
                            </button>
                        </div>
                    )}
                </Accordion>
            )}

            <Accordion title="All Conversations">
                {conversations?.length > 0 && (
                    <div ref={conversationsRef} className={`font-['DM Sans'] relative h-[240px] w-[278px] overflow-auto custom-scrollbar pl-[6px] `}>
                        {conversations?.map((conversation, index) => (
                            <div key={index} className="flex items-center py-1 text-[11px] text-[#868CA2] hover:bg-[#E8FFF5] hover:text-[#28282E] leading-tight cursor-pointer" onClick={() => navigate(`/draft-page/detail/${conversation.id}`) }>
                                <div className=" py-2 my-auto font-medium  w-[86px] mr-2">
                                    {formatCustomDateTime(conversation.created_at)}
                                </div>
                                <div className=" my-auto font-bold text-[#2B2E3A] hover:text-[#28282E] underline w-[170px] break-words">
                                    {conversation.email_subject}
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {totalConversation >= 10 &&
                    <div className="mt-1 text-center">
                        {conversations.length < totalConversation ? (
                            <button className="text-[8px] text-gray-400 underline font-Lato " onClick={handleShowMoreConversations}>
                                Show More
                            </button>
                        ) : (
                            <button className="text-[8px] text-gray-400 underline font-Lato " onClick={handleShowLessConversations}>
                                Show Less
                            </button>
                        )}
                    </div>
                }
            </Accordion>


            {isOrderLogsModalOpen && (
                <PopupModal closeModal={closeOrderLogsModal} outerClassName="justify-end mr-80" className="bg-[#FFFFFF] max-h-[500px] overflow-y-auto custom-scrollbar">
                    <div className="flex flex-col">
                        <div className="flex justify-end">
                            <button onClick={closeOrderLogsModal}>
                                <AiOutlineClose />
                            </button>
                        </div>
                        {isFetchingOrderlogs ? (<OrderLogsShimmer />) : (
                            Object.entries(groupedLogs).map(([date, logs]) => (
                                <div key={date} className="mb-4">
                                    <h3 className="font-semibold text-sm mb-2">{date}</h3>
                                    <ul className="list-none pl-0 relative">
                                        {logs.map((log, index) => (
                                            <li key={index} className="flex items-start space-x-3 mb-2 relative">
                                                {/* Vertical line */}
                                                <div className={`absolute ml-[18px] top-3 bottom-0 w-px h-full bg-[#2B2E3A] ${index === logs.length - 1 ? 'hidden' : ''}`}></div>

                                                <div className="flex items-center">
                                                    <div className="w-3 h-3"><img src={ListButtonIcon} alt="" /></div>
                                                </div>

                                                <div className="flex justify-between w-full">
                                                    <div className="text-[10px] text-[#000000] w-[320px]">{log.message}</div>
                                                    <div className="text-[10px] text-[#868CA2] w-[50px] text-right">
                                                        {formatTime(log.created_at)}
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))
                        )}

                    </div>
                </PopupModal>

            )}

        </>
    );
};

export default DraftDetailsSidebar;
